import { BlockComponent } from "../../../framework/src/BlockComponent";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import React from 'react';
import {DisplayFlow} from "../../../components/src/PreviewFlow";
const _ = require('lodash');

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  video?: {};
  match: any;
}

interface ZoneSpecificVideos {
  [key: number]: { set1?: any; set2?: any };
}



export interface StationVideo {
  set1: any;
  set2: any;
}

export interface SelectedClassType {
  id: string;
  type: string;
  attributes: {
    introduction: number | string;
    demo: number | string;
    demo_video_shift: number | string;
    warmup: number | string;
    cooling_down: number | string;
    
    exercise_sets: {
      id: any;
      rest_time: any;
      set_number: any;
      set_time: any;
    }[];
    focus: any;
    formatted_station_data: any;
    
    number_of_loops: number | string;
    station: number | string;
    station_sequence: string[];
    change_zone: number | string;
    title: string;
    workout_time: number | string;
  }
}

interface S {
  introductionVideo: any;
  warmupVideo: any;
  coolingVideo: any;

  zone1: ZoneSpecificVideos;
  zone2: ZoneSpecificVideos;
  zone3: ZoneSpecificVideos;
  zoneData: ZoneSpecificVideos;

  laps: any;
  sets: any;
  selectedZone: string;
  station: number;
  selectedClassType: any;

  displayFlow: DisplayFlow[];
}

interface SS {
  id: any;
}


export default class PreviewController extends BlockComponent<Props, S, SS> {

  previewRef: any = React.createRef();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      introductionVideo: {},
      warmupVideo: {},
      coolingVideo: {},

      zone1: { 1: { set1: "", set2: "" }, 2: { set1: "", set2: "" }, 3: { set1: "", set2: "" }, 4: { set1: "", set2: "" }, 5: { set1: "", set2: "" }, 6: { set1: "", set2: "" }, 7: { set1: "", set2: "" }, 8: { set1: "", set2: "" }, 9: { set1: "", set2: "" } },
      zone2: { 1: { set1: "", set2: "" }, 2: { set1: "", set2: "" }, 3: { set1: "", set2: "" }, 4: { set1: "", set2: "" }, 5: { set1: "", set2: "" }, 6: { set1: "", set2: "" }, 7: { set1: "", set2: "" }, 8: { set1: "", set2: "" }, 9: { set1: "", set2: "" } },
      zone3: { 1: { set1: "", set2: "" }, 2: { set1: "", set2: "" }, 3: { set1: "", set2: "" }, 4: { set1: "", set2: "" }, 5: { set1: "", set2: "" }, 6: { set1: "", set2: "" }, 7: { set1: "", set2: "" }, 8: { set1: "", set2: "" }, 9: { set1: "", set2: "" } },
      zoneData: { 1: { set1: "", set2: "" }, 2: { set1: "", set2: "" }, 3: { set1: "", set2: "" }, 4: { set1: "", set2: "" }, 5: { set1: "", set2: "" }, 6: { set1: "", set2: "" }, 7: { set1: "", set2: "" }, 8: { set1: "", set2: "" }, 9: { set1: "", set2: "" } },
      
      station: 0,
      laps: 0,
      sets: 0,
      selectedZone: "",
      selectedClassType: {},
      
      displayFlow: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.initData();
    window.addEventListener('load', this.handleLoad);
    // Customizable Area End
  }

  async componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    const timer = setTimeout(() => {
      if (this.previewRef && this.previewRef?.current) {
        this.previewRef.current.resume();
      }
      clearTimeout(timer);
    }, 500);
  }

  initData = async () => {
    const localData = await this.getDataFromLS("PreviewData");
    this.mapLocalDataToState(localData);
  }

  mapLocalDataToState = async (localData: any) => {
    this.setState({
      introductionVideo: localData.introductionVideo,
      warmupVideo: localData.warmupVideo,
      coolingVideo: localData.coolingVideo,
      zone1: localData.zone1,
      zone2: localData.zone2,
      zone3: localData.zone3,
      selectedZone: this.props?.match?.params?.id, // zone1, zone2, zone3
      selectedClassType: localData.selectedClassType,
    }, this.initDisplayVideo);
  }


  getDataFromLS = (key: string) => {
    const previewData = localStorage.getItem(key);
    if (previewData) {
      return JSON.parse(previewData);
    } else {
      return {};
    }
  };

  initDisplayVideo = async () => {
    const {
      introductionVideo,
      warmupVideo,
      zone1,
      zone2,
      zone3,
      coolingVideo,
      selectedZone,
      selectedClassType,
    } = this.state;
    try {
      let displayedFlow: DisplayFlow[] = [];

      const zoneMappings: any = { zone1, zone2, zone3 };

      const station = parseInt(selectedClassType.attributes.station);
      const sets = selectedClassType.attributes.exercise_sets.length;
      const laps = parseInt(selectedClassType.attributes.number_of_loops);

      this.setState({ zoneData: zoneMappings[selectedZone], station, sets, laps });

      const addStationFlow = (currentZone: number, currentLap: number) => {
        for (let i = 0; i < station; i++) {
          selectedClassType.attributes.exercise_sets.forEach((setDataItem: any, index: number) => {
            displayedFlow.push({ phase: `SET_${index + 1}`, time: setDataItem.set_time, currentZone, currentLap, currentSet: index + 1, currentStation:i + 1  });
            displayedFlow.push({ phase: "REST", time: setDataItem.rest_time, currentZone, currentLap, currentSet: index + 1 });
          });
        }
      }
      const addDataZone = (zone: number) => {
        for (let i = 1; i <= laps; i++) {
          addStationFlow(zone, i);
        }
        displayedFlow.pop(); // remove Rest phase at last
      }
      const addGeneralPhase = (phase: string, video: any, time: number, currentZone: number, currentLap: number) => {
        displayedFlow.push({ phase, time, currentZone, currentLap });
      }

      displayedFlow.push({ phase: 'PREPARE', time: 30});
      addGeneralPhase("INTRO", introductionVideo,selectedClassType?.attributes?.introduction, 1, 1);
      addGeneralPhase("DEMO",introductionVideo,selectedClassType?.attributes?.demo, 1, 1);
      addGeneralPhase("WARM_UP", warmupVideo,selectedClassType?.attributes?.warmup, 1, 1);

      displayedFlow.push({ phase: 'GET_STARTED', time:10});
      
      addDataZone(1)
      displayedFlow.push({ phase: "CHANGE_ZONE", time: selectedClassType.attributes.change_zone, currentLap: laps });
      addDataZone(2)
      displayedFlow.push({ phase: "CHANGE_ZONE", time: selectedClassType.attributes.change_zone, currentLap: laps });
      addDataZone(3)
      displayedFlow.push({ phase: "CHANGE_ZONE", time: selectedClassType.attributes.change_zone, currentLap: laps });

      addGeneralPhase("COOL_DOWN", coolingVideo,selectedClassType?.attributes?.cooling_down, 3, laps);
      displayedFlow.push({ phase: "END" });

      displayedFlow.forEach((element, index) => element.id = index);

      this.setState({ displayFlow: displayedFlow });
    } catch (error) {
      console.log(error)
    }
  }
}