import React, { useState, useEffect } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CommonModalTags from "./CommonModalTags";
const _ = require('lodash');

interface CommonTagsFieldProps extends BoxProps {
  label: string;
  listTag: string[];
  currentTag?: string[];
  tagsCache: string[];
  handlePositiveClick: (data: string[]) => void;
}

const CommonTagsField: React.FC<CommonTagsFieldProps> = (props) => {
  const { label, listTag, handlePositiveClick, currentTag, tagsCache } = props;
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [tagsSelected, setTagsSelected] = useState<string[]>([])
  useEffect(() => {
    if(currentTag){
      setTagsSelected(currentTag)
    }
  }, [currentTag])
  
  const onChangeTags = (listNewTags: string[]) => {
    setTagsSelected(listNewTags)
    setIsOpenModal(false)
    handlePositiveClick(listNewTags)
  }
  const handleDeleteTag = (tagDeleted: string) => {
    let listTagsTemp =  [...tagsSelected]
    listTagsTemp = listTagsTemp.filter((tagName: string) => tagName !== tagDeleted)
    setTagsSelected(listTagsTemp)
    handlePositiveClick(listTagsTemp)
  }
  
  const renderModalAddTag = () => {
    return (
      <CommonModalTags
        popupHeading="Add Focus Tag For Video"
        openModal={isOpenModal}
        handleCloseModal={() => setIsOpenModal(false)}
        handlePositiveClick={onChangeTags}
        allTags={_.uniq([...listTag, ...tagsCache])}
        currentTag={tagsSelected}
      />
    );
  };
  const renderTag = (tagName: any, index: number) => {
    return (
      <Box sx={webStyle.tagContainer} key={index}>
        <Typography style={webStyle.input}>{tagName}</Typography>
        <Box onClick={() => handleDeleteTag(tagName)}>
          <Close fontSize="small" />
        </Box>
      </Box>
    );
  };
  return (
    <Box {...props}>
      {renderModalAddTag()}
      <Typography style={webStyle.label}>{label}</Typography>
      <Box onClick={() => {
        if(tagsSelected.length === 0){
          setIsOpenModal(true)
        }
      }} sx={webStyle.containerInput}>
        <Box sx={webStyle.listTagContainer}>
          {tagsSelected?.map((tag, index) => renderTag(tag, index))}
        </Box>
        <AddIcon style={webStyle.iconAdd} fontSize="small" onClick={() => {
          if(tagsSelected.length > 0){
            setIsOpenModal(true)
          }
        }} />
      </Box>
    </Box>
  );
};
const webStyle = {
  tagContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 16,
    paddingRight: 16,
    height: 30,
    alignItems: "center",
    marginLeft: 8,
    borderRadius: 13,
    background: "#fff",
    marginTop: 5
  },
  listTagContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
    fontWeight: 400
  },
  icon: {
    marginLeft: 10,
  },
  iconAdd: {
    marginRight: 20,
    marginTop: 5
  },
  containerModalTag: {
    borderRadius: 17,
    border: "1px solid #C2C2C2",
    background: "#F1F0EE",
    flex: 1,
    height: 63,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  containerInput: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    minHeight: 40,
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
  },
  modalWapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textHeader: {
    fontSize: 16,
    fontWeight: 700,
    color: "#313320",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 630,
    borderRadius: 16,
    background: "#fff",
    overflow: "hidden",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 24,
    paddingRight: 18,
    background: "#EEECE7",
  },
  headerContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 31,
    marginTop: 20,
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 31,
    paddingTop: 15,
    borderTop: "0.5px solid #EEECE7",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 32,
  },
  textAddTag: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
  textTagAvailable: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    alignSelf: "center",
    marginTop: 16,
  },
};

export default CommonTagsField;
