import React, { ChangeEvent, useEffect, useState } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography, IconButton, Modal, InputBase } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import CommonButton from "./CommonButton";

interface CommonModalTagsProps extends BoxProps {
  popupHeading: string;
  handleCloseModal: any;
  openModal: boolean;
  allTags?: string[];
  currentTag: string[];
  handlePositiveClick: (data: string[]) => void;
}

const CommonModalTags: React.FC<CommonModalTagsProps> = (props) => {
  const { popupHeading, handleCloseModal, openModal, allTags, handlePositiveClick, currentTag } = props;
  const [listTag, setListTag] = useState<string[]>([])
  useEffect(() => {
    setListTag(currentTag)
  }, [currentTag])
  
  const [inputText, setInputText] = useState("")
  const [isShowSearchPanel, setIsShowSearchPanel] = useState(false)

  const handleDeleteATag = (tagDeleted: string) => {
    let listTagTemp = [...listTag]
    listTagTemp = listTagTemp.filter(tag => tag !== tagDeleted)
    setListTag(listTagTemp)
  }
  const handleAddATag = (tagAdded: string) => {
    if(listTag.includes(tagAdded)){
      setIsShowSearchPanel(false)
      setInputText("")
      return
    }
    let listTagTemp = [...listTag, tagAdded]
    setListTag(listTagTemp)
    setIsShowSearchPanel(false)
    setInputText("")
  }

  const renderTag = (item: string, index: number) => {
    return (
      <Box sx={webStyle.tagContainer} key={index}>
        <Typography style={webStyle.input}>{item}</Typography>
        <Box onClick={() => handleDeleteATag(item)}>
          <Close fontSize="small" />
        </Box>
      </Box>
    );
  };
  const onChangeInputText = (text: ChangeEvent<HTMLInputElement>) => {
    setInputText(text.target.value);
  }
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      data-testid="modal-add-tag-workout"
      aria-labelledby="modal-add-tag-title"
      aria-describedby="modal-add-tag-description"
    >
      <Box sx={webStyle.modalWrapper}>
        <Box
          sx={{ ...webStyle.modalContainer, paddingBottom: 12 }}
        >
          <Box sx={webStyle.modalHeader}>
            <Typography
              style={webStyle.textHeader}
              data-testid="modal-add-tag-title"
            >
              {popupHeading}
            </Typography>
            <IconButton
              data-testid="modal-button-close"
              onClick={handleCloseModal}
            >
              <Close fontSize="medium" />
            </IconButton>
          </Box>
          <Box sx={webStyle.contentContainer}>
            <Box sx={webStyle.containerModalTag}>
              <InputBase
                onFocus={() => setIsShowSearchPanel(true)}
                style={{ width: "100%", fontWeight: "normal" }}
                placeholder="Enter a focus tag"
                value={inputText}
                onChange={onChangeInputText}
              />
            </Box>
           {isShowSearchPanel && <Box
              style={{
                position: 'absolute',
                top: 96,
                left: 35,
                right: 35,
                display: "flex",
                flexDirection: "column",
                maxHeight: 300,
                overflowY: "auto",
                backgroundColor: '#F1F0EE',
                borderRadius: 10,
              }}
            >
              {allTags?.filter((tagName: string) => tagName.toLowerCase().includes(inputText.toLowerCase())).length === 0 &&
              <Box onClick={() => {handleAddATag(inputText)}}
               sx={{display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 15, alignSelf: 'center', ...webStyle.button}}>
                <Typography 
                style={{fontSize: 14, color: "#000"}}
                >
                  {`Tag not found, `}
                  </Typography>
                  <Typography 
                style={{fontSize: 14, color: "#0B60B0"}}
                >
                  create now
                  </Typography>
                </Box> 
                
                  }
                  {allTags?.filter((tagName: string) => tagName.toLowerCase().includes(inputText.toLowerCase())).length !== 0 && inputText.trim().length > 0 &&  
                  <Box 
                    onClick={() => {
                    handleAddATag(inputText)
                 }} 
                 sx={{
                   paddingLeft: 15,
                   padding: 10,
                   display: 'flex',
                   alignItems: 'center',
                   ...webStyle.button
                   }}>
                 <Typography style={{fontSize: 14, color: "#000", fontWeight: 'bold'}}>
                  {`Create a tag like "${inputText}".`}
                  </Typography>
                  </Box>}
              {allTags?.filter((tagName: string) => tagName.toLowerCase().includes(inputText?.toLowerCase()))
                .map((tagName: string) => {
                  return (
                    <Box 
                    key={tagName}
                    onClick={() => {
                      handleAddATag(tagName)
                    }} 
                    sx={{
                      paddingLeft: 15,
                      padding: 10,
                      ...webStyle.button
                      }}>
                      <Typography style={{fontSize: 14, color: "#000"}}>{tagName}</Typography>
                    </Box>
                  );
                })}
                
            </Box>}
            <Box sx={webStyle.listTagContent}>
            {listTag.map((tag, index) => renderTag(tag, index))}
          </Box>
          </Box>
          
          <Box display="flex" flex={1} />
          <Box sx={webStyle.buttonContainer}>
            <CommonButton
              width={93}
              height={38}
              label="Cancel"
              onClickButton={() => {
                setListTag([]);
                setInputText("");
                handleCloseModal();
              }}
              mode="transparent"
            />
            <CommonButton
              width={93}
              height={38}
              label="Add"
              onClickButton={() => {
                if (listTag.length === 0) {
                } else {
                  handlePositiveClick(listTag);
                  setListTag([]);
                  setInputText("");
                }
              }}
              mode={listTag.length === 0 ? "rounded" : "primary-rounded"}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
const webStyle = {
  button: {
    "&:hover": {
      cursor: "pointer",
    }
  },
  tagContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "0.76px solid #EEECE7",
    background: "#fff",
    borderRadius: 13,
    marginRight: 14,
    height: 54,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16
  },
  listTagContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
  },
  icon: {
    marginLeft: 10,
  },
  iconAdd: {
    marginRight: 20,
  },
  containerModalTag: {
    borderRadius: 17,
    border: "1px solid #C2C2C2",
    background: "#F1F0EE",
    flex: 1,
    height: 63,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 15
  },
  containerInput: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    height: 49,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    marginRight: 10
  },
  modalWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textHeader: {
    fontSize: 16,
    fontWeight: 700,
    color: "#313320",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 630,
    borderRadius: 16,
    background: "#fff",
    overflow: "hidden",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 24,
    paddingRight: 18,
    background: "#EEECE7",
  },
  headerContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 31,
    marginTop: 20,
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 31,
    paddingTop: 15,
    borderTop: "0.5px solid #EEECE7",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 32px 0px 32px",
    position: 'relative'
  },
  textAddTag: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
  textTagAvailable: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    alignSelf: "center",
    marginTop: 16,
  },
  listTagContent: {
    display: "flex",
    flexDirection: "row",
    height: 280,
    flexWrap: "wrap",
    overflowY: "auto",
  },
};

export default CommonModalTags;