import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface myProps {
  loading: boolean;
  color?:any;
  thickness?:number;
  variant?:any;
  value?:any;
  className?:any;
  style?:any;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
    minWidth: "176px",
   height: "176px",
    width: "100%",
  },
  customProgress: (props: myProps) => ({
    color: props.className?.color || undefined,
    height: props.className?.height || undefined,
    width: props.className?.width || undefined,
    transform: props.className?.transform || undefined,
    "& .MuiCircularProgress-root":props.className?.["& .MuiCircularProgress-root"]||undefined ,
    "& .MuiCircularProgress-circle": props.className?.["& .MuiCircularProgress-circle"] || undefined,
    "& .MuiCircularProgress-svg": props.className?.["& .MuiCircularProgress-svg"] || undefined,
  }),
}));

export default function Loader(props: myProps) {
  const classes = useStyles(props);
  
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer} style={props.style}>
        <CircularProgress  variant={props.variant} value={props.value} className={classes.customProgress} thickness={0} />
      </div>
    </div>
  ) : (
    <div />
  );
}
