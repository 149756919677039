// Customizable Area Start
import React , {useState} from "react";

import {
  Box,
  Typography,
  IconButton,
  InputBase,
  Grid,
  Popover,
  Modal,
  TextField,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Select,
} from "@material-ui/core";
import ReactPlayer from "react-player";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  imgFilter,
  imgLevel,
  imgSchedule,
  imgEdit,
  videoWorkout,
  imgSearch,
  imgSort,
  filterList
} from "./assets";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CommonVideoPlayer from "../../../components/src/CommonVideoPlayer";
import CommonModalAddTags from "../../../components/src/CommonModalAddTags";
import VideoItem from "../../../components/src/VideoItem";
import CommonModalDelete from "../../../components/src/CommonModalDelete";
import CommonModalTags from "../../../components/src/CommonModalTags";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#eb7c58",
    },
  },
  typography: {
    h6: {
      fontSize: 20,
      fontWeight: 700,
      color: "#e53600",
    },
    caption: {
      fontSize: 16,
      fontWeight: 700,
      color: "#000",
    },
    subtitle1: {
      fontSize: 16,
      color: "#000",
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 13,
      color: "#838382",
    },
  },
});

import VideolibraryController, { Props } from "./VideolibraryController";
import CommonButton from "../../../components/src/CommonButton";

export default class Videolibrary extends VideolibraryController {
  constructor(props: Props) {
    super(props);
  }

  renderVideoItem() {}
  
  renderFilterModal() {
    const listFilterData = this.isVideoTabActive() ? this.state.listCategory : filterList
    return (
      <Box style={{borderRadius:17}}>
      <Popover
        id={this.idFilter}
        open={Boolean(this.state.anchorEl || null)}
        anchorEl={this.state.anchorEl}
        onClose={this.handerCloseFilter}
        data-testid="filter-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={webStyle.filterModalContainer}>
          {listFilterData.map((category: string) => {
            const filterStyle =
            category === this.state.selectedFilter
                ? webStyle.filterModalItemSelected
                : {};
            return (
              <Box
                data-testid="filter-item"
                onClick={() => {
                  this.handleChangeSelectedFilter(category);
                }}
                sx={{ ...webStyle.filterModalItem, ...filterStyle }}
                key={category}
              >
                <Typography>{category}</Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
      </Box>
      
    );
  }
  renderAddTag() {
    const { listTag, modalOpen } = this.state;
    return (
      <CommonModalTags
      popupHeading="Add Focus Tag For Video"
      openModal={modalOpen}
      handleCloseModal={this.handleCloseTagModal}
      handlePositiveClick={this.handleAddTag}
      allTags={listTag}
      currentTag={[]}
    />
    );
  }
  renderModalEdit() {
    return (
      <Modal
        open={this.state.openModal}
        onClose={this.handleCloseModal}
        data-testid="modal-detail"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={webStyle.modalWapper}>
          <Box sx={webStyle.modalContainer}>
            {this.state.modalOpen ? this.renderAddTag() : <></>}
            <Box sx={webStyle.modalHeader}>
              <Typography
                style={{ fontSize: 16, fontWeight: 700 }}
                id="modal-modal-title"
              >
                 {this.state.loading && (
                <Box
                  sx={webStyle.loadingContainer}
                >
                  <CircularProgress style={{color: '#E73700'}} />
                </Box>
              )}
                Edit
              </Typography>
              <IconButton
                data-testid="button-close-edit-modal"
                onClick={this.handleCloseModal}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>
            <Box sx={webStyle.modalContentContainer}>
              <CommonVideoPlayer
                videoWidth="569"
                source={this.state?.base64URL}
                handleFileChange={this.handleFileInputChange}
              />
              <Box sx={webStyle.modalTextTitleContainer}>
                {this.state.editableType === "title" ? (
                  <TextField
                  data-test-id= "title-input"
                    type="text"
                    name="title"
                    value={this.state?.editItemData?.attributes?.exercise_name}
                    onChange={(e) => this.handleInputChange(e, "title")}
                    onBlur={this.handleInputBlur}
                    autoFocus
                  />
                ) : (
                  <Typography
                    style={webStyle.modalTextTitle}
                    id="modal-modal-title"
                  >
                    {this.state?.editItemData?.attributes?.exercise_name}
                  </Typography>
                )}
                <img
                  data-test-id='title-edit'
                  style={webStyle.iconEdit}
                  src={imgEdit}
                  onClick={() => this.handleEditIconClick("title")}
                />
              </Box>
              <Box sx={{marginTop: 10}}>
                <Select
                  native
                  value={this.state?.editItemData?.attributes?.category}
                  onChange={(e) => this.handleInputChange(e, "category")}
                >
                  {this.state.listCategory.map((category: string) => {
                    return <option value={category}>{category}</option>
                  })}
                </Select>
                </Box>
              <Box sx={webStyle.modalTagContainer}>
                
              {[...new Set(this.state?.editItemData?.attributes?.add_tags.map((item :any) => item.name))]?.map(
                  (uniqueName: any, index: number) => {
                    const item = this.state?.editItemData?.attributes?.add_tags?.find((obj :any) => obj && obj.name === uniqueName);
                    return (
                      <Box key={index} sx={webStyle.tagItem}>
                        <Typography>{`${item.name}`}</Typography>
                        <CloseIcon
                          data-test-id='delete'
                          fontSize="small"
                          onClick={() => this.handleDeleteTag(item)}
                        />
                      </Box>
                    );
                  }
                )}
                <Box
                  sx={webStyle.addTagButton}
                  data-testid="open-add-tag"
                  onClick={this.handleOpenModal}
                >
                  <Typography>Add Tag</Typography>
                  <AddIcon fontSize="small" />
                </Box>
              </Box>
              <Box display='flex' flex={1}/>
              <Box sx={webStyle.modalButtonContainer}>
                <Box sx={webStyle.modalButtonCancel} onClick={this.handleCloseModal}>
                  <Typography>Cancel</Typography>
                </Box>
                 <Box
              data-test-id='save'
              onClick={() => {
                this.handleSave();
              }}
              sx={webStyle.modalButtonSave}
            >
              <Typography>Save</Typography>
            </Box>
               
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }
  renderButtonHeader = () => {
    return (
      <Box sx={webStyle.buttonHeaderContainer}>
        {this.listTab.map((tab) => {
          const textTabStyle =
            tab === this.state.activeTab
              ? webStyle.tabTextActive
              : webStyle.tabTextInactive;
          return (
            <Box
              key={tab}
              data-test-id="button-change-tab"
              onClick={() => this.handleChangeTab(tab)}
              sx={webStyle.tabButton}
            >
              <Typography style={textTabStyle}>{tab}</Typography>
            </Box>
          );
        })}
      </Box>
    );
  };
  /* istanbul ignore next */
  renderListTagTab = () => {
    const { searchInput, selectedFilter } = this.state;
    let displayedList = [];
    if (searchInput && selectedFilter !== "") {
      displayedList = this.state.filteredTag;
    } else if (searchInput) {
      displayedList = this.state.filteredTag;
    } else if (selectedFilter !== "") {
      displayedList = this.state.sortedTagList;
    } else {
      displayedList = this.state.listTag;
    }

    return (
      <Box sx={webStyle.listTagContainer}>
        <Box sx={webStyle.listTagContent}>
          {displayedList.map((tag) => (
            <Box key={tag} sx={webStyle.tagComponent}>
              <Typography style={webStyle.textTag}>{tag}</Typography>
              <Box
                data-test-id="button-delete-tag"
                onClick={() => this.deleteTagApiCall(tag)}
              >
                <CloseIcon fontSize="small" />
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={webStyle.buttonAddTag}>
          <CommonButton
            onClickButton={this.handleOpenModalAddTag}
            renderRightIcon={<AddIcon style={webStyle.iconAdd} />}
            mode="primary-rounded"
            width={169}
            height={56}
            testId="button-add-tag"
            label="Add New Tag"
          />
        </Box>
      </Box>
    );
  };
  renderListCategoryTab = () => {
    return (
      <Box sx={webStyle.listTagContainer}>
        <Box sx={webStyle.listTagContent}>
          {this.state.listCategory.map((category) => (
            <Box key={category} sx={webStyle.tagComponent}>
              <Typography style={webStyle.textTag}>{category}</Typography>
              <Box
                data-test-id="button-delete-tag"
                onClick={() => this.deleteCategoryApiCall(category)}
              >
                <CloseIcon fontSize="small" />
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={webStyle.buttonAddTag}>
          <CommonButton
            onClickButton={this.handleOpenModalAddCategory}
            renderRightIcon={<AddIcon style={webStyle.iconAdd} />}
            mode="primary-rounded"
            width={180}
            height={56}
            testId="button-add-category"
            label="Add New Category"
          />
        </Box>
      </Box>
    );
  };
  renderListVideoTab = () => {
    return <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly'
    }}>
      {this.state?.sampleData.slice(0, this.state.videoDisplayLimit)?.map((item: any) =>
        <VideoItem 
          key={item?.id}
          item={item}
          handleOpenModalEdit={this.handleOpenModalEdit}
          handleDeleteVideo={()=>{this.deleteConfirmation(item.id)}}
        />
      )}
    </Box>
      {this.isShowLoadMoreBtn() ? (
        <Box
          onClick={this.handleLoadmoreVideo}
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 30,
            marginBottom: 50,
            borderWidth: 1,
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
            cursor: 'pointer',
          }}
        >
          <Typography variant="h6">Load more</Typography>
        </Box>
      ) : undefined}
    </>
  }
  renderModalAddTag = () => {
    return (
      <CommonModalAddTags
      data-testid="modal-button-close"
        popupHeading="Add New Tag"
        openModal={this.state.openModalAddTag}
        handleCloseModal={this.handleCloseModalAddTag}
        allTags={this.state.listTag}
        handlePositiveClick={this.addNewTagApiCall}
      />
    );
  };
  renderModalAddCategory = () => {
    return (
      <CommonModalAddTags
      data-testid="modal-button-close"
        popupHeading="Add New Category"
        openModal={this.state.openModalAddCategory}
        handleCloseModal={this.handleCloseModalAddCategory}
        allTags={this.state.listCategory}
        handlePositiveClick={this.addNewCategoryApiCall}
      />
    );
  };
  renderListScreen = () => {
    switch (this.state.activeTab) {
      case this.listTab[0]:
        return this.renderListVideoTab()
      case this.listTab[1]:
        return this.renderListTagTab()
      case this.listTab[2]:
        return this.renderListCategoryTab()
      default: this.renderListVideoTab()
    }
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box width="100%" sx={{ overflow: "scroll" }}>
          {this.renderFilterModal()}
          {this.renderModalEdit()}
          {this.renderModalAddTag()}
          {this.renderModalAddCategory()}
          {<CommonModalDelete
              message={ this.state.editfailmsg}
              handlePositiveButton={this.handlePositiveButton}
              handleNegativeButton={this.handleNegativeButton}
              showDeleteModal={this.state.editsuccessmodal}
              negativeButtonTitle="Cancel"
              showNegativeButton={this.state.editfailmsg === "Are you sure you want to delete this video?" ?true :false}
              positiveButtonTitle="OK"
              showPositiveButton={true}
              handleCloseModal={this.handlePositiveButton} />}

          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.headerContainer}>
              <Typography style={{ flex: 1 }} variant="h6">
                Video Library
              </Typography>
              <Box sx={webStyle.inputContainer}>
                <img src={imgSearch} width={24} height={24} />
                <InputBase
                  test-dataId='input-base'
                  placeholder="Search"
                  style={webStyle.input}
                  value={this.state.searchInput}
                  onChange={(e) => {
                    this.isVideoTabActive() ?
                      this.searchInputFilter(e.target.value) : this.handleTagSearchLocally(e.target.value);
                  }}
                />
              </Box>
              <Link style={webStyle.link} to="/BulkUploading">
                <Box sx={webStyle.addVideoButton}>
                  <Typography>Add Video</Typography>
                  <AddIcon fontSize="small" />
                </Box>
              </Link>
            </Box>
            <Box sx={webStyle.filterContainer}>
              {this.renderButtonHeader()}
              {this.state.activeTab !== this.listTab[2] && <Box
                data-testid="filter-button"
                aria-describedby={this.idFilter}
                onClick={this.handleClickFilter}
                sx={{ ...webStyle.filterButton, borderColor: this.state.sortEnabled ? 'black' : '#a4a3a3' }}
              >
                <Typography style={{ fontWeight: this.state.sortEnabled ? 'bold' : 'normal' }}>
                  {this.isVideoTabActive() ? "Category" : "Order by"}
                </Typography>
                <img
                  style={webStyle.iconFilter}
                  src={this.isVideoTabActive() ? imgFilter : imgSort}
                />
              </Box>}
            </Box>
            {this.renderListScreen()}
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#faf9f6",
    paddingTop: 14,
    paddingLeft: 30,
    paddingRight: 30,
  },
  inputContainer: {
    borderRadius: 10,
    border: "1px solid #CBD5E1",
    background: "#fff",
    height: 40,
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 365,
    marginRight: 10,
  },
  input: {
    width: "100%",
    marginLeft: 10,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconAdd: {
    width: 24,
    height: 24,
    marginLeft: 8,
  },
  tagComponent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "0.76px solid #EEECE7",
    background: "#fff",
    borderRadius: 13,
    marginRight: 14,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
  },
  textTag: {
    fontSiz: 14,
    fontWeight: 400,
    color: "#313320",
    marginRight: 10,
  },
  buttonAddTag: {
    marginTop: 64,
    alignSelf: "flex-end",
  },
  addVideoButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    background: "#e53600",
    padding: 10,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 16,
    color: "#fff",
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 36,
    justifyContent: "space-between",
    marginBottom: 18,
    fontSize: 16,
  },
  buttonHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tabButton: {
    marginRight: 14,
    marginLeft: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tabTextActive: {
    fontSize: 16,
    fontWeight: 700,
    color: "#313320",
  },
  tabTextInactive: {
    fontSize: 16,
    fontWeight: 400,
    color: "#939393",
  },
  listTagContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  listTagContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  filterButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 8,
    color: "#000",
    border: "1px solid #a4a3a3",
    padding: 4,
    paddingLeft: 16,
    paddingRight: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  videosContainer: {
    flex: 1,
  },
  image: {
    width: "100%",
    height: 140,
    borderRadius: 16,
  },
  videoItemContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    background: "#fff",
    padding: 12,
    borderRadius: 15, 
    overflow: "hidden",
    "&:hover": {
      cursor: "pointer",
    },
  },
  videoItemSelf:{
    overflow: "hidden",
    borderRadius: 15,
  },
  filterModalContainer: {
    display: "flex",
    width: 300,
    flexDirection: "column",
    padding: 4,
    background: "#FBFAF7",
    fontSize: 16,
    borderRadius: "17px",
  },
  filterModalItem: {
    display: "flex",
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 16,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterModalItemSelected: {
    background: "#EEECE7",
  },
  iconFilter: {
    width: 15,
    height: 15,
    marginLeft: 8,
  },
  iconVideoItem: {
    width: 14,
    height: 14,
    marginRight: 1,
  },
  link: {
    textDecoration: "none",
  },
  modalWapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 631,
    height: 686,
    borderRadius: 16,
    background: "#fff",
    overflow: "auto",
  },
  addtagmodalContainer: {
    width: 631,
    height: 686,
    display: "flex",
    overflow: "auto",
    background: "#fff",
    borderRadius: 16,
    flexDirection: "column",
  },
  modalHeader: {  
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 31,
    paddingRight: 20,
    background: "#edece6",
  },
  modalContentContainer: {
    paddingTop: 19,
    paddingRight: 31,
    paddingLeft: 31,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  modalTextTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 21,
  },
  modalTextTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: "#313320",
    marginRight: 4,
  },
  modalTextDescription: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    marginTop: 4,
  },
  iconEdit: {
    width: 17,
    height: 17,
  },
  modalTagContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: 'wrap',
    gridColumnGap: 10,
    marginTop: 12,
    marginBottom: 12,
    "&::-webkit-scrollbar": {
      cursor: "pointer",
    },
  },
  tagItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 13,
    color: "#747474",
    padding: 8,
    marginTop: 8,
    background: "#EEECE7",
    paddingLeft: 16,
    paddingRight: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  addTagButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gridColumnGap: 10,
    borderRadius: 13,
    color: "#313320",
    padding: 8,
    marginTop: 8,
    background: "#fff",
    paddingLeft: 16,
    paddingRight: 16,
    border: "0.766px solid #EEECE7",
    "&:hover": {
      cursor: "pointer",
    },
  },
  modalButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 33,
    borderTop: "0.5px solid #EEECE7",
    height: 61,
  },
  modalButtonCancel: {
    padding: 8,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 13,
    "&:hover": {
      cursor: "pointer",
    },
  },
  modalButtonSave: {
    border: "1px solid #E73700",
    padding: 8,
    color: "#E73700",
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 13,
    "&:hover": {
      cursor: "pointer",
    },
  },
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    opacity: 0.5,
    zIndex:9999,
  },
};
// Customizable Area End
