import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

export const generateRequestMessage = (endpoint: any, type: any, additionalHeaders?: any) => {
    const headers = {
      "content-type": "application/json",
      token: typeof window !== 'undefined' && localStorage.getItem("admintoken") ,
      ...(additionalHeaders || {}),
    };
  
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const addSlash = !endpoint.startsWith("/") && !endpoint.startsWith("http");
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        addSlash ? `/${endpoint}` : endpoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        type
    );
    return requestMessage;
  };

export const generateRequestMessageData = (endpoint: any, type: any, additionalHeaders?: any) => {
    const headers = {
      token: typeof window !== 'undefined' && localStorage.getItem("admintoken") ,
      ...(additionalHeaders || {}),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    const addSlash = !endpoint.startsWith("/") && !endpoint.startsWith("http");
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        addSlash ? `/${endpoint}` : endpoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        type
    );
    return requestMessage;
  };