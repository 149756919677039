// Customizable Area Start
import { ChangeEvent } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const baseURL = require("../../../framework/src/config.js").baseURL;

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  file: any
}

interface SS {
  id: any;
}

export default class UploadCSVController extends BlockComponent<
  Props,
  S,
  SS
> {

  uploadCSVApiCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      file: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      console.log(e.target.files[0]);
      this.setState({
        file: e.target.files[0]
      })
    }
  };

  upload = () => {

    if(!this.state.file) {
      alert("Please select a csv file");
      return;
    }
    let formdata = new FormData();
    formdata.append("file", this.state.file);
    fetch(`${baseURL}/bx_block_videos8/videos/bulk_upload`, {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        alert(res?.message);
      })
      .catch(error => console.log('error', error));
  }

}
// Customizable Area End
