// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import {DisplayFlow as IDisplayPhase} from "../../../components/src/PreviewFlow";
import { findMaxPosAndMaxNeg, toDuration } from '../../../components/src/util';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  dataWorkout: any;
  displayPhases: IDisplayPhase[];
  currentVideoIndex: number;
  isInWorkoutTime: boolean;
  isPlaying: boolean;
  zoneData: any
  isHavingNextWorkout: boolean;
  loading: boolean;
  minuteForNextWorkout: number;
  templateData: any;
  isForkPlay: boolean
  restMinuteForNextSlot: number
  workoutTimeSlots: { hour: number, minute: number, pastTime: number }[];
  currentSlot: { hour?: number, minute?: number };
}

interface SS {
  id: any;

}

export default class ZoneDisplayController extends BlockComponent<Props, S, SS> {
    getWorkoutMessageId: string = "";
    getTemplateMessageId: string = "";
    getZoneTodayMessageId: string = "";
    getPlayingStatusMessageId: string = "";
    updatePlayingStatusMessageId: string = "";
    nextTimeSlotInterval: any = null
    fetchingTodayZoneTimeoutId: any = null
    statusPlayingInterval: any = null
    coreDisplayRef: any = React.createRef();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dataWorkout: null,
      displayPhases: [],
      currentVideoIndex: 0,
      isInWorkoutTime: false,
      zoneData: { 1: { set1: "", set2: "" }, 2: { set1: "", set2: "" }, 3: { set1: "", set2: "" }, 4: { set1: "", set2: "" }, 5: { set1: "", set2: "" }, 6: { set1: "", set2: "" }, 7: { set1: "", set2: "" }, 8: { set1: "", set2: "" }, 9: { set1: "", set2: "" } },
      isHavingNextWorkout: false,
      minuteForNextWorkout: 0,
      templateData: null,
      loading: true,
      isPlaying: false,
      isForkPlay: false,
      restMinuteForNextSlot: 0,
      workoutTimeSlots: [],
      currentSlot: {}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();

    this.getZoneTodayData()
  }
 
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(this.getZoneTodayMessageId === apiRequestCallId) {
        this.handleReceiveTodayZone(responseJson)
      }
      if(this.getTemplateMessageId === apiRequestCallId) {
        this.getPotentialWorkout(responseJson.data)
        this.setState({templateData: responseJson.data})
      }
      if(this.getWorkoutMessageId === apiRequestCallId) {
        const dataWorkout = responseJson
        this.setState({dataWorkout, isInWorkoutTime: false, loading: false, isPlaying: false}, () => {
          this.handleDataPreview()
        })
      }
      this.handlePlayingStatus(apiRequestCallId, responseJson)
    }
  }

  getPotentialWorkout = (templateData: any) => {
    const workoutTimes = [...this.state.workoutTimeSlots.map(time => time.pastTime)]
    let maxWorkoutTime = 0
    let minRestTime = 0
    const { max, min } = findMaxPosAndMaxNeg(workoutTimes, toDuration(templateData.attributes.workout_time))
    maxWorkoutTime = max
    minRestTime = min
    if(maxWorkoutTime > 0){
      const potentialSlot = this.state.workoutTimeSlots.find(slot => slot.pastTime === maxWorkoutTime)
      this.setState({ 
        isForkPlay: true, 
        restMinuteForNextSlot: Math.abs(maxWorkoutTime - toDuration(templateData.attributes.workout_time)),
        currentSlot: { hour: potentialSlot?.hour, minute: potentialSlot?.minute }
      }, () => {
        this.forkPlayVideo()
      })
    } else { // for case there's no workout in progress
      const potentialNextSlot = this.state.workoutTimeSlots.find(slot => slot.pastTime === minRestTime)
      this.cleanInterval(potentialNextSlot)
    } 
  }

  cleanInterval = (nextSession: { pastTime: number; hour: number; minute: number } | undefined) => {
    if (this.nextTimeSlotInterval !== null) {
      clearInterval(this.nextTimeSlotInterval);
    }
    const currentTime = moment()
    const currentHour = parseInt(currentTime.format('HH'))
    const currentMinute = parseInt(currentTime.format('mm'))
    const nextSessionIn = nextSession ? ((nextSession?.hour - currentHour) * 60 + (nextSession?.minute - currentMinute)) : 0 
    this.setState(
      { isHavingNextWorkout: nextSessionIn > 0, minuteForNextWorkout: nextSessionIn },
      () => {
        setTimeout(() => {
          this.countDownToNextWorkout();
        }, (60 - parseInt(moment().format('ss'))) * 1000);
      }
    );
  }

  handlePlayingStatus = (apiRequestCallId: any, responseJson: any) => {
    if(this.getPlayingStatusMessageId === apiRequestCallId) {
      if (responseJson.reload_message && responseJson.reload_message === "true") {
        this.props.navigation.refresh();
      }
      if(responseJson.messages === "false" && this.state.isPlaying){
        this.setState({isPlaying: false}, () => {
          this.coreDisplayRef.current.pause();
        })
      }
      if(responseJson.messages === "true" && !this.state.isPlaying){
        this.setState({isPlaying: true}, () => {
          this.coreDisplayRef.current.resume();
        })
      }
    }
  }
  handleReceiveTodayZone = (responseJson: any) => {
    const videoTemplateId = responseJson.video_template_id
    const templateId = responseJson.template_id
    if(videoTemplateId){
      this.getWorkoutData(videoTemplateId);
      this.getTemplateData(templateId);
    }else{
      this.setState({loading: false})
    }
    const listTimeSlot = responseJson.time_slots
    this.setupTimer(listTimeSlot, templateId)
  }
  setupTimer = (timeSlots: any, templateId: any) => {
    if (this.fetchingTodayZoneTimeoutId !== null) {
      clearTimeout(this.fetchingTodayZoneTimeoutId)
    }
    this.startUpdateTimeSlotTimer()
    if(timeSlots && templateId){
      this.startCountDownPlayVideo(timeSlots)
    }
  }
  
  /* istanbul ignore next */
  startCountDownPlayVideo = (timeSlots: any) => {
    if(timeSlots.length && timeSlots.length === 0){
      return
    }
    const currentTime = moment()
    const currentHour = parseInt(currentTime.format('HH'))
    const currentMinute = parseInt(currentTime.format('mm'))
    const currentSecond = parseInt(currentTime.format('ss'))
     
    let workoutTimeSlots: { hour: number; minute: number; pastTime: number }[] = []
    timeSlots.forEach((timeSlot: any) => {
      const {hour, minute} =  this.getTimeFromTimeSlots(timeSlot)
      const pastHour = currentHour - hour
      const pastMinute = currentMinute - minute
      const pastTime = pastHour * 3600 + pastMinute * 60 + currentSecond
      workoutTimeSlots.push({ hour, minute, pastTime})
    })
    this.setState({ workoutTimeSlots })
  }
  setCountDownNextWorkoutState = () => {
    const TIME_START = 2
    //@ts-ignore
    this.setState((prevState: S) => {
      if (prevState.minuteForNextWorkout > TIME_START) {
        return { minuteForNextWorkout: prevState.minuteForNextWorkout - 1 };
      } else if (prevState.minuteForNextWorkout === TIME_START && prevState.isHavingNextWorkout === true) {
        clearInterval(this.nextTimeSlotInterval);
        this.playVideo()
        return { minuteForNextWorkout: 0, isHavingNextWorkout: false };
      }
      return null;
    });
  }
  countDownToNextWorkout = () => {
    this.setCountDownNextWorkoutState()
    this.nextTimeSlotInterval = setInterval(() => {
      this.setCountDownNextWorkoutState()
    }, 60 * 1000)
  }
  playVideo = () => {
    this.updatePlayingStatusData(true)
    this.setState({isInWorkoutTime: true, isPlaying: true, isForkPlay: false}, () => {
      if(this.coreDisplayRef.current) {
        this.coreDisplayRef.current.reset();
        this.coreDisplayRef.current.resume();
        this.statusPlayingInterval = setInterval(() => {
          this.getPlayingStatusData()
        }, 2000)
      }
    })
  }
  getTimeFromTimeSlots = (timeSlot: any) => {
    if(timeSlot.start_time){
      const time = timeSlot.start_time.split(",")[0]
      const timeStyle = timeSlot.start_time.split(",")[1]
      let hour = parseInt(time.split(":")[0])
      let minute = parseInt(time.split(":")[1])
      const hoursAdded = timeStyle === "PM" ? 12 : 0
      hour = hour === 12 ? hour : hour + hoursAdded
      return {hour, minute}
    }
   return {hour: 0, minute: 0}
  }
  startUpdateTimeSlotTimer = () => {
    const MINUTE_CHECK = 25
    const MILLISECOND_MINUTE = 1000 * 60
    const currentTime = moment()
    const currentMinute = parseInt(currentTime.format('mm'))
    const timeOutStartGetNewData = MINUTE_CHECK - currentMinute
    if(timeOutStartGetNewData > 0){
      this.fetchingTodayZoneTimeoutId = setTimeout(() => {
        this.getNewDataAndStartIntervalUpdate()
      }, timeOutStartGetNewData * MILLISECOND_MINUTE);
    }else{
      const extentTime = timeOutStartGetNewData <= -30 ? 60 : 30
      this.fetchingTodayZoneTimeoutId = setTimeout(() => {
        this.getNewDataAndStartIntervalUpdate()
      }, (timeOutStartGetNewData + extentTime) * MILLISECOND_MINUTE );
    }
  }
  onEndAWorkoutPlaying = () => {
    this.updatePlayingStatusData(false)
    clearInterval(this.statusPlayingInterval)
    this.setState({ currentSlot: {} })
    this.getZoneTodayData();
  }
  getNewDataAndStartIntervalUpdate = () => {
    if (!this.state.isInWorkoutTime) {
      this.getZoneTodayData();
    }
  }
  
  addStationPhase = (displayPhases: any, dataWorkout: any, currentZone: number, currentLap: number ) => {
    for (let i = 0; i < dataWorkout.station; i++) {
      dataWorkout.exercise_sets.forEach((setDataItem: any, index: number) => {
        displayPhases.push({
          phase: `SET_${index + 1}`,
          time: setDataItem.set_time,
          currentZone,
          currentLap,
          currentSet: index + 1
        });
        displayPhases.push({
          phase: "REST",
          time: setDataItem.rest_time,
          currentZone,
          currentLap,
          currentSet: index + 1
        });
      });
    }
  }
  
  renderZoneData = (formatted_station_data: any, zoneSelected: number) => {
    const listDataZoneSelected = formatted_station_data.filter((stationData: any) => {
      return stationData.zone_id === zoneSelected
    } )
    let listStationVideo = {}
    listDataZoneSelected.forEach((stationData: any, index: number) => {
      let dataStationItem: any = {}
      stationData.sets.forEach((set: any, index: number) => {
        dataStationItem[`set${index + 1}`] = {video_url: set.video_url}
      });
      listStationVideo = {
        ...listStationVideo,
        [index + 1] : dataStationItem
      }   
    })
    return listStationVideo
  }
  addDataZone = (zoneNumber: number, workoutAttributes: any, displayPhases: any) => {
    for (let i = 1; i <= workoutAttributes.number_of_loops; i++) {
      this.addStationPhase(displayPhases,workoutAttributes, zoneNumber, i);
    }
    displayPhases.pop(); // remove Rest phase at last
  }
  /* istanbul ignore next */
 getCorrectTimeOfPhase = (phaseTime: any) => {
    if (typeof phaseTime === 'string') {
        if (phaseTime.includes("Minutes")) {
            const data = phaseTime.split(" ")
            return parseFloat(data[0]) * 60
        }
        if (phaseTime.includes("Seconds")) {
            const data = phaseTime.split(" ")
            return parseInt(data[0])
        }
    }
    return parseInt(phaseTime)
}
/* istanbul ignore next */
  forkPlayVideo = () => {
    if(this.state.displayPhases.length > 0 && this.state.isForkPlay && this.state.restMinuteForNextSlot !== 0){
      let totalTimeOfDisplayPhases = 0
      const newDisplayPhases = [...this.state.displayPhases]
      for (let index = 1; index < newDisplayPhases.length; index++) {
        const displayPhase = newDisplayPhases[index];
        if(displayPhase.time){
          const timeOfPhase =  this.getCorrectTimeOfPhase(displayPhase.time)
          totalTimeOfDisplayPhases += timeOfPhase
        }
      }
      const secondOfRestMinute = this.state.restMinuteForNextSlot
      if((totalTimeOfDisplayPhases - 60) > secondOfRestMinute){
        this.playVideo()
      }
    }

  }
 
  /* istanbul ignore next */
  handleDataPreview = () => {
    const dataWorkout = this.state.dataWorkout
    if( dataWorkout){
      const videoInfo = dataWorkout.video_info
      const workoutAttributes = dataWorkout.data.attributes
      const displayPhases: any[] = []
      const zoneId = this.props.navigation.getParam("zoneId")
      const listDataZone = this.renderZoneData(workoutAttributes.formatted_station_data, parseInt(zoneId))
      displayPhases.push({ phase: 'PREPARE', time: 60 });
      
      displayPhases.push({ phase: "INTRO", time: videoInfo.introduction.introduction_time, currentZone: 1, currentLap: 1 });
      displayPhases.push({ phase: "DEMO", time: videoInfo.demo.demo_time, currentZone: 1, currentLap: 1 });
      displayPhases.push({ phase: "WARM_UP", time: videoInfo.warmup.warm_up_time, currentZone: 1, currentLap: 1 });
      

      displayPhases.push({ phase: "GET_STARTED", time: 10 });
      

      this.addDataZone(1, workoutAttributes, displayPhases)
      displayPhases.push({ phase: "CHANGE_ZONE", time: workoutAttributes.change_zone, currentLap: workoutAttributes.number_of_loops });
      this.addDataZone(2, workoutAttributes, displayPhases)
      displayPhases.push({ phase: "CHANGE_ZONE", time: workoutAttributes.change_zone, currentLap: workoutAttributes.number_of_loops });
      this.addDataZone(3, workoutAttributes, displayPhases)
      displayPhases.push({ phase: "CHANGE_ZONE", time: workoutAttributes.change_zone, currentLap: workoutAttributes.number_of_loops });


      displayPhases.push({ phase: 'COOL_DOWN', time: videoInfo.cooling_down.cooling_down_time, currentZone: 3, currentLap: workoutAttributes.number_of_loops });
      displayPhases.push({ phase: "END" });

      displayPhases.forEach((element, index) => element.id = index);
      this.setState({ displayPhases, zoneData: listDataZone}, () => {
        this.forkPlayVideo()
      });
    }
  }
  getPlayingStatusData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    this.getPlayingStatusMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStatusPlayingEndPoint + `?screen=workout&zone_id=${this.props.navigation.getParam("zoneId")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dragDropInterfaceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updatePlayingStatusData = (status: boolean) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    this.updatePlayingStatusMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateStatusPlayingEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ play: status })
  );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getZoneTodayData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    this.getZoneTodayMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getZoneTodayAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dragDropInterfaceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getWorkoutData = (workoutId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    this.getWorkoutMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWorkoutAPIEndPoint + `/${workoutId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dragDropInterfaceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTemplateData = (templateId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    this.getTemplateMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTemplateAPIEndPoint + `/${templateId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dragDropInterfaceApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}
// Customizable Area End