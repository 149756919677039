import { Workout } from "./types";
export const exerciseData: Workout[] = [
  {
    id: 1,
    name: "Squats",
    imageUrl:
      "https://post.healthline.com/wp-content/uploads/2019/03/Female_Squat_Studio_732x549-thumbnail-2.jpg"
  },
  {
    id: 2,
    name: "Push-ups",
    imageUrl:
      "https://images.pexels.com/photos/176782/pexels-photo-176782.jpeg?cs=srgb&dl=pexels-keiji-yoshiki-176782.jpg&fm=jpg"
  },
  {
    id: 3,
    name: "Abdominal Crunches",
    imageUrl:
      "https://www.muscleandfitness.com/wp-content/uploads/2020/04/Man-Doing-Crunches-At-Home.jpg?quality=86&strip=all"
  },
  {
    id: 4,
    name: "Lunges",
    imageUrl:
      "https://www.verywellfit.com/thmb/2M77NtV1A9m_tN9QupDJpGm_dgE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/About-8-2911936-Low-Lunge-Kneel-Hamstring-Stretch01-131-12eb2bbe1c5c462e80313c7de44edd2e.jpg"
  },
  {
    id: 5,
    name: "Plank",
    imageUrl:
      "https://img.freepik.com/free-photo/young-fitness-man-studio_7502-5008.jpg"
  },
  {
    id: 6,
    name: "Burpees",
    imageUrl:
      "https://img.freepik.com/free-photo/young-fitness-man-studio_7502-5008.jpg"
  },
  {
    id: 7,
    name: "Mountain Climbers",
    imageUrl:
      "https://img.freepik.com/free-photo/young-fitness-man-studio_7502-5008.jpg"
  },
  {
    id: 8,
    name: "Jumping Jacks",
    imageUrl:
      "https://img.freepik.com/free-photo/young-fitness-man-studio_7502-5008.jpg"
  },
  {
    id: 9,
    name: "Bicycle Crunches",
    imageUrl:
      "https://img.freepik.com/free-photo/young-fitness-man-studio_7502-5008.jpg"
  },
  {
    id: 10,
    name: "High Knees",
    imageUrl:
      "https://img.freepik.com/free-photo/young-fitness-man-studio_7502-5008.jpg"
  }
];
