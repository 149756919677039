import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import AccessAlarms from "@material-ui/icons/AccessAlarms";
import moment from "moment";
import CommonModalWorkout from "../../../components/src/CommonModalWorkout"

const theme = createTheme({
  palette: {
    text: {
      primary: "#000",
      secondary: "#fff"
    },
    primary: {
      main: "#000",
      contrastText: "#fff"
    },
    secondary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
});
// Customizable Area End

import DashboardController, { Props } from "./DashboardController";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCardWorkout(cardItem: any) {
    const getEventName = () => {
      if(cardItem.data){
        return cardItem.data.templateName
      }
      if(cardItem.hideEvent){
        return ""
      }
      return "No Event"
    }
    return (
      <Box
        key={cardItem.id.toString()}
        onClick={() => this.handleShowModalWorkout(cardItem)}
        sx={webStyle.cardWrapper}
        data-testid="card-workout-item"
      >
        <Box display="flex" sx={webStyle.cardIconContainer}>
          <img width={16} height={16} src={cardItem.icon} />
        </Box>
        <Typography style={{...webStyle.textDesCard, marginTop: getEventName() === "" ? 65 : 52}} variant="body1">
          {getEventName()}
        </Typography>
        <Box sx={webStyle.cardContent}>
          <Typography style={webStyle.textContentCard}>{cardItem.id !== 3 ? cardItem.text : `${this.state.dayRemaining} days remaining`}</Typography>
          <Box sx={webStyle.buttonCardIcon}> 
            <ArrowForwardOutlined color="secondary" />
          </Box>
        </Box>
      </Box>
    );
  }
  renderCardRoyal(data: any) {
    const imageUrl = this.state.selectedSchedule.workout_image_url === "" ?
     require('../../../components/src/unified_logo.png') :
      this.state.selectedSchedule.workout_image_url
    const imageStyle = this.state.selectedSchedule.workout_image_url === "" ? {background: "#313320"} : {background: "transparent"}
    return (
      <Box sx={webStyle.cardRoyalContainer}>
        <img style={{...webStyle.image, ...imageStyle,  objectFit: 'contain'}} src={imageUrl} alt="mainImg" />
        <Box
          sx={webStyle.contentCardWapper}
        >
          <Box sx={webStyle.contentCardBattle}>
            <AccessAlarms fontSize="small" color="primary" />
            <Typography style={webStyle.royalCardDate}>{data.start_time ? `${data.start_time.replace(/,/g, '')}` : ""}</Typography>
          </Box>
          <Typography style={webStyle.royalCardName}>{this.state.selectedSchedule?.workout_name}</Typography>
        </Box>
      </Box>
    );
  }
  renderDateElement = (currentDate: any, handleDateClick: any,currentMonth: any) => {
    const isCurrentMonth = currentDate.isSame(currentMonth, "month");
    const today = moment();
      const isToday = currentDate.isSame(today, "day");
      const currentTimeSlot = this.state.listScheduleSlot.find(
        (timeSlot: any) => {
          return currentDate.isSame(
            moment(timeSlot.attributes.schedule_date, "YYYY-MM-DD")
          );
        }
      );
      const styleTextMonth = isCurrentMonth
        ? { ...webStyle.textMonth, color: isToday ? "#fff" : "#747474" }
        : {...webStyle.textMonthBlur ,  color: isToday ? "#fff" : "#ababab"};
      const onPressedDate = handleDateClick(currentDate);
      const styleDayMonthTextContainer = isToday
        ? webStyle.dayMonthTextTodayContainer
        : webStyle.dayMonthTextContainer;
    return (
      <Box
        onClick={currentTimeSlot && currentTimeSlot.attributes.video_template_id !== null ? onPressedDate : () => {}}
        key={currentDate.format("YYYY-MM-DD")}
        sx={{
          ...webStyle.dayMonthContainer,
          ...styleTextMonth
        }}
      >
        <Box
          sx={{
            ...styleDayMonthTextContainer
          }}
        >
          <Typography>{currentDate.format("D")}</Typography>
        </Box>
        {currentTimeSlot && currentTimeSlot.attributes.video_template_id !== null ? (
          <Box key={currentDate.format("YYYY-MM-DD")} sx={webStyle.battleRoyalEventBox}>
            <Typography style={webStyle.textEventBox}>{currentTimeSlot.templateName}</Typography>
          </Box>
        ) : (
          <Box key={currentDate.format("YYYY-MM-DD")} sx={webStyle.noEventBox}>
            <Typography style={webStyle.textNoEventBox}>No event</Typography>
          </Box>
        )}
      </Box>
    );
  }
  renderCalendarWorkout = () => {
    const weekdays = moment.weekdaysShort();
    const currentMonth = this.state.currentMonth;

    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const startDate = startOfMonth.startOf("week");
    const endDate = endOfMonth.endOf("week");

    const calendar = [];
    let row = [];
    const weekdaysRow = weekdays.map(weekday => (
      <Box key={weekday} sx={webStyle.weekDay}>
        <Typography style={webStyle.textWeek}>{weekday}</Typography>
      </Box>
    ));
    calendar.push(<Box sx={webStyle.weekDayRow}>{weekdaysRow}</Box>);

    let currentDate = startDate;
    const today = moment();
    const handleDateClick = (date: any) => {
      return () => {
        this.handleChangeDayBattle(date);
      };
    };
    while (currentDate.isSameOrBefore(endDate)) {
      
      const dateElement = this.renderDateElement(currentDate, handleDateClick, currentMonth)
      row.push(dateElement);
      if (currentDate.day() === 6) {
        calendar.push(
          <Box sx={webStyle.monthDayRow} key={currentDate.format("YYYY-MM-DD")}>
            {row}
          </Box>
        );
        row = [];
      }

      currentDate = currentDate.clone().add(1, "day");
    }

    if (row.length > 0) {
      calendar.push(
        <Box sx={webStyle.monthDayRow} key={currentDate.format("YYYY-MM-DD")}>
          {row}
        </Box>
      );
    }
    return (
      <Box key={this.state.currentMonth.format("MMM, YYYY")} sx={webStyle.calendarWorkoutContainer}>
        <Box sx={webStyle.calendarWorkoutHeader}>
          <Box sx={webStyle.rowCenterMode}>
            <Typography style={webStyle.textMonthHeader}>
              {this.state.currentMonth.format("MMM, YYYY")}
            </Typography>
            <IconButton
              data-testid="btn-prev-month"
              onClick={this.handlePrevMonth}
            >
              <ArrowBackIos color="primary" fontSize="small" />
            </IconButton>
            <IconButton
              data-testid="btn-next-month"
              onClick={this.handleNextMonth}
            >
              <ArrowForwardIos color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <Typography style={webStyle.textDateTimeHeader}>
            {this.state.dayBattleSelected.format("dddd, D MMMM, YYYY")}
          </Typography>
        </Box>
        {calendar}
      </Box>
    );
  };
  renderListBattleRoyal() {
    return (
      <Box display="flex" sx={webStyle.listBattleRoyalContainer}>
        <Typography style={webStyle.textHeaderRoyal}>
          {`${
            this.state.selectedSchedule
              ? this.state.selectedSchedule.templateName
              : "No Event"
          } | ${this.state.dayBattleSelected.format("D MMMM")}`}
        </Typography>
        <Box sx={webStyle.columnMode}>
          {this.state.selectedSchedule &&
            this.state.selectedSchedule.attributes.time_slots.map((item: any) =>
              this.renderCardRoyal(item)
            )}
        </Box>
      </Box>
    );
  }
  renderModalWorkout() {
    return (
      <CommonModalWorkout
        headerTitle={this.state.openModal ? this.state.openModal.text : ""}
        openModal={!!this.state.openModal}
        handleCloseModal={this.handleCloseModal}
        battleOfDaySelected={this.state.battleOfDaySelected}
        loading={this.state.modalLoading}
      />
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box width="100%" sx={{ overflow: "scroll" }}>
          <Box sx={webStyle.mainWrapper}>
            {this.state.loading && (
                <Box
                  sx={webStyle.loadingContainer}
                >
                  <CircularProgress style={{color: '#E73700'}} />
                </Box>
              )}
              <Typography style={webStyle.textHeader}>Welcome!</Typography>
              <Typography style={webStyle.textDateHeader}>
                {moment().format("D MMMM, YY")}
              </Typography>
            <Box sx={webStyle.cardContainer}>
              {this.state.headerData.map((data: any) => this.renderCardWorkout(data))}
            </Box>
            <Box sx={webStyle.contentMiddleContainer}>
              {this.renderCalendarWorkout()}
              {this.renderListBattleRoyal()}
            </Box>
          </Box>
          {this.renderModalWorkout()}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  rowCenterMode: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  columnMode: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentMiddleContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row"
  },
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    opacity: 0.5,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: 30,
    paddingTop: 20,
    paddingLeft: 37,
    background: "#FBFAF7"
  },
  textHeader: {
    fontSize: 36, fontWeight: 700, color: "#E73700"
  },
  textDateHeader: {
    fontSize: 16, fontWeight: 700, color: "#747474"
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  cardWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    background: "#313320",
    borderRadius: 19,
    padding: 19,
    margin: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  textDesCard: {
    color: "#C2C2C2",
    fontSize: 11,
    fontWeight: 300,
    marginTop: 52,
  },
  textContentCard: {
    color: "#fff",
    fontSize: 22.5,
    fontWeight: 600,
  },
  cardRoyalContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 32
  },
  image: {
    width: 63,
    height: 50,
    borderRadius: 17,
    marginRight: 11,
    paddingRight: 5,
    paddingLeft: 5,
  },
  textMonth: {
    fontSize: 12.7,
    fontWeight: 400,
    color: "#747474"
  },
  textMonthBlur: {
    fontSize: 12.7,
    fontWeight: 400,
    color: "#ababab"
  },
  battleRoyalEventBox: {
    display: "flex",
    alignItems: "center",
    border: "0.63px solid #E73700",
    background: "#FFF3EF",
    borderRadius: 7,
    paddingLeft: 5,
    width: "90%",
    height: 36.6,
    marginTop: 6.7,
    overflow: 'hidden'
  },
  textEventBox: {
    fontSize: 8,
    fontWeight: 600,
    color: "#313320"
  },
  noEventBox: {
    display: "flex",
    alignItems: "center",
    border: "0.6px solid #EEECE7",
    borderRadius: 7,
    paddingLeft: 6,
    width: "90%",
    height: 24.4,
    marginTop: 6.7,
    color: "#d9d9d9",
  },
  textNoEventBox: {
    fontSize: 8,
    fontweight: 400,
    color: "#747474"
  },
  weekDayRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 51,
    marginTop: 11
  },
  weekDay: {
    display: "flex",
    flex: 1,
    background: "#EEECE7",
    justifyContent: "center",
    alignItems: "center"
  },
  monthDayRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 100.4,
    background: "#fff"
  },
  listBattleRoyalContainer: {
    flex: 1,
    flexDirection: "column",
    background: "#fff",
    padding: 24,
    paddingTop: 16,
    borderRadius: 20,
    marginRight: 22,
    marginLeft: 10,
    marginTop: 25
  },
  textMonthHeader: {
    fontSize: 16,
    fontWeight: 600,
    color: "#313320"
  },
  textDateTimeHeader: {
    fontSize: 16,
    fontWeight: 400,
    color: "#747474"
  },
  cardIconContainer: {
    padding: 6,
    borderRadius: 20,
    background: "#000",
    alignSelf: "start"
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonCardIcon: {
    borderRadius: "42%",
    border: "1px solid #ffffff",
    padding: "8px"
  },
  dayMonthContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-end",
    paddingTop: 10
  },
  dayMonthTextContainer: {
    display: "flex",
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    background: "#fff"
  },
  dayMonthTextTodayContainer: {
    display: "flex",
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    background: "#e73600"
  },
  calendarWorkoutContainer: {
    display: "flex",
    flex: 2.4,
    flexDirection: "column",
    marginLeft: 22,
    marginRight: 10,
    paddingTop: 20
  },
  calendarWorkoutHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  textWeek: {
    fontSize: 14.25,
    fontWeight: 400,
    color: "#313320"
  },
  contentCardBattle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gridGap: 5
  },
  contentCardWapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gridGap: 5
  },
  royalCardDate: {
    fontSize: 14,
    fontWeight: 600,
    color: "#313320"
  },
  royalCardName: {
    fontSize: 16,
    fontWeight: 400,
    color: "#747474"
  },
  textHeaderRoyal: {
    fontSize: 16,
    fontWeight: 600,
    color: "#313320"
  }
};
// Customizable Area End
