// Customizable Area Start
import React, { useState } from "react";
//@ts-ignore
import VideoImageThumbnail from "react-video-thumbnail-image";

interface ThumbProps {
    item: any;
    style: {
        width: number;
        height: number;
    } | any;
}
export default function ThumbVideo({ item, style }: ThumbProps) {
    const videoUrl = item?.attributes?.video_url?.[0]?.url || item?.video_url || "";
    const { width = 100, height = 70 } = style;
    return (
        <div style={{...style, overflow: "hidden", backgroundColor: "rgba(0,0,0,0.1)"}}>
            {videoUrl ? (
                <VideoImageThumbnail
                    videoUrl={videoUrl}
                    width={width}
                    height={height}
                    key={videoUrl}
                />
            ) : undefined}
        </div>
    )
}