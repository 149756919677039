import React, { useState } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CommonModalAddTags from "./CommonModalAddTags";

interface CommonAddTagsProps extends BoxProps {
  label: string;
  listTag?: string[];
  handlePositiveClick: (data: string[]) => void;
  handleDeleteATag: (data: string) => void;
  openModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
}

const CommonAddTags: React.FC<CommonAddTagsProps> = (props) => {
  const { label, listTag, handleDeleteATag, handlePositiveClick, openModal, handleOpenModal, handleCloseModal } = props;
  
  const renderModalAddTag = () => {
    return (
      <CommonModalAddTags
      popupHeading="Add New Focus Tag"
      openModal={openModal}
        handleCloseModal={handleCloseModal}
        handlePositiveClick={handlePositiveClick}
        allTags={listTag}
      />
    );
  };
  const renderTag = (item: any, index: number) => {
    return (
      <Box sx={webStyle.tagContainer} key={index}>
        <Typography style={webStyle.input}>{item}</Typography>
        <Box onClick={() => handleDeleteATag(item)}>
          <Close fontSize="small" />
        </Box>
      </Box>
    );
  };
  return (
    <Box {...props}>
      {renderModalAddTag()}
      <Typography style={webStyle.label}>{label}</Typography>
      <Box onClick={() => {
        if(listTag?.length === 0){
          handleOpenModal()
        }
      }} sx={webStyle.containerInput}>
        <Box sx={webStyle.listTagContainer}>
          {listTag?.map((tag, index) => renderTag(tag, index))}
        </Box>
        <AddIcon style={webStyle.iconAdd} fontSize="small" onClick={handleOpenModal} />
      </Box>
    </Box>
  );
};
const webStyle = {
  tagContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 16,
    paddingRight: 16,
    height: 30,
    alignItems: "center",
    marginLeft: 8,
    borderRadius: 13,
    background: "#fff",
    marginTop: 5
  },
  listTagContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
    fontWeight: 400
  },
  icon: {
    marginLeft: 10,
  },
  iconAdd: {
    marginRight: 20,
    marginTop: 5
  },
  containerModalTag: {
    borderRadius: 17,
    border: "1px solid #C2C2C2",
    background: "#F1F0EE",
    flex: 1,
    height: 63,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  containerInput: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    minHeight: 40,
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
  },
  modalWapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textHeader: {
    fontSize: 16,
    fontWeight: 700,
    color: "#313320",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 630,
    borderRadius: 16,
    background: "#fff",
    overflow: "hidden",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 24,
    paddingRight: 18,
    background: "#EEECE7",
  },
  headerContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 31,
    marginTop: 20,
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 31,
    paddingTop: 15,
    borderTop: "0.5px solid #EEECE7",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 32,
  },
  textAddTag: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
  textTagAvailable: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    alignSelf: "center",
    marginTop: 16,
  },
};

export default CommonAddTags;
