Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";
exports.applicationJsonApiContentType = "application/json";
exports.scheduleListAPIEndPoint = "bx_block_scheduling/schedules";
exports.templateListAPIEndPoint = "bx_block_video_template/video_templates";
exports.tagListAPIEndPoint = "bx_block_videos8/videos/alltag";
exports.getWorkoutAPIEndPoint = "bx_block_video_template/video_templates";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
// Customizable Area End