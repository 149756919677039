import React from "react";
import Box from "@material-ui/core/Box";
import {
    Typography,
    Modal,
} from "@material-ui/core";
import { Link } from "react-router-dom";

interface ModalWorkoutProps {
    message: string;
    handlePositiveButton: () => void;
    handleNegativeButton: () => void;
    handleCloseModal: () => void;
    showDeleteModal: boolean;
    negativeButtonTitle: string;
    showNegativeButton: boolean;
    positiveButtonTitle: string;
    showPositiveButton: boolean;
    destination?: string;
}
const CommonModalDelete: React.FC<ModalWorkoutProps> = (props) => {
    const { handlePositiveButton, handleNegativeButton, handleCloseModal, showDeleteModal, message, negativeButtonTitle, showNegativeButton, positiveButtonTitle, showPositiveButton, destination } = props;

    const renderPositiveButton = () => {
        if (showPositiveButton) {
            if (destination && destination.length !== 0) {
                return (
                    <Link to={destination}>
                        <Box onClick={handlePositiveButton} sx={webStyle.buttonCancelContainer}>
                            <Typography>{positiveButtonTitle}</Typography>
                        </Box>
                    </Link>
                )
            } else {
                return (
                    <Box onClick={handlePositiveButton} sx={webStyle.buttonCancelContainer}>
                        <Typography>{positiveButtonTitle}</Typography>
                    </Box>
                )
            }
        } else {
            return null;
        }
    }

    return (<Modal
        open={showDeleteModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-close-modal"
        aria-describedby="modal-close-modal-description"
    >
        <Box sx={webStyle.modalWapper}>
            <Box display="flex" flexDirection="column" sx={webStyle.modalConfirm} >
                <Typography>{message}</Typography>
                <Box display="flex" flexDirection="row" sx={{ marginTop: 10, justifyContent: 'flex-end' }}>
                    {showNegativeButton && <Box
                        onClick={handleNegativeButton}
                        sx={webStyle.buttonCancelContainer}
                    >
                        <Typography>{negativeButtonTitle}</Typography>
                    </Box>
                    }
                    {renderPositiveButton()}
                </Box>
            </Box>
        </Box>
    </Modal>)
}

const webStyle = {
    modalConfirm: { width: 300, background: "#fff", padding: 20, borderRadius: 10 },

    modalWapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonCancelContainer: {
        color: "#747474",
        fontSize: 16,
        padding: 10,
        flexDirection: 'row',
        paddingLeft: 16,
        paddingRight: 16,
        "&:hover": {
            cursor: "pointer",
        },
    },
};

export default CommonModalDelete;
