import React, { useState, useEffect, useRef, MutableRefObject } from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    useMediaQuery,
    Grid,
    GridSize,
    Paper,
    Hidden
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import BoilerPlate from "./BoilerPlate.web";
import VideoPlayerPreview from "./VideoPlayerPreview";
import { PropsTypes } from "./ModalPreview.web";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ReactPlayer from "react-player";
import Replay from "@material-ui/icons/Replay";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseIcon from '@material-ui/icons/Pause';
import FastForwardRounded from '@material-ui/icons/FastForwardRounded';
import FastRewindRounded from '@material-ui/icons/FastRewindRounded';

const companyLogo = require("./firm_logo.png");

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "9.45px",
            letterSpacing: "-1%",
            textAlign: "center",
            color: "#000000"
        },
        subtitle1: {
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "32px",
            letterSpacing: "-0.5%",
            color: "#747474", 
            
        },
        body1: {
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "9.45px",
            letterSpacing: "-1%",
            color: "#000000"
        },
    },
});
// Customizable Area End

interface SetVideo {
    set1: any;
    set2: any;
}
interface Video {
    type: string; // introduction, demo, warmup, set, coolingdown
    data: any | SetVideo[];
}

// Customizable Area Start
export const ModalBody = (props: PropsTypes) => {
    const {
        tempalteImages,
        isHorizontalLayout,
        introductionVideo,
        warmupVideo,
        coolingVideo,
        zone1,
        zone2,
        zone3,
        laps,
        sets,
        restTime,
        selectedZone } = props

        console.log( warmupVideo, coolingVideo)

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [displayedVideos, setDisplayedVideos] = useState<Video[]>([]);
    const  displayedVideosLength = displayedVideos[currentVideoIndex]?.data?.length
    const playerIntroRef: any = React.useRef(null);
    const playerDemoRef: any = React.useRef(null);
    const playerWarmupRef: any = React.useRef(null);
    const playerCoolingDownRef: any = React.useRef(null);

    const playerStation1Ref: any = React.useRef(null);
    const playerStation2Ref: any = React.useRef(null);
    const playerStation3Ref: any = React.useRef(null);
    const playerStation4Ref: any = React.useRef(null);
    const playerStation5Ref: any = React.useRef(null);
    const playerStation6Ref: any = React.useRef(null);
    const playerStation7Ref: any = React.useRef(null);
    const playerStation8Ref: any = React.useRef(null);
    const playerStation9Ref: any = React.useRef(null);

    const playerStationsRef = [
        playerStation1Ref,
        playerStation2Ref,
        playerStation3Ref,
        playerStation4Ref,
        playerStation5Ref,
        playerStation6Ref,
        playerStation7Ref,
        playerStation8Ref,
        playerStation9Ref,
    ]

    useEffect(() => {
        let data: Video[] = [];
        let setVideos: any[] = [];
        if (introductionVideo?.id) data.push({ data: introductionVideo, type: "introduction" });
        if (warmupVideo?.id) data.push({ data: warmupVideo, type: "warmup" });
        for (let i = 1; i <= 9; i++) {
            if (selectedZone === "zone1") {
                if (zone1[i].set1 || zone1[i].set2) {
                    setVideos.push({ ...zone1[i] });
                }
            }
            if (selectedZone === "zone2") {
                if (zone2[i].set1 || zone2[i].set2) {
                    setVideos.push({ ...zone2[i] });
                }
            }
            if (selectedZone === "zone3") {
                if (zone3[i].set1 || zone3[i].set2) {
                    setVideos.push({ ...zone3[i] });
                }
            }
        }
        if (setVideos?.length) data.push({ data: setVideos, type: "set" });
        if (coolingVideo?.id) data.push({ data: coolingVideo, type: "coolingdown" });
        setDisplayedVideos(data);
    }, []);

    useEffect(() => {
        if(displayedVideos.length > 0) {
            pauseAll();
            setCurrentVideoIndex(0);
            // playVideoAtIndex(0);
        }
    }, [displayedVideos])

    const renderActionButtons = () => {
        return (
            <Box sx={{ ...webStyle.row, marginTop: 20, }}>
                <Box
                    data-testid="play-button"
                    sx={{ ...webStyle.actionButtonContainer, marginRight: 7 }}
                    onClick={handlePlay}>
                    <PlayArrowRoundedIcon style={{ color: 'black' }} />
                </Box>
                <Box
                    data-testid="pause-button"
                    sx={{ ...webStyle.actionButtonContainer, marginRight: 7 }}
                    onClick={handlePause}>
                    <PauseIcon style={{ color: 'black' }} />
                </Box>
                <Box
                    data-testid="rewind-button"
                    sx={{ ...webStyle.actionButtonContainer, marginRight: 7 }}
                    onClick={handleBack}>
                    <FastRewindRounded style={{ color: 'black' }} />
                </Box>
                <Box
                    data-testid="forward-button"
                    sx={{ ...webStyle.actionButtonContainer, marginRight: 7 }}
                    onClick={handleNext}>
                    <FastForwardRounded style={{ color: 'black' }} />
                </Box>
                <Box
                    data-testid="replay-button"
                    sx={{ ...webStyle.actionButtonContainer }}
                    onClick={handleReplay}>
                    <Replay style={{ color: 'black' }} />
                </Box>
            </Box>
        )
    }

    const handlePlay = () => {
        pauseAll();
        const index = currentVideoIndex;
        if (displayedVideos[index]?.type === "introduction") {
            playerIntroRef?.current?.play();
        } else if (displayedVideos[index]?.type === "demo") {
            playerDemoRef?.current?.play();
        } else if (displayedVideos[index]?.type === "warmup") {
            playerWarmupRef?.current?.play();
        } else if (displayedVideos[index]?.type === "coolingdown") {
            playerCoolingDownRef?.current?.play();
        } else if (displayedVideos[index]?.type === "set") {
            playStationVideo();
        }
    }

    const handlePause = () => {
        pauseAll();
    }

    const handleBack = () => {
        pauseAll();
        if (currentVideoIndex > 0) {
            setCurrentVideoIndex(currentVideoIndex - 1);
            playVideoAtIndex(currentVideoIndex - 1);
        }
    }

    const handleNext = () => {
        pauseAll();
        if (currentVideoIndex < displayedVideos.length - 1) {
            setCurrentVideoIndex(currentVideoIndex + 1);
            playVideoAtIndex(currentVideoIndex + 1);
        }
    }

    const handleReplay = () => {
        pauseAll();
        setCurrentVideoIndex(0);
        playVideoAtIndex(0);
    }

    const pauseAll = () => {
        playerIntroRef?.current?.pause();
        playerDemoRef?.current?.pause();
        playerWarmupRef?.current?.pause();
        playerCoolingDownRef?.current?.pause();
        playerStation1Ref?.current?.pause();
        playerStation2Ref?.current?.pause();
        playerStation3Ref?.current?.pause();
        playerStation4Ref?.current?.pause();
        playerStation5Ref?.current?.pause();
        playerStation6Ref?.current?.pause();
        playerStation7Ref?.current?.pause();
        playerStation8Ref?.current?.pause();
        playerStation9Ref?.current?.pause();
    }

    const playStationVideo = () => {
        const timer = setTimeout(() => {
            playerStation1Ref?.current?.play();
            playerStation2Ref?.current?.play();
            playerStation3Ref?.current?.play();
            playerStation4Ref?.current?.play();
            playerStation5Ref?.current?.play();
            playerStation6Ref?.current?.play();
            playerStation7Ref?.current?.play();
            playerStation8Ref?.current?.play();
            playerStation9Ref?.current?.play();
            clearTimeout(timer);
        }, 500)
    }

    const playVideoAtIndex = (index: number) => {
        if(displayedVideos?.length <= 0) return;
        if (displayedVideos[index]?.type === "introduction") {
            playerIntroRef?.current?.play();
        } else if (displayedVideos[index]?.type === "demo") {
            playerDemoRef?.current?.play();
        } else if (displayedVideos[index]?.type === "warmup") {
            playerWarmupRef?.current?.play();
        } else if (displayedVideos[index]?.type === "coolingdown") {
            playerCoolingDownRef?.current?.play();
        } else if (displayedVideos[index]?.type === "set") {
            playStationVideo();
        }
    }

    const handleEndVideo = () => {
        pauseAll();
        if (currentVideoIndex < displayedVideos.length - 1) {
            setCurrentVideoIndex(currentVideoIndex + 1);
            playVideoAtIndex(currentVideoIndex + 1);
        }
    }

    const renderIntroDemoWarmup = () => (
        <Grid container spacing={2} style={{ flexDirection: 'row' }}>
            <Grid item xs={4} md={4}>
                {renderViewoGroupTitle("Introduction")}
                
            </Grid>
            <Grid item xs={4} md={4}>
                {renderViewoGroupTitle("Demo")}
                
            </Grid>
            <Grid item xs={4} md={4}>
                {renderViewoGroupTitle("Warm Up")}
                
            </Grid>
        </Grid>
    )

    const renderZoneVideo = () => (
        <Grid container spacing={2} style={{ flexDirection: 'row' }}>
            <Grid item xs={4} md={4}>
                {renderViewoGroupTitle("Zone 1")}
                <Grid container spacing={2} >
                    {Object.values(zone1).filter(e => e.set1?.id || e.set2?.id).map((item: SetVideo, index: number) => (
                        <Grid key={index} item xs={12} md={12}>
                            <Typography style={{ marginBottom: '10px' }} variant="h6">{`Station ${index + 1}`}</Typography>
                            
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={4} md={4}>
            {renderViewoGroupTitle("Zone 2")}
                <Grid container spacing={2} >
                    {Object.values(zone2).filter(e => e.set1?.id || e.set2?.id).map((item: SetVideo, index: number) => (
                        <Grid key={index} item xs={12} md={12}>
                            <Typography style={{ marginBottom: '10px' }} variant="h6">{`Station ${index + 1}`}</Typography>
                            
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={4} md={4}>
            {renderViewoGroupTitle("Zone 3")}
                <Grid container spacing={2} >
                    {Object.values(zone3).filter(e => e.set1?.id || e.set2?.id).map((item: SetVideo, index: number) => (
                        <Grid key={index} item xs={12} md={12}>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )

    const renderCoolingDown = () => (
        <Grid container spacing={2} style={{ flexDirection: 'row' }}>
            <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
            {renderViewoGroupTitle("Cooling Down")}
            </Grid>
            <Grid item xs={4} md={4} />
        </Grid>
    )

    const renderheader = () => (
        <Box sx={webStyle.topBarStyle}>
            <Box sx={webStyle.topBarBox} flexDirection="column"
                onClick={() => {
                    console.log(zone1)
                    console.log("is array", Array.isArray(zone1));
                    console.log("in 0", zone1[0]);
                }}
            >
                <Typography variant="subtitle1" component="div">
                    <span style={{ color: "#E73700" }}>{sets} </span>Sets
                </Typography>
                <hr style={webStyle.hrStyle} />
                <Typography variant="subtitle1" component="div">
                    <span style={{ color: "#E73700" }}>{laps} </span>Laps
                </Typography>
            </Box>
            <Box >
                <img src={companyLogo} alt="company-logo" />
            </Box>
            <Box sx={webStyle.topBarBox}>
                <BoilerPlate insideCircleText={restTime} outsideCircleText="Rest" style={webStyle.customStyle} />
            </Box>
        </Box>
    )

    const renderViewoGroupTitle = (title: string) => (
        <h1>{title}</h1>
    )

    // Customizable Area End


    return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
        </ThemeProvider>
        // Customizable Area End
    );
}

export default withWidth()(ModalBody)
// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        paddingBottom: "30px",
        background: "#fff",
        paddingTop: "30px",
        width: "100%",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    topBarStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "30px"
    },
    topBarBox: {
        width: "173px",
        height: "65px",
        borderRadius: "8px",
        border: "1px solid #C2C2C2",
        background: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    hrStyle: {
        height: "1px",
        width: "80px",
        border: "none",
        background: "#EEECE7",
        marginBlock: 0
    },
    templatePic: {
        width: "443px",
        height: "740px"
    },
    textOverlay: {
        position: "absolute" as "absolute",
        marginTop: "19px",
        left: "20px",
        width: "40px",
        height: "42px",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "23px",
        letterSpacing: "-0.01em",
    },

    customProgress: {
        color: "#E73700",
        height: "52px !important",
        width: "52px !important",
        transform: "rotate(-0deg) !important",
        "& .MuiCircularProgress-circle": { strokeWidth: "9px" },
        "& .MuiCircularProgress-svg": {
            overflow: "visible",
        },
    },
    customStyle: {
        minWidth: '40px',
        height: '40px',
        display: "flex",
        justifyContent: "spaceBetween",
        paddingInline: "12%"

    },
    hiddenBoilerPlate: {
        flexDirection: "column",
        justifyContent: "center",
    },
    hiddenText: {
        position: "absolute",
        bottom: "12%",
    },
    row: {
        flexDirection: "row",
        display: "flex",
    },

    actionButtonContainer: {
        borderRadius: 17,
        border: '1px solid black',
        alignContent: 'center',
        padding: 10,
        "&:hover": {
            cursor: "pointer",
        },
    },

};
// Customizable Area End
