import React, { useState, useEffect } from 'react';

interface VideoItemProps {
    imageBase64Path: any;
}

export  const ImageComponent = (props: VideoItemProps) => {
    const { imageBase64Path } = props;
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (imageBase64Path instanceof Blob) {
    const url = URL.createObjectURL(imageBase64Path);
    setImageUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };}
  }, [imageBase64Path]);

  return <img style={WebStyle.imageUploadingItem} src={imageUrl ? imageUrl : require("../assets/firm_logo.png")} alt="Preview" />;
};


const WebStyle = {
    imageUploadingItem: {
        width: 59,
        height: 56,
        borderRadius: 17,
        marginLeft:10,
      }
}