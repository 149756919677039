import React, { ChangeEvent, useState } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography, IconButton, Modal, CircularProgress } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import CommonButton from "./CommonButton";
import CommonSelection from "./CommonSelection";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

interface ModalAddTimeSlotProps extends BoxProps {
  handleCloseModal: any;
  openModal: boolean;
  timeSlotSelected: any;
  handleSaveTimeSlot: any;
  errorOutside?: any
  loading?: boolean;
}
const TIME_SLOT_TYPES = [
  { value: "0", label: "Daily" },
  { value: "1", label: "Does not repeat" },
  { value: "2", label: "Weekly on Friday" },
  { value: "3", label: "Every weekday" },
  { value: "4", label: "Weekly on MTWT" },
];

const getListTimeSlots = () => {
  const listTimeSlots = [];
  for (let i = 0; i < 24; i++) {
    const hour = i > 12 ? i - 12 : i;
    const hourDisplay = `${hour.toString().length === 1 ? `0${hour}` : hour}`;
    const format = i < 12 ? "AM" : "PM";
    listTimeSlots.push({
      value: `${hourDisplay}:00,${format}`,
      data: { hour: i, minute: 0, second: 0 },
      label: `${hourDisplay}:00 ${format}`,
    });
    listTimeSlots.push({
      value: `${hourDisplay}:30,${format}`,
      data: { hour: i, minute: 30, second: 0 },
      label: `${hourDisplay}:30 ${format}`,
    });
  }
  return listTimeSlots;
};
const TIME_SLOTS_DAY = getListTimeSlots();
const ModalAddTimeSlot: React.FC<ModalAddTimeSlotProps> = (props) => {
  const {
    handleCloseModal,
    openModal,
    timeSlotSelected,
    handleSaveTimeSlot,
    errorOutside,
    loading
  } = props;
  const [listTimeSlot, setListTimeSlot] = useState<any>([
    {
      id: 1,
      startTime: TIME_SLOTS_DAY[10].value,
      type: TIME_SLOT_TYPES[1],
    },
  ]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onClickSaveContinue = () => {
    let isSame = false
    if(listTimeSlot.length > 1) {
      listTimeSlot.forEach((timeSlotItem: any, index: number) => {
        listTimeSlot.forEach((timeSlotItem2: any, index2: number) => {
          if(timeSlotItem.startTime == timeSlotItem2.startTime && index !== index2){
            isSame = true;
            return
          }
        })
      })
    }
    if(isSame){
      setErrorMessage("We cannot have two time slots with the same start time.")
      return
    }
    handleSaveTimeSlot(timeSlotSelected, listTimeSlot);
    if(errorMessage !== null){
      setErrorMessage(null)
    }
    setListTimeSlot([
      {
        id: 1,
        startTime: TIME_SLOTS_DAY[10].value,
        type: TIME_SLOT_TYPES[1],
      },
    ]);
  };

  const onClickCloseModal = () => {
    if(errorMessage !== null){
      setErrorMessage(null)
    }
    setListTimeSlot([
      {
        id: 1,
        startTime: TIME_SLOTS_DAY[10].value,
        type: TIME_SLOT_TYPES[1],
      },
    ]);
    handleCloseModal();
  };

  const handleAddTimeSlot = () => {
    let newListTimeSlot = [...listTimeSlot];
    newListTimeSlot.push({
      id: newListTimeSlot.length + 1,
      startTime: TIME_SLOTS_DAY[10].value,
      type: TIME_SLOT_TYPES[1],
    });
    setListTimeSlot(newListTimeSlot);
  };

  const renderTimeSlot = (item: any, index: number) => {
    const handleChangTime = (time: any) => {
      let newListTimeSlot = [...listTimeSlot];
      newListTimeSlot = newListTimeSlot.map((itemTime) => {
        if (itemTime.id === item.id) {
          return { ...itemTime, startTime: time.value };
        } else {
          return itemTime;
        }
      });
      setListTimeSlot(newListTimeSlot);
    };
    const handleChangeType = (type: any) => {
      let newListTimeSlot = [...listTimeSlot];
      newListTimeSlot = newListTimeSlot.map((itemTime) => {
        if (itemTime.id === item.id) {
          return { ...itemTime, type: type };
        } else {
          return itemTime;
        }
      });
      setListTimeSlot(newListTimeSlot);
    };
    const handleChangeTimeSlot = (startTime: any) => {
      let newListTimeSlot = [...listTimeSlot];
      newListTimeSlot = newListTimeSlot.map((itemTime) => {
        if (itemTime.id === item.id) {
          return { ...itemTime, startTime: startTime.value };
        } else {
          return itemTime;
        }
      });
      if(errorMessage !== null){
        setErrorMessage(null)
      }
      setListTimeSlot(newListTimeSlot);
    };
    const handleDeleteTimeSlot = () => {
      let newListTimeSlot = [...listTimeSlot];
      newListTimeSlot = newListTimeSlot.filter((itemTime) => {
        return itemTime.id !== item.id
      });
      setListTimeSlot(newListTimeSlot);
    }
    return (
      <Box key={item.id.toString()} sx={webStyle.timeSlotContainer}>
        <CommonSelection
          selectionHeight={183}
          value={item.startTime}
          onChangeItem={handleChangeTimeSlot}
          data={TIME_SLOTS_DAY}
          label="Start Time"
          width="100%"
          renderLeftIcon={
            <img
              width={24}
              height={24}
              style={{ marginRight: -8, marginLeft: 16 }}
              src={require("./clock.png")}
            />
          }
        />
        <CommonSelection
          selectionHeight={183}
          data={TIME_SLOT_TYPES}
          value={item.type.value}
          onChangeItem={handleChangeType}
          label=""
          style={webStyle.selectionType}
          width="100%"
        />
       {listTimeSlot.length > 1 && <Box onClick={handleDeleteTimeSlot} sx={webStyle.buttonDelete}>
          <DeleteOutlineIcon style={{color: '#747474'}}/>
        </Box>}
      </Box>
    );
  };
  return (
    <Modal
      open={openModal}
      onClose={onClickCloseModal}
      data-test-id="modal-add-time-slot"
      aria-labelledby="modal-add-time-slot-title"
      aria-describedby="modal-add-time-slot-description"
    >
      <Box sx={webStyle.modalWrapper}>
        <Box sx={{ ...webStyle.modalContainer, paddingBottom: 12 }}>
          <Box sx={webStyle.modalHeader}>
            <Typography
              style={webStyle.textHeader}
              data-test-id="modal-add-time-slot-title"
            >
              Set Time
            </Typography>
            <IconButton
              data-test-id="modal-button-close"
              onClick={onClickCloseModal}
            >
              <Close fontSize="medium" />
            </IconButton>
          </Box>
          <Box sx={webStyle.contentContainer}>
          {loading && (
                <Box
                  sx={webStyle.loadingContainer}
                >
                  <CircularProgress style={{color: '#E73700'}} />
                </Box>
              )}
            <Box sx={webStyle.inputContainer}>
              <img
                src={require("./image_calendar.png")}
                width={24}
                height={24}
                style={{ marginLeft: 16 }}
              />
              <Typography style={webStyle.textDate}>
                {timeSlotSelected && timeSlotSelected.format("MMMM D, YYYY")}
              </Typography>
            </Box>
            {listTimeSlot.map(renderTimeSlot)}
            <Box onClick={handleAddTimeSlot} sx={webStyle.buttonAddContainer}>
              <AddCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={webStyle.textAdd}>
                Add more time slots
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flex={1} />
          <Box sx={webStyle.buttonContainer}>
            <CommonButton
              width={93}
              height={44}
              label="Cancel"
              onClickButton={onClickCloseModal}
              mode="transparent"
            />
            <CommonButton
              width={165}
              height={44}
              data-test-id="button-save-continue"
              label="Save & Continue"
              mode="simple"
              onClickButton={onClickSaveContinue}
            />
          </Box>
          {(errorMessage || errorOutside)  && (
            <Typography style={webStyle.textError}>{ errorMessage ?? errorOutside}</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
const webStyle = {
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    opacity: 0.5,
  },
  textError: {
    fontSize: 14,
    color: "#E73700",
    paddingLeft: 30,
  },
  timeSlotContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 18,
    position: "relative"
  },
  buttonDelete: {
    alignSelf: "end",
    position: 'absolute',
    right: 0,
    top: 0,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    alignItems: "center",
    height: 49,
    minHeight: 49,
    width: "95%",
    marginBottom: 10,
  },
  selectionType: {
    marginTop: 23,
    marginLeft: 16,
  },
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
  },
  icon: {
    marginLeft: 10,
  },
  buttonAddContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
    "&:hover": {
      cursor: "pointer",
    },
  },
  textAdd: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 9,
    color: "#313320",
  },
  containerModalTag: {
    borderRadius: 17,
    border: "1px solid #C2C2C2",
    background: "#F1F0EE",
    flex: 1,
    height: 63,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  containerInput: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    height: 49,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    marginRight: 10,
  },
  modalWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textHeader: {
    fontSize: 16,
    fontWeight: 700,
    color: "#313320",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 630,
    borderRadius: 16,
    background: "#fff",
    overflow: "hidden",
    maxHeight: 753
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 24,
    paddingRight: 18,
    background: "#EEECE7",
  },
  textDate: {
    fontSize: 18,
    fontWeight: 400,
    marginLeft: 16,
    color: "#313320",
  },
  headerContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 31,
    marginTop: 20,
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 31,
    paddingTop: 15,
    paddingBottom: 20,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 32,
    paddingTop: 52,
    overflow: 'auto',
  },
  textAddTag: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
  textTagAvailable: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    alignSelf: "center",
    marginTop: 16,
  },
};

export default ModalAddTimeSlot;
