import React, { ChangeEvent, useState, useEffect } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import {
  Typography,
  IconButton,
  Modal,
  TextField,
  InputAdornment,
  withStyles,
  Popover,
  CircularProgress,
  Backdrop,
  Fade,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";
import moment from "moment";
import Close from "@material-ui/icons/Close";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { DatePicker } from '@material-ui/pickers';
import CommonSelection from "./CommonSelection";
import CommonButton from "./CommonButton";
import ModalConfirmDelete from "./ModalConfirmDelete";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ThumbVideo from "./ThumbVideo";

interface Zone {
  id: number;
  text: string;
}
export interface Workout {
  id: number;
  name: string;
  imageUrl: string;
}
interface ModalWorkoutProps {
  editMode?: boolean;
  openModal: boolean;
  modalId?: string;
  headerTitle: string;
  handleCloseModal: () => void;
  battleOfDaySelected: any;
  selectedSchedule?:any
  handleDeleteSchedule? : any
  handleUpdateSchedule? : any
  loading?: boolean
  errorOutside?: any
}
const listZone: Zone[] = [
  { id: 0, text: "Zone 1" },
  { id: 1, text: "Zone 2" },
  { id: 2, text: "Zone 3" },
];
const listSetData: Zone[] = [
  { id: 0, text: "Set 1" },
  { id: 1, text: "Set 2" },
];
export const TIME_SLOT_TYPES = [
  { value: "0", label: "Daily" },
  { value: "1", label: "Does not repeat" },
  { value: "2", label: "Weekly on Friday" },
  { value: "3", label: "Every weekday" },
  { value: "4", label: "Weekly on MTWT" },
];
const getListTimeSlots = () => {
  const listTimeSlots = [];
  for (let i = 0; i < 24; i++) {
    const hour = i > 12 ? i - 12 : i;
    const hourDisplay = `${hour.toString().length === 1 ? `0${hour}` : hour}`;
    const format = i < 12 ? "AM" : "PM";
    listTimeSlots.push({
      value: `${hourDisplay}:00,${format}`,
      data: { hour: i, minute: 0, second: 0 },
      label: `${hourDisplay}:00 ${format}`,
    });
    listTimeSlots.push({
      value: `${hourDisplay}:30,${format}`,
      data: { hour: i, minute: 30, second: 0 },
      label: `${hourDisplay}:30 ${format}`,
    });
  }
  return listTimeSlots;
};
const TIME_SLOTS_DAY = getListTimeSlots();
const CustomTextField = withStyles({
  root: {
    '& .MuiInput-underline::before': {
      borderBottom: 'none', // Remove the default underline/border bottom
    },
    '& .MuiInput-underline::after': {
      borderBottom: 'none', // Remove the underline/border bottom on focus
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: 'none', // Remove the underline/border bottom on hover
    },
    '& .MuiInputBase-input': {
      fontSize: '14px', // Customize the font size
      fontWeight: 600, // Customize the font weight
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
})(TextField);
const CommonModalWorkout: React.FC<ModalWorkoutProps> = (props) => {
  const {editMode, errorOutside, openModal, handleCloseModal, battleOfDaySelected, headerTitle, modalId, selectedSchedule, handleDeleteSchedule, handleUpdateSchedule, loading } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [zoneSelected, setZoneSelected] = useState(0);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [setSelected, setSetSelected] = useState(0)
  const [listWorkout, setListWorkout] = useState([])
  const [isShowListWorkout, setIsShowListWorkout] = useState(true)
  const [listTimeSlot, setListTimeSlot] = useState<any>([]);
  const [timeSlotDelete, setTimeSlotDelete] = useState<any>(null)

  useEffect(() => {
    if(setSelected !== 0){
      setSetSelected(0)
    }
    if(zoneSelected !== 0){
      setZoneSelected(0)
    }
    setErrorMessage(null)
    if(!isShowListWorkout){
      setIsShowListWorkout(true)
    }
    if(editMode && selectedSchedule && selectedSchedule.attributes.time_slots.length > 0){
      setIsShowListWorkout(selectedSchedule.attributes.video_template_id !== null)
      let newListTimeSlot = [...selectedSchedule.attributes.time_slots]
      newListTimeSlot = newListTimeSlot.map((timeSlotItem: any) => {
        return {...timeSlotItem, status: TIME_SLOT_TYPES[timeSlotItem.status] }
      })
      setListTimeSlot(newListTimeSlot)
    }
  }, [selectedSchedule])
  
  const onCloseModal = () => {
    if(setSelected !== 0){
      setSetSelected(0)
    }
    setErrorMessage(null)
    setListWorkout([])
    if(!isShowListWorkout){
      setIsShowListWorkout(true)
    }
    handleCloseModal()
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const idFilter = "simple-popover";
 
  const checkOldDate = () => {
    const currentDate = moment();
    const newSelectedDate = moment(selectedDate).format('MM-DD-YYYY')
    const momentSelectedDate = moment(`${newSelectedDate}`, "MM-DD-YYYY")
    if(momentSelectedDate.isSame(currentDate, 'day')){
      return false
    }
    return momentSelectedDate.isBefore(currentDate, 'day')
  }
  const handleChangeZone = (zoneSelected: number) => {
    setZoneSelected(zoneSelected);
  };
  useEffect(() => {
    if(selectedSchedule){
      handleDateChange(new Date(selectedSchedule.attributes.schedule_date));
    }
  }, [selectedSchedule])

  const onCloseModalConfirmDelete = () => {
    setTimeSlotDelete(null)
    
  }
  const onUpdateSchedule = () => {
    let haveError = false
    listTimeSlot.forEach((timeSlot: any, index: number) => {
      listTimeSlot.forEach((timeSlot1: any, index1: number) => {
        if(timeSlot.start_time && timeSlot1.start_time && timeSlot.start_time === timeSlot1.start_time && index !== index1){
          setErrorMessage("We can't create or edit a time slot with same start time")
          haveError = true
          return
        }
      });
    });
    if(haveError){
      return
    }
    
    const newListTimeSlot = listTimeSlot.map((timeSlot: any) => {
      if(timeSlot.isCreateNew){
        return {
          start_time: timeSlot.start_time,
          status: parseInt(timeSlot.status.value),
          schedule_id: parseInt(selectedSchedule.id)
        }
      }
      if(timeSlot._destroy){
        return {_destroy: timeSlot._destroy, delete_statue: timeSlot.delete_status}
      }
      return {
        id: timeSlot.id,
        start_time: timeSlot.start_time,
        status: parseInt(timeSlot?.status?.value),
        schedule_id: parseInt(selectedSchedule.id)
      }
    })
    handleUpdateSchedule(selectedSchedule, selectedDate, newListTimeSlot)
  }
  const onSwitchOnOffListWorkout = () => {
    setIsShowListWorkout(!isShowListWorkout)
  }

  const modalHeight = editMode ? 753 : 631
  const renderCalendar = () => {
    if(checkOldDate()){
      return <Typography style={webStyle.textCalenderButton}>{moment(selectedDate).format('D MMMM, YYYY')}</Typography>
    }
    const TextFieldComponent = (props: any) => (
      <CustomTextField
        {...props}
        style={webStyle.button}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ExpandMoreIcon />
            </InputAdornment>
          ),
        }}
      />
    );
    
    const isDateSelected = (date: Date | null) => {
      return selectedDate !== null && date !== null && moment(selectedDate).isSame(date, "day");
    };
    return (<DatePicker
        value={selectedDate}
        onChange={handleDateChange}
        animateYearScrolling
        format="D MMMM, YYYY"
        disableToolbar
        variant="inline"
        minDate={new Date()}
        autoOk
        TextFieldComponent={TextFieldComponent}
        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
          const isSelected = isDateSelected(day);
  
          return React.cloneElement(dayComponent, {
            style: {
              borderRadius: '50%',
              backgroundColor: isSelected ? '#E73700' : undefined, // Set orange color for selected date
              color: isSelected ? '#fff' : undefined, // Set white color for text of selected date
            },
          });
        }}
      />)
  }
 const renderModalConfirmDelete = () => {
  return <ModalConfirmDelete 
  open={!!timeSlotDelete} 
  onClose={onCloseModalConfirmDelete} 
  handleClickDeleteButton={(value: string) => {
    let newListTimeSlot = [...listTimeSlot];
    newListTimeSlot = newListTimeSlot.map((itemTime) => {
   if(itemTime.id === timeSlotDelete?.id){

     return {
         id: itemTime.id,
       _destroy: itemTime.id,
       delete_status: parseInt(value)
     }
   }
     return itemTime
   });
   onCloseModalConfirmDelete()
   setListTimeSlot(newListTimeSlot);
  }}/>
 }
  const renderTimeSlot = (item: any, index: number) => {
    if( item._destroy){
      return <></>
    }
    const handleChangTime = (time: any) => {
      let newListTimeSlot = [...listTimeSlot];
      newListTimeSlot = newListTimeSlot.map((itemTime) => {
        if (itemTime.id === item.id) {
          return { ...itemTime, start_time: time?.value };
        } else {
          return itemTime;
        }
      });
      setListTimeSlot(newListTimeSlot);
    };
    const handleChangeType = (type: any) => {
      let newListTimeSlot = [...listTimeSlot];
      newListTimeSlot = newListTimeSlot.map((itemTime) => {
        if (itemTime.id === item.id) {
          return { ...itemTime, status: type };
        } else {
          return itemTime;
        }
      });
      setListTimeSlot(newListTimeSlot);
    };
    const handleChangeTimeSlot = (startTime: any) => {
      let newListTimeSlot = [...listTimeSlot];
      newListTimeSlot = newListTimeSlot.map((itemTime) => {
        if (itemTime.id === item.id) {
          return { ...itemTime, start_time: startTime?.value };
        } else {
          return itemTime;
        }
      });
      if(errorMessage !== null){
        setErrorMessage(null)
      }
      setListTimeSlot(newListTimeSlot);
    };
    const handleDeleteTimeSlot = () => {
      let newListTimeSlot = [...listTimeSlot];
      if(item.isCreateNew){
        newListTimeSlot = newListTimeSlot.filter((itemTime) => {
          return itemTime.id !== item.id
        });
        setListTimeSlot(newListTimeSlot);
      }else{
        if(item.status.value === "1"){
            newListTimeSlot = newListTimeSlot.map((itemTime) => {
           if(itemTime.id === item?.id){
        
             return {
                 id: itemTime.id,
               _destroy: itemTime.id,
               delete_status: "1"
             }
           }
             return itemTime
           });
          setListTimeSlot(newListTimeSlot);
        }else{
          setTimeSlotDelete(item)
        }
      
      }
      
    }
    return (
      item?.start_time && <Box key={item.id.toString()} sx={webStyle.timeSlotContainer}>
        <CommonSelection
          selectionHeight={183}
          value={item.start_time}
          onChangeItem={handleChangeTimeSlot}
          data={TIME_SLOTS_DAY}
          label="Start Time"
          width="100%"
          renderLeftIcon={
            <img
              width={24}
              height={24}
              style={{ marginRight: -8, marginLeft: 16 }}
              src={require("./clock.png")}
            />
          }
        />
        <CommonSelection
          selectionHeight={183}
          data={TIME_SLOT_TYPES}
          value={item?.status?.value}
          onChangeItem={handleChangeType}
          label=""
          style={webStyle.selectionType}
          width="100%"
        />
       {listTimeSlot.filter((timeSlotItem: any) => timeSlotItem._destroy === undefined).length !== 1 && <Box onClick={handleDeleteTimeSlot} sx={webStyle.buttonDelete}>
          <DeleteOutlineIcon style={{color: '#747474'}}/>
        </Box>}
      </Box>
    );
  };
  const handleSaveTimeSlot = (timeSlotSelected: any, listTimeSlot: any[]) => {
    const lisAddTimeSlot = listTimeSlot?.map((timeSlot: any, index: any) => {
      return {
        id: index.toString(),
        start_time: timeSlot.startTime,
        status: timeSlot.type,
      };
    })
    let newListTimeSlot = [...listTimeSlot, ...lisAddTimeSlot];
    setListTimeSlot(newListTimeSlot);
  }
  const handleAddTimeSlot = () => {
    let newListTimeSlot = [...listTimeSlot];
    newListTimeSlot.push({
      id: `newTimeSlot${newListTimeSlot.length + 1}`,
      start_time: TIME_SLOTS_DAY[0]?.value,
      status: TIME_SLOT_TYPES[1],
      isCreateNew: true,
    });
    setListTimeSlot(newListTimeSlot);
  };
  const renderTimeSlotList = () => {
    return <Box display="flex" flexDirection="column" width="92%">
       {listTimeSlot?.length > 0 && listTimeSlot.map(renderTimeSlot)}
            <Box onClick={handleAddTimeSlot} sx={webStyle.buttonAddContainer}>
              <AddCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={webStyle.textAdd}>
                Add more time slots
              </Typography>
            </Box>
    </Box>
  }

  const renderEditTimeSlot = () => {
    if(!editMode){
      return <></>
    }
    if(checkOldDate()){
      return <></>
    }
    if(battleOfDaySelected.length === 0){
      return <></>
    }
    return (<Box onClick={onSwitchOnOffListWorkout} sx={webStyle.buttonCancelContainer}>
    <Typography>{isShowListWorkout ?  'Edit Time Slots' : "Show Workout"}</Typography>
    </Box>)
  }
  const renderZoneColumn = (zoneItem: any, index: number) => {
    if (zoneItem && zoneItem.length > 0){
      return <Box flex sx={{flexDirection: 'column',marginRight: 5, width:'30%'}}>
      <Typography style={webStyle.textZone}>Zone {index + 1}</Typography>
      {zoneItem.map((setItem: any, setIndex: number) => {
        return  <Box sx={{marginTop: 16}}>
          <Typography>Set {setIndex + 1}</Typography>
          {setItem.map(renderItemWorkout)}
          </Box>
      })}
    </Box>
    } 
    return <></>
  }
  const renderItemWorkout = (workout: any) => {
    return <Box
    display="flex"
    flexDirection="row"
    key={workout.id.toString()}
    sx={webStyle.modalWorkoutItem}
  >
    <ThumbVideo 
      style={webStyle.image}
      item={workout}
    />
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Typography style={webStyle.textName}>
        {workout?.exercise_name ?? "Deleted data"}
      </Typography>
      <Typography style={webStyle.textDes}>
        {workout?.title}
      </Typography>
    </Box>
  </Box>
  }
  return (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      data-testid={modalId ? modalId : "modal-workout"}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={webStyle.modalWapper}>
        {/* {renderListSetPopover()} */}
        {renderModalConfirmDelete()}
        <Box
          height={modalHeight}
          sx={{
            ...webStyle.modalContainer,
            paddingBottom: editMode ? 29 : 37,
          }}
        >
          <Box sx={webStyle.modalHeader}>
            <Typography
              style={webStyle.textHeader}
              data-testid={modalId ? `${modalId}-title` : "modal-modal-title"}
            >
              {headerTitle}
            </Typography>
            <IconButton
              data-testid={
                modalId ? `${modalId}-button-close` : "modal-button-close"
              }
              onClick={onCloseModal}
            >
              <Close fontSize="medium" />
            </IconButton>
          </Box>
          {editMode && (
            <Box sx={webStyle.headerContentContainer}>
              <Box>
                {renderCalendar()}
                <Typography
                  style={{ fontSize: 16, fontWeight: 400, color: "#747474" }}
                >
                  {editMode ? selectedSchedule?.template?.attributes?.title : 'Workout Name | BR3'}
                </Typography>
              </Box>
             <Box/>
              {renderEditTimeSlot()}
            </Box>
          )}
          {/* <Box sx={webStyle.modalZoneContainerAll}>
            <Box sx={webStyle.modalZoneContainer}>
              {listZone.map((item: Zone) => (
                <Box
                  onClick={() => handleChangeZone(item.id)}
                  key={item.id}
                  sx={{
                    ...webStyle.zoneButtonItem,
                    border:
                      item.id === zoneSelected
                        ? "1px solid #313320"
                        : "1px solid transparent",
                  }}
                >
                  <Typography style={webStyle.textZone}>
                    {item.text}
                  </Typography>
                </Box>
              ))}
            </Box>
           
          </Box> */}
         
          <Box sx={webStyle.modalWorkoutList}>
          {loading && (
                <Box
                  sx={webStyle.loadingContainer}
                >
                  <CircularProgress style={{color: '#E73700', zIndex: 1000}} />
                </Box>
              )}
            {isShowListWorkout ? battleOfDaySelected.length > 0&& battleOfDaySelected.map(renderZoneColumn) : renderTimeSlotList()}
          </Box>
          {editMode && !checkOldDate() && (
            <Box sx={webStyle.buttonContainer}>
              <Box
                onClick={onCloseModal}
                sx={webStyle.buttonCancelContainer}
              >
                <Typography>Cancel</Typography>
              </Box>
              <Box data-test-id="modal-update-button" onClick={onUpdateSchedule} sx={webStyle.buttonCreateContainer}>
                <Typography>Update</Typography>
              </Box>
              {/* <Box onClick={() => handleDeleteSchedule()} sx={webStyle.buttonCreateContainer}>
                <Typography>Delete</Typography>
              </Box> */}
            </Box>
          )}
           {(errorMessage || errorOutside)  && (
            <Typography style={webStyle.textError}>{ errorMessage ?? errorOutside}</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
const webStyle = {
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    opacity: 0.5,
    zIndex: 10,
  },
  textError: {
    fontSize: 14,
    color: "#E73700",
    paddingLeft: 30,
  },
  textName: {
    fontSize: 14,
    fontWeight: 600,
    color: "#313320"
  },
  textZone: {
    fontSize: 16,
    fontWeight: 600,
    color: "#313320"
  },

  modalConfirm: {width: 300, background: "#fff", padding: 20, borderRadius: 10},
  textDes: {
    fontSize: 16,
    fontWeight: 400,
    color: "#747474"
  },
  image: {
    width: 73,
    height: 50,
    borderRadius: 17,
    marginRight: 12
  },
  modalWapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  timeSlotContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 18,
    position: "relative"
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 820,
    borderRadius: 16,
    background: "#fff",
    overflow: "hidden",
  },
  buttonDelete: {
    alignSelf: "end",
    position: 'absolute',
    right: 0,
    top: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    heighht: 61,
    paddingLeft: 24,
    paddingRight: 18,
    background: "#EEECE7",
  },
  headerContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 31,
    marginTop: 20,
  },
  selectionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
    },
  },
  textCalenderButton: {
    fontSize: 14, fontWeight: 600,
  },
  button: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  textHeader: {
    fontSize: 16,
    fontWeight: 700
  },
  modalZoneContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  modalZoneContainerAll: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 24,
  },
  zoneButtonItem: {
    padding: 10,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 17,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectionType: {
    marginTop: 23,
    marginLeft: 16,
  },
  modalWorkoutList: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 24,
    paddingRight: 24,
    overflowY: 'scroll',
    width: '100%',
    flex: 1,
    marginTop: 32
  },
  modalWorkoutItem: {
    padding: 16,
    paddingLeft: 13,
    border: "0.7px solid #C2C2C2",
    borderRadius: 17,
    marginTop: 8,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "flex-end",
    gridColumnGap: 15,
    marginTop: 23,
    paddingRight: 31,
  },
  buttonCreateContainer: {
    display: 'flex',
    padding: 10,
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
    background: "#E73700",
    borderRadius: 17,
    color: "#fff",
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonCancelContainer: {
    color: "#747474",
    fontSize: 16,
    padding: 10,
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonPreviewContainer: {
    color: "#747474",
    fontSize: 16,
    padding: 10,
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
    border: "1px solid #747474",
    borderRadius: 17,
    "&:hover": {
      cursor: "pointer",
    },
  },
  setPopoverContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    background: "#FBFAF7",
    fontSize: 16,
    borderRadius: 16,
    border: "1px solid rgba(49, 51, 32, 0.30)"
  },
  setPopoverItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 16,
    height: 41,
    paddingLeft: 16,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
    }
  },
  setSelectedItem: {
    background: "#EEECE7",
    color: "#313320"
  },
  setDeselectedItem: {
    background: "#FBFAF7",
    color: "#747474"
  },
  textAdd: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 9,
    color: "#313320",
  },
  buttonAddContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
    "&:hover": {
      cursor: "pointer",
    },
  },
};

export default CommonModalWorkout;
