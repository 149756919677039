import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, IconButton } from '@material-ui/core';
import ModalPreviewBodyWeb from './ModalPreviewBody.web';
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";

interface Ihbh {
    set1: any;
    set2: any;
}

export interface Pij {
    [key: number]: Ihbh
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
            height: "100%",
            width: "100%",
        },
    }),
);

export interface PropsTypes {
    open: boolean;
    close: (x: boolean) => any;
    tempalteImages: any;
    isHorizontalLayout:boolean;
    introductionVideo: any;
    warmupVideo: any;
    coolingVideo: any;
    zone1: Pij;
    zone2: Pij;
    zone3: Pij;
    laps: any;
    sets: any;
    restTime: any;
    selectedZone: string;
}

export default function TransitionsModal(props: PropsTypes) {
    const classes = useStyles();
    const { open, close, tempalteImages ,isHorizontalLayout} = props
    // const handleClose=()=>{
    //     close(false)
    // }

    return (
        <div>
            <Modal
                className={classes.modal}
                open={open}
                onClose={()=>close(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                    <IconButton
                        onClick={() => close(false)}
                        style={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon fontSize="large" />
                    </IconButton>
                        <ModalPreviewBodyWeb {...props}/>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
