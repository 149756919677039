Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "videolibrary2";
exports.labelBodyText = "videolibrary2 Body";
exports.getAllVideos = "bx_block_videos8/videos";
exports.getAllTags = "/bx_block_videos8/videos/alltag";
exports.addNewTags = "/bx_block_videos8/videos/newtag"
exports.videosAPIEndPoint = "bx_block_videos8/videos";
exports.allTagsAPIEndPoint = "alltag"
exports.allCategoryAPIEndPoint = "bx_block_videos8/categories"
exports.addTagAPIEndPoint = "newtag"
exports.addCategoryAPIEndPoint = "bx_block_videos8/categories"
exports.deleteTagAPIEndPoint = "delete_tag"
exports.deleteCategoryAPIEndPoint = "bx_block_videos8/categories"
exports.deleteVideoAPIEndPoint = "/bx_block_videos8/videos"

exports.createContentType = "multipart/form-data"
exports.btnExampleTitle = "CLICK ME";
exports.postAPiMethod = 'POST';
exports.putAPiMethod = 'PUT';
exports.getApiMethod = 'GET';
exports.patchApiMethod = 'PATCH';
exports.deleteApiMethod = 'DELETE';
// Customizable Area End