import React from "react";
import { Workout } from "../../../components/src/CommonModalWorkout";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { exerciseData } from "../../dashboard/src/assets";
import { templatePic } from "./assets"
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
const _ = require('lodash');

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    video?: {}
}

interface Ihbh {
    set1: any;
    set2: any;
    set3: any;
}

export interface Pij {
    [key: number]: Ihbh
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    workoutName: string;
    dashboardData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    classTypeAnchorEl: HTMLButtonElement | null;
    focusAnchorEl: HTMLButtonElement | null;
    sortAnchorEl: HTMLButtonElement | null;
    filterAnchorEl: HTMLButtonElement | null;
    selectedClassType: any;
    selectedFocus: any;
    focusList: any,
    selectedSort: string;
    selectedFilter: string;
    isHorizontalLayout: boolean;
    selectedZone: string;
    templateOverviewList: any[];
    videoLibraryList: any[];
    videoLibraryListFilter: any[];
    listTagOfVideo: any[];
    listCategoryOfVideo: any[];
    introductionVideo: any;
    warmupVideo: any;
    workoutTime: any;
    openModal: boolean;
    tempalteImages: any;
    zoneVideo: { id: Object, data: Object, zone: string };
    zone1: Pij;
    zone2: Pij;
    zone3: Pij;
    laps: any;
    stations: any;
    sets: any;
    restTime: any;
    zoneChange: any;
    searchStr: string
    restTimeSet1: any;
    restTimeSet2: any;
    restTimeSet3: any;
    currentTemplate: any[],
    coolingVideo: any,
    set1: any,
    set2: any,
    set3: any,
    saveZoneData: any,
    showDeleteModal: boolean,
    workout_id: string,
    popupMessage: string,
    positiveButtonText: string,
    videoDisplayLimit: number;
}

interface SS {
    id: any;
}

export default class DragDropInterfaceController extends BlockComponent<Props, S, SS> {
    defaultDataSet= { 1: { set1: "", set2: "", set3: "" }, 2: { set1: "", set2: "", set3: "" }, 3: { set1: "", set2: "", set3: "" }, 4: { set1: "", set2: "", set3: "" }, 5: { set1: "", set2: "", set3: "" }, 6: { set1: "", set2: "", set3: "" }, 7: { set1: "", set2: "", set3: "" }, 8: { set1: "", set2: "", set3: "" }, 9: { set1: "", set2: "", set3: "" } }
    idFilter = "simple-popover";
    apiDragDropInterfaceVideoLibraryId: string = "";
    apiDragDropInterfaceTemplateOverviewId: string = "";
    dashboardApiCallId: string = "";
    apiGetQueryStrinurl: string = "";
    apiDragDropInterfacePostRequestId: string = ""
    saveWorkoutApiCallId: any;
    imageRef = React.createRef();
    getWorkoutDataAPICallId: any;
    
    tag = this.props.navigation.getParam("tag")
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            dashboardData: [],
            errorMsg: "",
            token: "",
            workoutName: "",
            loading: false,
            classTypeAnchorEl: null,
            focusAnchorEl: null,
            sortAnchorEl: null,
            filterAnchorEl: null,
            selectedClassType: {},
            selectedFocus: "",
            focusList: "",
            selectedSort: '',
            selectedFilter: '',
            isHorizontalLayout: true,
            selectedZone: 'zone1',
            templateOverviewList: [],
            listTagOfVideo: [],
            listCategoryOfVideo: [],
            videoLibraryList: [],
            videoLibraryListFilter: [],
            introductionVideo: {},
            zoneVideo: { id: "", data: "", zone: "" },
            warmupVideo: {},
            openModal: false,
            tempalteImages: [templatePic, templatePic, templatePic],
            zone1: this.defaultDataSet,
            zone2: this.defaultDataSet,
            zone3: this.defaultDataSet,
            laps: 0,
            stations: 0,
            sets: 0,
            restTime: 0,
            zoneChange: 0,
            searchStr: "",
            restTimeSet1: "00:00",
            restTimeSet2: "00:00",
            restTimeSet3: "00:00",
            workoutTime: "00:00",
            currentTemplate: [],
            coolingVideo: {},
            set1: {},
            set2: {},
            set3: {},
            saveZoneData: {},
            showDeleteModal: false,
            workout_id: "",
            popupMessage: "",
            positiveButtonText: "",
            videoDisplayLimit: 15,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
                this.getToken();
            });
        }
        // Customizable Area Start
        this.getDragDropInterfaceTemplateOverViewData()
        this.getDragDropInterfaceVideoLibraryData('')
        // Customizable Area End
    }

    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    getDragDropInterfaceTemplateOverViewData(): any {
        // Customizable Area Start
        this.setState({
            loading: true,
        })
        const header = {
            "Content-Type": configJSON.dragDropInterfaceApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiDragDropInterfaceTemplateOverviewId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dragDropInterfaceGetTemplateOverviewUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dragDropInterfaceApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End

    }
    onChangeWorkoutName(text: string){
        this.setState({
            workoutName: text
        })
    }
    getDragDropInterfaceVideoLibraryData(sort: any): any {
        // Customizable Area Start
        this.setState({
            loading: true,
        })
        const focusList = this.state.focusList ? this.state.focusList[0] : ""
        const tag = this.state.selectedFocus === "" ? focusList : this.state.selectedFocus
        console.log('sort', sort)
        let newUrl = configJSON.dragDropInterfaceGetVideoLibraryUrl
        if (sort !== "") {
            newUrl = `${configJSON.dragDropInterfaceGetVideoLibraryUrl}?category=${sort}&search=${this.state.searchStr}&tag=${tag}`
        } else {
            newUrl = `${configJSON.dragDropInterfaceGetVideoLibraryUrl}?search=${this.state.searchStr}&tag=${tag}`
        }

        const header = {
            "Content-Type": configJSON.dragDropInterfaceApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiDragDropInterfaceVideoLibraryId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            newUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dragDropInterfaceApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);

        const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (response?.data) {
            if (apiRequestId === this.apiDragDropInterfaceVideoLibraryId) {
                this.handleVideoLibraryResponse(response.data);
            } else if (apiRequestId === this.apiDragDropInterfaceTemplateOverviewId) {
                this.handleTemplateOverviewResponse(response.data);
            } else if (apiRequestId === this.saveWorkoutApiCallId) {
                this.setState({
                    loading: false,
                    showDeleteModal: true,
                    popupMessage: "Workout has been saved successfully!",
                    positiveButtonText: "OK",
                }, () => {
                    this.getWorkoutDataAPICall()
                });
            }
        } else if (response && this.getWorkoutDataAPICallId === apiRequestId) {
            this.handleShowWorkoutResponse(response);
        } else {
            const errorMessage = response.error;
            this.setState({
                loading: false,
                popupMessage: errorMessage,
                showDeleteModal: true,
                positiveButtonText: "OK",
            })
            this.parseApiErrorResponse(response);
            this.parseApiCatchErrorResponse(errorResponse);
        }
    }
    handleGetListTagFromListVideo(data: any){
        let listTag: any[] = []
        data.forEach((videoItem: any) => {
            listTag = listTag.concat(videoItem.attributes.add_tags.map((tagItem: any) => tagItem.name))
        })
        const newListTag = _.uniq(listTag)
        return newListTag
    }
    handleGetListCategoryFromListVideo(data: any){
        let listCategory: any[] = []
        data.forEach((videoItem: any) => {
            listCategory.push(videoItem.attributes.category)
        })
        const newListCategory = _.uniq(listCategory)
        return newListCategory
    }
    handleVideoLibraryResponse(data: any) {
        const listTag = this.handleGetListTagFromListVideo(data)
        const listCategory = this.handleGetListCategoryFromListVideo(data)
        this.setState({
            videoLibraryListFilter: [],
            listTagOfVideo: listTag,
            listCategoryOfVideo: listCategory,
            videoLibraryList: data,
            errorMsg: "",
            loading: false,
        });
    }
    findShowedTemplate = (data: any, templateId: any) => {
      
      if(templateId){
        const showedData = data.find((template: any) => {
            return template.id === templateId
        })
        return showedData
      }else{
        return data[0]
      }
    }
    findSelectedTag = (focusTagList: any[], tagSelected: string) => {
        if(tagSelected){
            return tagSelected
          }else{
            return focusTagList[0]
          }
    }
    handleTemplateOverviewResponse(data: any) {
        if (data.length === 0) {
            this.setState({
                templateOverviewList: [],
                showDeleteModal: true,
                popupMessage: "No Template available. Please create one.",
                positiveButtonText: "Create",
                loading: false,
            })
        } else {
            const templateId = this.props.navigation.getParam("templateId")
            const tagSelected = this.props.navigation.getParam("tag")
            const showedTemplate = this.findShowedTemplate(data, templateId)
            console.log('showedTemplate',showedTemplate)
            const focusList = showedTemplate?.attributes.focus.map((item: any) => item.name);
            this.setState((prev) => ({
                ...prev,
                templateOverviewList: data,
                selectedClassType: showedTemplate,
                selectedFocus: this.findSelectedTag(focusList, tagSelected),
                focusList,
                laps: showedTemplate.attributes.number_of_loops,
                stations: showedTemplate.attributes.station,
                sets: showedTemplate.attributes.exercise_sets.length,
                restTime: showedTemplate.attributes.exercise_sets[0].rest_time,
                zoneChange: showedTemplate.attributes.change_zone,
                restTimeSet1: showedTemplate.attributes.exercise_sets[0].rest_time,
                restTimeSet2: showedTemplate.attributes.exercise_sets[1]?.rest_time,
                restTimeSet3: showedTemplate.attributes.exercise_sets[2]?.rest_time,
                workoutTime: showedTemplate.attributes.workout_time,
                errorMsg: "",
                currentTemplate: [showedTemplate],
                loading: false,
                workout_id: "",
                popupMessage: "",
                positiveButtonText: "",
            }), () => {
                this.getDragDropInterfaceVideoLibraryData('');
                this.getWorkoutDataAPICall();
            });
        }
    }

    handleLoadmoreVideo = () => {
        this.setState({
            videoDisplayLimit: this.state.videoDisplayLimit + 10
        })
    }

    isShowLoadMoreBtn = () => {
        return this.state.videoDisplayLimit < this.state.videoLibraryList.length;
    }

    handleShowWorkoutResponse = (response: any) => {
        this.setState({
            loading: false,
            selectedSort: '',
            selectedFilter: '',
            isHorizontalLayout: true,
            selectedZone: 'zone1',
            introductionVideo: {},
            zoneVideo: { id: "", data: "", zone: "" },
            zone1: this.defaultDataSet,
            zone2: this.defaultDataSet,
            zone3: this.defaultDataSet, 
            warmupVideo: {},
            saveZoneData: {},
            coolingVideo: {},
            searchStr: "",
            sortAnchorEl: null,
            filterAnchorEl: null,
        })
        if (response.length !== 0) {
            const videoTypes = [ "introduction", "cooling_down", "warmup"];
            const videoData: { [key: string]: any } = {};

            const formattedData = response[0]?.data.attributes.formatted_station_data
            formattedData.map((item: any) => {
                (item.sets).map((set_item: any) => {
                    this.handleZoneUpdate(item.zone_id, `${item.station_number}.${set_item.set_number}`, set_item)
                })
            })

            if (response[0]?.video_info) {
                videoTypes.forEach((type) => {
                    videoData[type] = response[0]?.video_info[type];
                });
            }
            this.setState({
                workout_id: response[0]?.data.id,
                introductionVideo: videoData["introduction"],
                warmupVideo: videoData["warmup"],
                coolingVideo: videoData["cooling_down"],
                workoutName: response[0]?.data.attributes.workout_name
            });
        } else {
            this.handleClickClear()
        }

    }

    getUrlData = async (url: any) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const contentType = response.headers.get('content-type');
            const filename = url.substring(url.lastIndexOf('/') + 1);
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    resolve({
                        base64: reader.result,
                        contentType: contentType,
                        filename: filename,
                    });
                };
                reader.onerror = () => {
                    reject(new Error('Failed to read the image.'));
                };
            });
        } catch (error: any) {
            throw new Error('Failed to fetch the image: ' + error.message);
        }
    }

    handleClosePopover = () => {
        this.setState({ classTypeAnchorEl: null, focusAnchorEl: null, sortAnchorEl: null, filterAnchorEl: null, });
    };

    handleChangeSelectedClassType = (classType: any) => {
        const focusList2 = (classType.attributes?.focus || []).map((item: any) => {
            return item.name
        })

        this.setState({
            selectedClassType: classType,
            selectedFocus: classType.attributes?.focus[0]?.name ?? "",
            focusList: focusList2,
            laps: classType.attributes?.number_of_loops,
            stations: classType.attributes?.station,
            sets: classType.attributes?.exercise_sets.length,
            restTime: classType.attributes?.exercise_sets[0].rest_time,
            zoneChange: classType.attributes?.change_zone,
            restTimeSet1: classType.attributes?.exercise_sets[0]?.rest_time,
            restTimeSet2: classType.attributes?.exercise_sets[1]?.rest_time,
            restTimeSet3: classType.attributes?.exercise_sets[2]?.rest_time,
            currentTemplate: [classType],
        }, () => {
            this.getDragDropInterfaceVideoLibraryData('')
            this.getWorkoutDataAPICall()
        });
        this.handleClosePopover()
    };

    handleChangeSelectedFocus = (focus: any) => {
        this.setState({ selectedFocus: focus }, () => {
            this.getDragDropInterfaceVideoLibraryData('');
            this.handleClickClear()
            this.getWorkoutDataAPICall()
        });
        this.handleClosePopover()
    };

    handleClickClassTypePopover = (
        event: any
    ) => {
        this.setState({ classTypeAnchorEl: event.currentTarget });
    };

    handleClickFocusPopover = (
        event: any
    ) => {
        this.setState({ focusAnchorEl: event.currentTarget });
    };

    handleClickLayoutPreview = () => {
        this.setState({ isHorizontalLayout: !this.state.isHorizontalLayout });
    };

    handleClickClear = (
    ) => {
        this.setState({
            selectedSort: '',
            selectedFilter: '',
            isHorizontalLayout: true,
            selectedZone: 'zone1',
            introductionVideo: {},
            zoneVideo: { id: "", data: "", zone: "" },
            zone1: this.defaultDataSet,
            zone2: this.defaultDataSet,
            zone3: this.defaultDataSet,
            warmupVideo: {},
            saveZoneData: {},
            coolingVideo: {},
            searchStr: "",
            sortAnchorEl: null,
            filterAnchorEl: null,
            // workout_id: "",
            popupMessage: "",
            positiveButtonText: "",
            loading: false,
        }, () => {
            this.getDragDropInterfaceVideoLibraryData("")
        })
    };

    handleClickPreview = async (isOpen: boolean) => {
        console.log(this.state);
        localStorage.setItem("PreviewData", JSON.stringify({
            introductionVideo: this.state.introductionVideo,
            warmupVideo: this.state.warmupVideo,
            coolingVideo: this.state.coolingVideo,
            zone1: this.state.zone1,
            zone2: this.state.zone2,
            zone3: this.state.zone3,
            selectedClassType: this.state.selectedClassType,
        }));

        const newTab = window.open(`/Preview/${this.state.selectedZone}`, '_blank');
        newTab?.focus();
    };

    handlePositiveButton = () => {
        this.setState({ showDeleteModal: false, loading: false })
    }
    isDraft = () => {
        const {sets, stations} = this.state;
        if (Object.keys(this.state.introductionVideo || {}).length === 0
        || Object.keys(this.state.warmupVideo || {}).length === 0
        || Object.keys(this.state.coolingVideo || {}).length === 0
        || (this.state.saveZoneData["1"] || []).length < sets*stations
        || (this.state.saveZoneData["2"] || []).length < sets*stations
        || (this.state.saveZoneData["3"] || []).length < sets*stations) {
            return true;
        }
        return false;
    }
    validateWorkoutData = () => {
        if (Object.keys(this.state.introductionVideo).length === 0) {
            this.setState({
                loading: false,
                popupMessage: "Please drag Introduction video",
                showDeleteModal: true,
                positiveButtonText: "OK",
            })
        }
        else if (Object.keys(this.state.warmupVideo).length === 0) {
            this.setState({
                loading: false,
                popupMessage: "Please drag Warmup video",
                showDeleteModal: true,
                positiveButtonText: "OK",
            })
        }
        else if (Object.keys(this.state.coolingVideo).length === 0) {
            this.setState({
                loading: false,
                popupMessage: "Please drag Cooling Down video",
                showDeleteModal: true,
                positiveButtonText: "OK",
            })
        } else {
            this.saveWorkoutApiCall()
        }
    }

    saveWorkoutApiCall = () => {
        if (!this.state.workoutName) {
            this.setState({
                loading: false,
                showDeleteModal: true,
                popupMessage: "Please enter workout name!",
                positiveButtonText: "OK",
            });
            return;
        }
        
        interface ZoneVideo {
            station_id: number,
            set_id: number,
            video_id: any
        }
        interface DraftZoneVideos {
            [key: number]: ZoneVideo[]
        }
        let draftZoneData: DraftZoneVideos = {
            1: [],
            2: [],
            3: []
        }

        this.state.selectedClassType?.attributes?.formatted_station_data?.map((stationElement: any) => {
            stationElement?.sets?.map((setElement: any) => {
                draftZoneData[stationElement?.zone_id].push({
                    station_id: stationElement?.id,
                    set_id: setElement?.id,
                    video_id: this.state.saveZoneData?.[stationElement?.zone_id]?.find((e: any) => (
                        e.station_id === stationElement.id && e.set_id === setElement.id
                    ))?.video_id || "",
                })
            })
        })

        const data = {
            video_template: {
                template_id: this.state.selectedClassType.id,
                warmup: this.state.warmupVideo.hasOwnProperty('video_id') ? this.state.warmupVideo.video_id : this.state.warmupVideo.id,
                introduction: this.state.introductionVideo.hasOwnProperty('video_id') ? this.state.introductionVideo.video_id : this.state.introductionVideo.id,
                cooling_down: this.state.coolingVideo.hasOwnProperty('video_id') ? this.state.coolingVideo.video_id : this.state.coolingVideo.id,
                focus_tag: this.state.selectedFocus,
                workout_name: this.state.workoutName,
                draft_status: this.isDraft()
            },
            zones: this.isDraft() ? draftZoneData : { ...this.state.saveZoneData },
        }

        const url = this.state.workout_id !== "" ? `${configJSON.dragDropInterfaceGetTemplatePostUrl}/${this.state.workout_id}` : configJSON.dragDropInterfaceGetTemplatePostUrl
        const methodType = this.state.workout_id !== "" ? configJSON.putMethod : configJSON.dragDropInterfaceApiMethodTypePost

        const header = {
            "Content-Type": configJSON.validationApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.saveWorkoutApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleChangeSelectedZone = (zone: string) => {
        this.setState({ selectedZone: zone });
    };

    handleClickSortPopover = (
        event: any
    ) => {
        this.setState({ sortAnchorEl: event.currentTarget });
    };

    handleChangeSelectedSort = (sort: string) => {
        this.setState({ selectedSort: sort }, () => {
            let listVideoTemp =  [...this.state.videoLibraryList]
            listVideoTemp = listVideoTemp.filter((videoItem: any) => {
               return videoItem.attributes.category === sort
            })
            this.setState({
                videoLibraryListFilter: listVideoTemp
            })
            this.handleClosePopover()
        })
    };

    handleClickFilterPopover = (
        event: any
    ) => {
        this.setState({ filterAnchorEl: event.currentTarget });
    };

    handleChangeSelectedFilter = (filter: string) => {
        this.setState({ selectedFilter: filter, }, () => {
            let listVideoTemp =  [...this.state.videoLibraryList]
            listVideoTemp = listVideoTemp.filter((videoItem: any) => {
                let isHaveTag = false
                videoItem.attributes.add_tags.forEach((tagItem: any) => {
                    if(tagItem.name === filter){
                        isHaveTag = true
                    }
                });
                return isHaveTag
            })
            this.setState({
                videoLibraryListFilter: listVideoTemp
            })
            this.handleClosePopover()
        });

    };

    getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        border: isDragging ? "1px solid #E73700" : "none",
        ...draggableStyle
    });

    filteringString = (e: any) => {
        this.setState({ searchStr: e.target.value }, () => {
            this.getDragDropInterfaceVideoLibraryData(this.state.selectedFilter.toLocaleLowerCase())
        })
    }
    handleRemoveVideo = (key: string) => {
        if (key === 'introductionDroppable') {
            this.setState({ introductionVideo: {} })
        } else if (key === 'warmupDroppable') {
            this.setState({ warmupVideo: {} })
        } else if (key === 'coolingDropable') {
            this.setState({ coolingVideo: {} })
        }
        else if (key.includes(".")) {
            if (this.state.selectedZone === "zone1") {
                this.handleZoneUpdate(1, key, {})
            }
            if (this.state.selectedZone === "zone2") {
                this.handleZoneUpdate(2, key, {})
            }
            if (this.state.selectedZone === "zone3") {
                this.handleZoneUpdate(3, key, {})
            }

        }
    }

    onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const sourceData = this.state.videoLibraryList[source.index]

        if (destination.droppableId === 'introductionDroppable') {
            this.setState({ introductionVideo: sourceData })
        } else if (destination.droppableId === 'warmupDroppable') {
            this.setState({ warmupVideo: sourceData })
        } else if (destination.droppableId === 'coolingDropable') {
            this.setState({ coolingVideo: sourceData })
        }

        else if (destination.droppableId.includes(".")) {
            if (this.state.selectedZone === "zone1") {
                this.handleZoneUpdate(1, destination.droppableId, sourceData)
            }
            if (this.state.selectedZone === "zone2") {
                this.handleZoneUpdate(2, destination.droppableId, sourceData)
            }
            if (this.state.selectedZone === "zone3") {
                this.handleZoneUpdate(3, destination.droppableId, sourceData)
            }

        }

    };

    handleZoneUpdate = (zoneNumber: number, id: string, sourceData: any) => {
        const intIdZone = +id[0];

        if (intIdZone >= 1 && intIdZone <= 9) {
            const stationIndex = parseInt(id.split('.')[0]);
            const setIdZone = id.split('.')[1];

            const newState = {
                ...(this.state as any)[`zone${zoneNumber}`],
                [intIdZone]: {
                    ...(this.state as any)[`zone${zoneNumber}`][intIdZone],
                    [`set${setIdZone}`]: sourceData
                }
            };

            const zoneKey = `zone${zoneNumber}`;
            this.setState(prevState => ({
                ...prevState,
                [zoneKey]: newState
            }));

            this.formatZoneData(zoneNumber, stationIndex, setIdZone, sourceData);
        }
    };
    formatZoneData = (
        zone: number,
        stationIndex: number,
        setId: string,
        sourceData: any
    ) => {
        const { selectedClassType } = this.state;

        if (!selectedClassType || !selectedClassType.attributes) {
            return;
        }

        const formattedStationData = selectedClassType.attributes.formatted_station_data;

        const filteredStationData = formattedStationData.find(
            (stationData: any) =>
                stationData.zone_id === zone && stationData.station_number === stationIndex
        );

        const filteredSetData = filteredStationData?.sets.find(
            (set: any) => set.set_number === parseInt(setId)
        );

        if (!filteredSetData) {
            console.error("No matching set data found.");
            return;
        }

        const { saveZoneData } = this.state;
        const data = { ...saveZoneData };

        const obj = {
            station_id: filteredStationData.id,
            set_id: filteredSetData.id,
            video_id: sourceData.hasOwnProperty('video_id') && sourceData.video_id !== null ? sourceData.video_id : sourceData.attributes?.id,
        };

        if (!data[zone]) {
            data[zone] = [];
        } else {
            data[zone] = data[zone].filter((item: any) => {
                return item.set_id !== filteredSetData.id;
            });
        }
        if (obj.video_id) {
            data[zone].push(obj);
        }
        data[zone].sort((a: any, b: any) => a.set_id - b.set_id);

        this.setState({ saveZoneData: data });
    };

    getWorkoutDataAPICall = () => {
        this.setState({
            loading: true,
        })
        const apiUrl = `${configJSON.workoutListAPIEndpoint}?template_id=${this.state.selectedClassType.id}&focus_tag=${this.state.selectedFocus}`
        const header = {
            "Content-Type": configJSON.dragDropInterfaceApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getWorkoutDataAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dragDropInterfaceApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

}