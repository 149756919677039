import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import { PlayArrow, Pause } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactPlayer from "react-player";

interface CommonVideoPlayerProps extends BoxProps {
  videoWidth?: string;
  videoHeight?: string;
  source: string;
  handleFileChange?: (event: any, type: string) => void;
}

const useStyles = makeStyles({
  progress: {
    color: "gray",
  },
  circle: {
    color: "#FF6E40",
  },
});
const CommonVideoPlayer: React.FC<CommonVideoPlayerProps> = ({
  videoWidth,
  videoHeight,
  source,
  handleFileChange,
  ...boxProps
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const classes = useStyles();
  const fileInputRef = useRef<any>(null);
  console.log("source", source)
  const theme = createTheme({
    palette: {
      primary: {
        main: "#313320",
        contrastText: "#fff",
      },
      secondary: {
        main: "#E73700",
      },
    },
  });
  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    if (video) {
      setDuration(video.duration);
    }
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
  };
  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const defaultWidth = videoWidth || "100%";
  const defaultHeight = videoHeight || "auto";
  const progress = duration ? (currentTime / duration) * 100 : 0;

  const renderCircularProgress = () => {
    return (
      <Box position="relative">
        <Box
          position="absolute"
          top={0}
          left={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <CircularProgress
            variant="determinate"
            value={100}
            size={78}
            thickness={4}
            style={{ color: "#a5a7a9" }}
          />
        </Box>
        <CircularProgress
          variant="determinate"
          value={progress}
          size={78}
          thickness={4}
          // disableShrink
          classes={{ root: classes.progress, circle: classes.circle }}
        />
        <IconButton
          onClick={handlePlayPause}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
          }}
        >
          {isPlaying ? (
            <Pause fontSize="large" color="primary" />
          ) : (
            <PlayArrow fontSize="large" color="primary" />
          )}
        </IconButton>
      </Box>
    );
  };
  const renderProgressText = () => {
    return (
      <Typography>
        {`${currentTime.toFixed(2)}/${duration.toFixed(2)} Sec`}
      </Typography>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Paper elevation={3} style={{ position: 'relative' }}>
          <video 
            ref={videoRef} 
            style={webStyle.video} 
            onLoadedMetadata={handleLoadedMetadata} 
            onTimeUpdate={handleTimeUpdate} 
            autoPlay 
            src={source} 
            controls 
            width={defaultWidth}
            height='100%'
          >
            Sorry, your browser doesn't support embedded videos.
          </video>
          {/* <ReactPlayer
          ref={videoRef} 
          style={webStyle.video}
             url={source} 
            controls={true} // Show native video controls
            playing={false} // Auto-play when the component mounts
            volume={0.8} // Set the initial volume (0 to 1)
            width="100%" // Set the width of the player
            height="auto" // Set the height of the player
            loop={false} // Loop the video
            muted={false} // Start muted
            playbackRate={1.0} // Set the playback speed
          /> */}
          <Box style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleButtonClick} sx={webStyle.buttonReplace}>
            <Typography>Replace</Typography>
          </Box>
        </Paper>
        {/* <Box sx={webStyle.absobluteContainer}>
          <Box sx={webStyle.absoluteBottomContainer}>
            <Box />
            <Box onClick={handleButtonClick} sx={webStyle.buttonReplace}>
              <Typography>Replace</Typography>
              <input type="file" ref={fileInputRef} accept="video/*" onChange={(e) => handleFileChange && handleFileChange(e, 'videos')} style={{ display: 'none' }} />
            </Box>
          </Box>
          <Box sx={webStyle.absoluteBottomContainer}>
            {renderCircularProgress()}
            {renderProgressText()}
          </Box>
        </Box> */}
      </div>
      <input id="videoFileInput" type="file" ref={fileInputRef} accept="video/*" onChange={(e) => handleFileChange && handleFileChange(e, 'videos')} style={{ display: 'none' }} />
    </ThemeProvider>
  );
};

const webStyle = {
  container: {
    borderRadius: 16,
    overFlow: "hidden",
  },
  absobluteContainer: {
    position: "absolute",
    display: "flex",
    padding: 13,
    flexDirection: "column",
    justifyContent: "space-between",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: "hidden",
  },
  video: {
    borderRadius: 16,
    overFlow: "hidden",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%), url(<path-to-image>)",
    backgroundPosition: "0px -35.723px",
    backgroundSize: "100% 123.261%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "lightgray",
    height:"100%",
  },
  absoluteBottomContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    color: "#fff",
  },
  buttonReplace: {
    padding: 4,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 13,
    background: "#fff",
    color: "#E73700",
    fontSize: 12,
    position: 'absolute', 
    top: '10px', 
    right: '10px'
  },
};

CommonVideoPlayer.defaultProps = {
  videoWidth: "100%",
  videoHeight: "auto",
};

export default CommonVideoPlayer;
