// Customizable Area Start
import React from "react";
import {
  Button,
} from "@material-ui/core";


import UploadCSVController, {
  Props,
} from "./UploadCSVController";

export default class UploadCSV extends UploadCSVController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <div style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 20
      }}>
        <input type="file" onChange={this.handleFileChange} data-testid="file-input"/>
        <Button
          style={{
            backgroundColor: "rgba(0,0,0,0.1)",
            marginTop: 15,
            width: 300
          }}
          data-testid="upload-btn"
          onClick={this.upload}>Upload</Button>
      </div>
    );
  }
}

// Customizable Area End
