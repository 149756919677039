// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  IconButton,
  Grid,
  Popover,
  RootRef,
  CircularProgress,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommonModalWorkout from "../../../components/src/CommonModalWorkout"
import ModalConfirmDelete from "../../../components/src/ModalConfirmDelete"
import ModalAddTimeSlot from "../../../components/src/ModalAddTimeSlot"
import EditIcon from '@material-ui/icons/Edit';
import CalendarDatePicker from "../../../components/src/CalendarDatePicker"
import {Template} from "./types"
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {imgButtonTime} from "./assets"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#eb7c58",
    },
  },
  typography: {
    h6: {
      fontSize: 20,
      fontWeight: 700,
      color: "#e53600",
    },
    caption: {
      fontSize: 16,
      fontWeight: 700,
      color: "#000",
    },
    subtitle1: {
      fontSize: 16,
      color: "#000",
    },
  },
});

import SchedulingController, { Props } from "./SchedulingController";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }
  getStyleTemplateBox = (isSelected: boolean ,isUsed: boolean) => {
    if (isSelected) {
      return webStyle.battleRoyalEventBoxSelected;
    }
    if (!isUsed) {
      return webStyle.battleRoyalEventBoxNew;
    }
    return {};
  };
  
  getStyleTextMonth = (isCurrentMonth: boolean, isToday: boolean) => {
    return isCurrentMonth
      ? { ...webStyle.textMonth, color: isToday ? "#fff" : "#777777" }
      : {...webStyle.textMonthBlur, color: isToday ? "#fff" : "#747474"};
  }
  geStyleDayMonthTextContainer = (isToday: boolean) => {
    return isToday
    ? webStyle.dayMonthTextTodayContainer
    : {};
  }

  getNameFromTag = (cardItem: any, tagMode?: boolean) => {
    if(tagMode && cardItem.focus_tag ){
      return `${cardItem.title} |  ${cardItem.focus_tag}`
    }
    if(cardItem.workout_name) return cardItem.workout_name
    
    return `${cardItem.title}`
  }
  /* istanbul ignore next */
  renderDateCalendar = (
    currentDate: any,
    currentMonth: any,
    today: any,
    handleDateClick: any,
    handleScheduleBlurClick: any
  ) => {
    const isCurrentMonth = currentDate.isSame(currentMonth, "month");
    const isToday = currentDate.isSame(today, "day");
    const isSelected =
      this.state.dateSelected &&
      currentDate.isSame(this.state.dateSelected, "day");
    const onPressedDate = handleDateClick(currentDate);
    const isPastDay = currentDate.isBefore(today, "day");
    const onPressedSchedule = isPastDay ? () => {} : handleScheduleBlurClick(currentDate);
    const onPressedDeleteDate = () => {
      const workoutSelected = this.state.listScheduleSlot.find(scheduleSlot => scheduleSlot.attributes.schedule_date === currentDate.format("YYYY-MM-DD"))
      this.setState({
        isOpenModalConfirmDelete: true,
        deleteWorkout: workoutSelected
      })
    }

    const currentTimeSlot = this.state.listScheduleSlot.find(
      (timeSlot: any) => {
        return currentDate.isSame(
          moment(timeSlot.attributes.schedule_date, "YYYY-MM-DD")
        );
      }
    );
    const styleTextMonth = this.getStyleTextMonth(isCurrentMonth, isToday)
    const styleDayMonthTextContainer = this.geStyleDayMonthTextContainer(isToday)
    const renderEmptyScheduleSlot = () => {
      return (
        <Box
          data-test-id="empty-schedule-slot"
          onClick={onPressedSchedule}
          sx={{...webStyle.scheduleBox, ...webStyle.buttonAction}}
        >
          <Box sx={webStyle.calendarContentWrapper}>
            <img src={imgButtonTime} style={webStyle.iconButtonTime} />
            <Typography style={webStyle.textScheduleBox}>Schedule</Typography>
          </Box>
          <AddIcon fontSize="small" />
        </Box>
      );
    };
    const renderScheduleTimeSlot = () => {
      return isPastDay ? (
        <Box sx={{...webStyle.scheduleBoxSlot,  paddingRight: 0}}>
          <Box sx={webStyle.calendarContentWrapper}>
            <img
              src={imgButtonTime}
              style={{ ...webStyle.iconButtonTime, stroke: "#000" }}
            />
            <Typography style={webStyle.textScheduleBox}>
              No template
            </Typography>
          </Box>
          <IconButton onClick={onPressedDeleteDate}>
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <div style={{
          maxWidth: 200,
          maxHeight: 65,
        }}>
          <Droppable
            key={currentDate.format("YYYY-MM-DD")}
            droppableId={currentDate.format("YYYY-MM-DD")}
          >
            {(provided: any) => (
              <>
                <RootRef rootRef={provided.innerRef}>
                  <Box sx={{...webStyle.scheduleBoxSlot, paddingRight: 0, ...webStyle.buttonAction}}>
                    <Box onClick={onPressedDate} display="flex" flexDirection="row" alignItems="center">
                      <img
                        src={imgButtonTime}
                        style={{ ...webStyle.iconButtonTime, stroke: "#000" }}
                      />
                      <Typography style={webStyle.textScheduleBox}>
                        Add template
                      </Typography>
                    </Box>
                    <IconButton onClick={onPressedDeleteDate}>
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </RootRef>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </div>
      );
    };

    const renderEventDate = () => {
      if (currentTimeSlot) {
        if(currentTimeSlot.attributes.time_slots.length === 0){
          return renderEmptyScheduleSlot();
        }
        if (currentTimeSlot.attributes.video_template_id !== null) {
          return (
            <Box
              data-testid="date-template-button"
              sx={{
                ...webStyle.battleRoyalEventBox,
                ...this.getStyleTemplateBox(isSelected, currentTimeSlot?.template?.is_used ?? true),
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box onClick={onPressedDate}  sx={{display: 'flex', flex: 1, height: 36, alignItems:'center', overflow: 'hidden'}}>
                <Typography style={webStyle.textBattleRoyal}>
                  {currentTimeSlot?.template?.workout_name}
                </Typography>
              </Box>
              <IconButton onClick={onPressedDeleteDate}>
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Box>
          );
        }
        return renderScheduleTimeSlot();
      }
      return renderEmptyScheduleSlot();
    };

    return (
      <Box
        key={currentDate.format("YYYY-MM-DD")}
        sx={{
          ...webStyle.dayMonthContainer,
          ...styleTextMonth,
        }}
      >
        <Box
          sx={{
            ...webStyle.dayMonthTextContainer,
            ...styleDayMonthTextContainer,
          }}
        >
          <Typography style={{ fontSize: 12.7 }}>
            {currentDate.format("D")}
          </Typography>
        </Box>
        {renderEventDate()}
      </Box>
    );
  };
  renderCalendar= () => {
    const weekdays = moment.weekdaysShort();
    const currentMonth = this.state.currentMonth;

    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const startDate = startOfMonth.startOf("week");
    const endDate = endOfMonth.endOf("week");

    const calendar = [];
    let row = [];
    const weekdaysRow = weekdays.map((weekday) => (
      <Box key={weekday} sx={webStyle.weekDay}>
        <Typography>{weekday}</Typography>
      </Box>
    ));
    calendar.push(
      <Box key="calendar-week" sx={webStyle.weekDayRow}>
        {weekdaysRow}
      </Box>
    );

    let currentDate = startDate;
    const today = moment();
    const handleDateClick = (date: any) => {
      return () => {
        this.handleChangeSelectedDate(date);
      };
    };
    const handleScheduleBlurClick = (date: any) => {
      return () => {
        this.handleAddTimeSlot(date);
      };
    };
    while (currentDate.isSameOrBefore(endDate)) {
      const dateElement = this.renderDateCalendar(
        currentDate,
        currentMonth,
        today,
        handleDateClick,
        handleScheduleBlurClick
      );
      row.push(dateElement);
      if (currentDate.day() === 6) {
        calendar.push(
          <Box
            sx={webStyle.monthDayRow}
            key={currentDate.format("YYYY-MM-DD")}
          >
            {row}
          </Box>
        );
        row = [];
      }

      currentDate = currentDate.clone().add(1, "day");
    }

    if (row.length > 0) {
      calendar.push(
        <Box
          sx={webStyle.monthDayRow}
          key={currentDate.format("YYYY-MM-DD")}
        >
          {row}
        </Box>
      );
    }
    return calendar;
  }
  renderCalendarWorkout = () => {
    return (
      <Grid key="calendar-container-grid" item xs={8}>
        <Box key="calendar-container" sx={webStyle.calendarWorkoutContainer}>
          <Box display="flex" sx={webStyle.calendarWorkoutHeader}>
            <Box sx={webStyle.calendarContentWrapper}>
              <Typography style={webStyle.monthHeaderText}>
                {this.state.currentMonth.format("MMM, YYYY")}
              </Typography>
              <IconButton
                data-testid="btn-prev-month"
                onClick={this.handlePrevMonth}
              >
                <ArrowBackIos color="primary" fontSize="small" />
              </IconButton>
              <IconButton
                data-testid="btn-next-month"
                onClick={this.handleNextMonth}
              >
                <ArrowForwardIos color="primary" fontSize="small" />
              </IconButton>
            </Box>
            <CalendarDatePicker
              dateCalendarSelected={this.state.dateCalendarSelected}
              handleChangeDateCalendarSelected={
                this.handleChangeDateCalendarSelected
              }
            />
          </Box>

          {this.renderCalendar()}
        </Box>
      </Grid>
    );
  };
  renderCardRoyal(cardItem: Template, index: number) {
    const isSelected = this.state.dateSelected && cardItem.time && moment(cardItem.time).isSame(this.state.dateSelected, "day");
    const containerStyle = cardItem.is_used ? webStyle.cardTemplateItemUsed : {}
    const selectedStyle= isSelected ? webStyle.cardTemplateItemSelected : webStyle.cardTemplateItemDeselected
    const timeData = cardItem.time ? moment(cardItem.time).format("MMM D YYYY") : ""
    const cardItemId = cardItem.time ? `${cardItem.id}--${cardItem.time}` : cardItem.id.toString() 
    const onClickWorkoutItem = () => {
      this.handleClickClassDay(cardItem.id)
    }
    const imageUrl = cardItem.workout_image_url || require('../../../components/src/unified_logo.png');
    const imageStyle = cardItem.workout_image_url === "" ? {background: "#313320"} : {background: "transparent"}
    return (
      <Draggable key={index.toString()} draggableId={cardItemId} index={index}>
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <Box
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              data-testid="template-item"
              style={{
                ...this.getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                ),
                ...webStyle.cardTemplateItemContainer,
                ...containerStyle,
                ...selectedStyle,
              }}
              onClick={onClickWorkoutItem}
            >
              <img
                style={{
                  ...webStyle.image, ...imageStyle, objectFit: "contain", marginTop: 15,
                  backgroundColor: "rgba(0,0,0,0.1)"
                }}
                src={imageUrl}
                alt="mainImg"
              />
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box sx={{display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography style={{...webStyle.dateCardTemplateItem, marginTop: 15}}>
                    {this.getNameFromTag(cardItem)} 
                  </Typography>
                  <IconButton onClick={() =>{
                    this.props.navigation.navigate("DragDropInterface", {templateId: cardItem.template_id, tag: cardItem.focus_tag})
                  }}>
                   <EditIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Typography style={webStyle.nameCardTemplateItem}>
                  {`${this.getNameFromTag(cardItem, true)} | ${timeData}`}
                </Typography>
              </Box>
              {!cardItem.is_used && <Box sx={webStyle.dotNew} />}
              {isSelected && (
                <Box sx={webStyle.cardTemplateItemSelectedMarked} />
              )}
            </Box>
          </RootRef>
        )}
      </Draggable>
    );
  }
  renderListBattleRoyal() {
    return (
      <Grid item xs={4}>
        <Box display="flex" sx={webStyle.listBattleRoyalContainer}>
          <Typography
            style={webStyle.textClassTypeTitle}
          >
            Class Type
          </Typography>
          {this.renderClassTypePopover()}
          <Box
            onClick={this.handleClickClassTypePopover}
            sx={webStyle.selectClassContainer}
            data-testid="select-class-type"
          >
            <Typography>{this.state.selectedClassType?.title}</Typography>
            <ExpandMoreIcon fontSize="small" />
          </Box>

          <Box sx={webStyle.listBattleRoyalContent}>
            <Box sx={webStyle.headerClassTypeContainer}>
              <Typography style={webStyle.textTemplates}>Workouts</Typography>
              <Box data-test-id="tag-filter-button" onClick={this.handleClickTagFilterPopover} sx={webStyle.filterButton}>
                <Typography style={webStyle.textFilter}>{`Filter${this.state.selectedTag !== "" ? " : " + this.state.selectedTag : ""}`}</Typography>
                <ExpandMoreIcon fontSize="small" />
              </Box>
            </Box>
            <Typography style={webStyle.templateHeaderTitle}>
              Drag template to schedule in your calendar.
            </Typography>
            <Droppable droppableId="templateDropable">
              {(provided: any, snapshot: any) => (
                <Box height="500px" overflow="auto">
                  <RootRef rootRef={provided.innerRef}>
                    <Box>
                    {this.state.listTemplateByClassType.map((item, index) =>
                      this.renderCardRoyal(item, index)
                    )}
                    </Box>
                  </RootRef>
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        </Box>
      </Grid>
    );
  }
  renderClassTypePopover() {
    return (
      <Popover
        id={this.idFilter}
        open={Boolean(this.state.anchorEl || null)}
        anchorEl={this.state.anchorEl}
        onClose={this.handleCloseClassTypePopover}
        PaperProps={{
          style: {
            borderRadius: 17, // Set the desired border radius value
          },
        }}
        data-testid="filter-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{...webStyle.classTypePopoverContainer, width: this.state.anchorEl ? this.state.anchorEl.clientWidth - 20 : 374}}>
          {this.state.listClassType.map((item: any) => {
            const classTypeSelectedStyle =
              item.template_id === this.state.selectedClassType.template_id
                ? webStyle.classTypeSelectedItem
                : webStyle.classTypeDeselectedItem;
            return (
              <Box
                data-testid="filter-item"
                onClick={() => {
                  this.handleChangeSelectedClassType(item);
                }}
                sx={{
                  ...webStyle.classTypePopoverItem,
                  ...classTypeSelectedStyle,
                }}
                key={item.template_id.toString()}
              >
                <Typography>{item.title}</Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    );
  }
  renderTagPopover() {
    return (
      <Popover
        id={this.idTagFilter}
        open={Boolean(this.state.anchorElTag || null)}
        anchorEl={this.state.anchorElTag}
        onClose={this.handleCloseTagPopover}
        PaperProps={{
          style: {
            borderRadius: 17, // Set the desired border radius value
          },
        }}
        data-testid="filter-tag-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{...webStyle.classTypePopoverContainer, width:  374}}>
          {this.state.listTag.map((item: any) => {
            const classTypeSelectedStyle =
              item === this.state.selectedTag
                ? webStyle.classTypeSelectedItem
                : webStyle.classTypeDeselectedItem;
            return (
              <Box
                data-test-id="filter-tag-item"
                onClick={() => {
                  this.handleChangeSelectedTag(item);
                }}
                sx={{
                  ...webStyle.classTypePopoverItem,
                  ...classTypeSelectedStyle,
                }}
                key={item}
              >
                <Typography>{item}</Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    );
  }
  
  renderModalWorkout() {
    return (
      <CommonModalWorkout
        headerTitle="Workout"
        loading={this.state.modalLoading}
        openModal={this.state.openModalWorkout}
        handleCloseModal={this.handleCloseModalWorkout}
        battleOfDaySelected={this.state.battleOfDaySelected}
      />
    );
  }
  renderModalEditWorkout() {
    return (
      <CommonModalWorkout
        editMode
        modalId="modal-edit-workout"
        headerTitle="Workout"
        loading={this.state.modalLoading}
        openModal={!!this.state.editWorkoutSelected}
        selectedSchedule={this.state.editWorkoutSelected}
        handleCloseModal={this.handleCloseModalEditWorkout}
        battleOfDaySelected={this.state.battleOfDaySelected}
        handleDeleteSchedule={this.handleDeleteSchedule}
        handleUpdateSchedule={this.handleUpdateASchedule}
        errorOutside={this.state.errorOutside}
      />
    );
  }
  renderModalAddTimeSlot() {
    return (
      <ModalAddTimeSlot
        openModal={!!this.state.addTimeSlotSelected}
        timeSlotSelected={this.state.addTimeSlotSelected}
        handleCloseModal={this.handleCloseModalAddTimeSlot}
        handleSaveTimeSlot={this.handleSaveTimeSlot}
        loading={this.state.modalLoading}
        errorOutside={this.state.errorOutside}
      />
    );
  }
  renderModalOptionDelete() {
    return <ModalConfirmDelete 
            open={this.state.isOpenModalConfirmDelete} 
            onClose={this.handleCloseModalConfirmDelete} 
            isDate
            handleClickDeleteButton={this.handleDeleteScheduleOption}/>
  }

  
  render() {
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Box overflow='scroll' height="100%" width="100%">
            {this.renderModalWorkout()}
            {this.renderTagPopover()}
            {this.renderModalEditWorkout()}
            {this.renderModalAddTimeSlot()}
            {this.renderModalOptionDelete()}
            <Box sx={webStyle.mainWrapper}>
              {this.state.loading && (
                <Box
                  sx={webStyle.loadingContainer}
                >
                  <CircularProgress style={{color: '#E73700'}} />
                </Box>
              )}
              <Box sx={webStyle.headerContainer}>
                <Typography
                  style={{
                    flex: 1,
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#E73700",
                  }}
                >
                  Schedule
                </Typography>
              </Box>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Grid style={{ paddingTop: 20 }} spacing={4} container>
                  {this.renderCalendarWorkout()}
                  {this.renderListBattleRoyal()}
                </Grid>
              </DragDropContext>
            </Box>
          </Box>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    opacity: 0.5,
    zIndex: 10,
  },
  modalConfirmDeleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  calendarContentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textClassTypeTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: "#313320" 
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#faf9f6",
    padding: 37,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textTemplates: {
    fontSize: 20,
    fontWeight: 700,
    color: "#313320"
  },
  textFilter: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
  filterButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconButtonTime: {
    width: 16,
    height: 16,
    marginRight: 4
  },
  headerClassTypeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 14,
  },
  listBattleRoyalContainer: {
    flexDirection: "column",
    width: "100%",
    marginTop: 9
  },
  calendarWorkoutContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  calendarWorkoutHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textMonth: {
    fontSize: 20,
    color: "#777777",
  },
  textMonthBlur: {
    fontSize: 20,
    color: "#747474",
  },
  battleRoyalEventBox: {
    display: "flex",
    alignItems: "center",
    border: "0.6px solid #BDB7A8",
    background: "#EEECE7",
    borderRadius: 7,
    paddingLeft: 10,
    height: 36,
    marginRight: 1,
    marginTop: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },
  battleRoyalEventBoxNew: {
    border: "0.6px solid #A0BBFF",
    background: "#F5F8FF",
  },
  battleRoyalEventBoxSelected: {
    border: "0.6px solid #E73700",
    background: "#FFF3EF",
  },
  scheduleBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    border: "0.63px solid #EEECE7",
    borderRadius: 7.2,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 1,
    height: 24,
    marginTop: 8,
    color: "#939393",
    background: "#fff",
    justifyContent: "space-between",
  },
  buttonAction: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  scheduleBoxSlot: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    border: "0.63px solid #E73700",
    borderRadius: 7.2,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 1,
    height: 24,
    marginTop: 8,
    color: "#939393",
    background: "#FFF3EF",
    justifyContent: "space-between",
  },
  textBattleRoyal: {
    fontWeight: 600,
    fontSize: 8.1,
    color: "#313320",
  },
  monthHeaderText: {
    fontSize: 16,
    fontWeight: 600,
  },
  textScheduleBox: {
    fontSize: 8.1,
    fontWeight: 400,
  },
  weekDayRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 41,
    marginTop: 17,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overFlow: "hidden",
  },
  weekDay: {
    display: "flex",
    flex: 1,
    background: "#EEECE7",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14.2,
    color: "#313320",
  },
  monthDayRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    background: "#fff",
  },
  dayMonthContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingTop: 5,
    paddingBottom: 34,
  },
  dayMonthTextContainer: {
    display: "flex",
    width: 26,
    height: 26,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    alignSelf: "flex-end",
    background: "#fff",
  },
  dayMonthTextTodayContainer: {
    background: "#e73600",
  },
  selectClassContainer: {
    height: 44,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 17,
    border: "0.7px solid #313320",
    fontSize: 16,
    color: "#313320",
    paddingLeft: 16,
    paddingRight: 16,
    background: "#fff",
    marginTop: 17,
    "&:hover": {
      cursor: "pointer",
    },
   
  },
  listBattleRoyalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    background: "#fff",
    marginTop: 6,
    padding: 10,
    borderRadius: 17,
    paddingTop: 16,
  },
  image: {
    width: 63,
    height: 50,
    borderRadius: 17,
    marginRight: 11,
    paddingRight: 5,
    paddingLeft: 5,
  },
  templateHeaderTitle: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 20,
    color: "#414141",
  },
  cardTemplateItemContainer: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 13,
    borderRadius: 17,
    paddingLeft: 13,
    paddingBottom: 15,
    marginBottom: 6,
  },
  cardTemplateItemUsed: {
    background: "#EEECE7",
  },
  cardTemplateItemSelected: {
    border: "0.7px solid #E73700",
    paddingRight: 4,
  },
  cardTemplateItemDeselected: {
    paddingRight: 13,
  },
  cardTemplateItemSelectedMarked: {
    width: 3,
    height: 38,
    background: "#E73700",
    borderRadius: 17,
    marginLeft: 6,
    alignSelf: "center"
  },
  dateCardTemplateItem: {
    fontSize: 14,
    fontWeight: 600,
    color: "#313320",
  },
  nameCardTemplateItem: {
    fontSize: 16,
    fontWeight: 400,
    color: "#747474",
  },
  dotNew: {
    width: 8,
    height: 8,
    borderRadius: 5,
    backgroundColor: "#A0BBFF",
  },
  classTypePopoverContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    background: "#FFF",
    fontSize: 16,
    borderRadius: 17,
    border: "1px solid #747474"
  },
  classTypePopoverItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 8,
    height: 40,
    paddingLeft: 40,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
    }
  },
  classTypeSelectedItem: {
    background: "#EEECE7",
    color: "#313320"
  },
  classTypeDeselectedItem: {
    background: "#fff",
    color: "#747474"
  }
};
// Customizable Area End
