Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_scheduling/schedules";
exports.templateListAPIEndPoint = "bx_block_video_template/video_templates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.getApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.applicationJsonApiContentType = "application/json";
exports.scheduleListAPIEndPoint = "bx_block_scheduling/schedules";
exports.getWorkoutAPIEndPoint = "bx_block_video_template/video_templates";
exports.getRemainingDayEndPoint = "bx_block_dashboard/homes/remaining_workout";
// Customizable Area End