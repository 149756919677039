Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.templateAPIEndPoint = "bx_block_template_management/templates";
exports.focusListAPIEndPoint = "all_focus_tag"
exports.createFocusAPIEndPoint = "create_focus_tag"

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.errorTitleMissing = "Please enter the title"

exports.getWorkoutAPIEndPoint = 'bx_block_video_template/video_templates';



// Customizable Area End