import Box from "@material-ui/core/Box";
import React, { useState } from 'react';

interface Props {
  renderHeader: any;
  renderChildren: any;
}

const ExpandableItem = (props: Props) => {
  const { renderHeader, renderChildren } = props;
  const [showExpand, setShowExpand] = useState(false);

  return <Box style={{
  }}>
    <Box onClick={() => {
      setShowExpand(!showExpand)
    }}>
      {renderHeader()}
    </Box>
    {showExpand ? (
      <Box>
        {renderChildren()}
      </Box>
    ) : undefined}
  </Box>;
};

export default ExpandableItem;