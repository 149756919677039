// Customizable Area Start
import React, { useState } from "react";

import {
    Box,
    Typography,
    IconButton,
    InputBase,
    Grid,
    Popover,
    Modal,
    TextField,
    Button,
    Menu,
    MenuItem,
} from "@material-ui/core";
import ReactPlayer from "react-player";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
    imgLevel,
    imgSchedule,
} from "../../blocks/videolibrary2/src/assets";
//@ts-ignore
import VideoImageThumbnail from "react-video-thumbnail-image";

interface VideoItemProps {
    item: any;
    handleOpenModalEdit: any;
    handleDeleteVideo: any;
}

export default function VideoItem(props: VideoItemProps) {
    const { item, handleOpenModalEdit, handleDeleteVideo } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleOpenModalEdit(item);
        setAnchorEl(null);
    }

    const handleDelete = () => {
        handleDeleteVideo(item);
        setAnchorEl(null);
    }

    return (
        <Box key={item.id} >
            <Box
                data-testid="video-item"
                sx={webStyle.videoItemContainer}
            >

                <div
                    style={{ height: 140, width: 248, overflow: "hidden", borderRadius: 15 }}
                >
                    {item?.attributes?.video_url[0]?.url || item?.video_url ? (
                        <VideoImageThumbnail
                            videoUrl={item?.attributes?.video_url[0]?.url || item?.video_url || ""}
                            width={248}
                            height={140}
                            key={item?.attributes?.video_url[0]?.url || item?.video_url || ""}
                        />
                    ) : undefined}
                </div>

                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={2}
                >
                    <Box sx={{
                        maxWidth: '80%', overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}>
                        <Typography variant="body1"
                        style={{width: 248}}
                            noWrap
                        >
                            {item?.attributes?.exercise_name?.charAt(0).toUpperCase() + item?.attributes?.exercise_name?.slice(1)}
                        </Typography>
                    </Box>
                    <MoreVertIcon onClick={handleClick} fontSize="small" />
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                >
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <img style={webStyle.iconVideoItem} src={imgSchedule} />
                        <Typography variant="body2">
                            {moment(item?.attributes?.updated_at).format(
                                "DD MMM, YYYY"
                            )}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        {/* <img style={webStyle.iconVideoItem} src={imgLevel} />
                        <Typography variant="body2">{item?.attributes?.level?.charAt(0).toUpperCase() + item?.attributes?.level?.slice(1)}</Typography> */}
                    </Box>
                </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Box>
    );
}


const webStyle = {
    videoItemContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        background: "#fff",
        padding: 12,
        borderRadius: 15,
        overflow: "hidden",
        "&:hover": {
            cursor: "pointer",
        },
        marginBottom: 18
    },
    videoItemSelf: {
        overflow: "hidden",
        borderRadius: 15,
    },
    iconVideoItem: {
        width: 14,
        height: 14,
        marginRight: 1,
    },
};