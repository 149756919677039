Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.dragDropInterfaceApiMethodType = "GET";
exports.dragDropInterfaceGetVideoLibraryUrl = "/bx_block_videos8/videos"; 
exports.dragDropInterfaceGetTemplateOverviewUrl = "/bx_block_template_management/templates";
exports.dragDropInterfaceApiMethodTypePost = "POST";
exports.dragDropInterfaceGetTemplatePostUrl ="bx_block_video_template/video_templates"
exports.getWorkoutAPIEndPoint ="bx_block_video_template/video_templates"
exports.getTemplateAPIEndPoint = "bx_block_template_management/templates";
exports.getZoneTodayAPIEndPoint = "bx_block_scheduling/schedule_time_slots";
exports.getStatusPlayingEndPoint = "bx_block_cfmultidisplaymanager/zones/show_zone_play_pause";
exports.updateStatusPlayingEndPoint = "bx_block_cfmultidisplaymanager/zones/update_zone_play_pause";
exports.dragDropInterfaceApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DragDropInterface";
exports.labelBodyText = "DragDropInterface Body";
exports.workoutListAPIEndpoint="bx_block_video_template/workout_index"
exports.putMethod="PUT"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End