// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  Modal,
  IconButton
} from "@material-ui/core";
import CommonButton from "../../../components/src/CommonButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {imgIconRight} from "./assets"
import Close from "@material-ui/icons/Close";
import ThumbVideo from "../../../components/src/ThumbVideo";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#eb7c58",
    },
  },
});

import Cfmultidisplaymanager2Controller, {
  Props,
  configJSON,
} from "./Cfmultidisplaymanager2Controller";

export default class Cfmultidisplaymanager2 extends Cfmultidisplaymanager2Controller {
  constructor(props: Props) {
    super(props);
  }
  renderZone = (item: any) => {
    return (
      <Box
        key={item.id}
        sx={webStyle.zoneItemContainer}
      >
        <Typography style={webStyle.zoneItemText}>{item.attributes.name}</Typography>
        <CommonButton testId="view-screen" onClickButton={()=> this.showZoneDetailApi(item.id)} renderRightIcon={<img src={imgIconRight} style={webStyle.iconButton}/>} width={162} height={56} mode="secondary" label="View Screen"/>
      </Box>
    );
  }
  renderModalZone = () => {
    return (
      <Modal
      open={this.state.openModal}
      onClose={this.handleCloseModal}
      data-test-id="modal-workout"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={webStyle.modalWrapper}>
        <Box sx={webStyle.modalContainer}>
          <Box sx={webStyle.modalHeader}>
            <Typography style={webStyle.textHeaderModal}>
              {this.state.zoneDetail?.attributes?.name ?? ""}
            </Typography>
            <IconButton data-test-id="modal-button-close" onClick={this.handleCloseModal}>
              <Close fontSize="large" />
            </IconButton>
          </Box>
          <Box sx={webStyle.modalWorkoutList}>
            {this.state.zoneDetail?.attributes?.custom_data?.map((item: any) => (
              <Box
                display="flex"
                flexDirection="row"
                key={item.id}
                sx={webStyle.modalWorkoutItem}
              >
                {/* <img style={webStyle.image} src={item.video_image_url} alt="mainImg" /> */}
                <ThumbVideo
                  item={item}
                  style={webStyle.thumbSelectedVideoWrapper}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography style={webStyle.textName}>{item.exercise_name}</Typography>
                  <Typography style={webStyle.textDes}>{item.title}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
    )
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box width="100%" sx={{ overflow: "scroll" }}>
          {this.renderModalZone()}
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.headerContainer}>
              <Typography style={webStyle.textHeader}>
                Multiscreen
              </Typography>
            </Box>
            <Typography style={webStyle.textContentHeader}>
              Available Zones
            </Typography>
            <Box sx={webStyle.contentContainer}>
              {this.state.zoneList.map(this.renderZone)}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#faf9f6",
    paddingTop: 32,
    paddingLeft: 38,
    paddingRight: 30,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  textHeader: {
    fontSize: 20,
    fontWeight: 700,
    color: "#E73700",
    display: "flex",
    flex: 1,
  },
  textHeaderModal: {
    fontSize: 16,
    fontWeight: 700,
    color: "#313320"
  },
  textContentHeader: {
    fontSize: 24,
    fontWeight: 700,
    color: "#000",
    marginTop: 78,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 32,
    flex: 1,
  },
  zoneItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 16,
    border: "0.7px solid #C2C2C2",
    background: "#FFF",
    padding: "27px 30px 26px 30px",
    marginBottom: 20
  },
  zoneItemText: {
    fontSize: 20,
    fontWeight: 700,
    color: "#313320"
  },
  iconButton: {
    width: 24,
    height: 24,
    marginLeft: 8
  },
  modalWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 630,
    height: 630,
    borderRadius: 16,
    background: "#fff",
    overflow: "hidden",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 24,
    paddingRight: 18,
    background: "#EEECE7",
  },
  modalWorkoutList: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    overflowY: 'scroll',
  },
  modalWorkoutItem: {
    padding: 16,
    paddingLeft: 13,
    border: "0.7px solid #C2C2C2",
    borderRadius: 17,
    marginTop: 8,
  },
  image: {
    width: 73,
    height: 50,
    borderRadius: 17,
    marginRight: 12
  },
  textName: {
    fontSize: 14,
    fontWeight: 600,
    color: "#313320"
  },
  textDes: {
    fontSize: 16,
    fontWeight: 400,
    color: "#747474"
  },
  thumbSelectedVideoWrapper: {
    overflow: "hidden",
    backgroundColor: "rgba(0,0,0,0.1)",
    width: 73,
    height: 50,
    borderRadius: 17,
    marginRight: 12,
  },
};
// Customizable Area End
