import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { generateRequestMessage, generateRequestMessageData } from "../../../components/src/MessageSupport";
import {filterList} from "./assets"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // loader:String;
}

interface S {
  anchorEl: HTMLButtonElement | null;
  selectedFilter: string;
  openModal: boolean;
  sampleData: any;
  searchInput: string;
  editItemData: any;
  editableType: string;
  newTag: string;
  fileBase64Url: string;
  file: any;
  base64URL: any;
  modalOpen: boolean;
  tags: any;
  selectedTags: any;
  activeTab: string;
  listTag: string[];
  openModalAddTag: boolean;
  openModalAddCategory: boolean;
  allTags: any;
  newTagsArr: any;
  filteredTag: string[];
  sortEnabled: boolean;
  sortedTagList: string[];
  editsuccessmodal: boolean;
  editfailmsg: string;
  loading: boolean;
  videoToDelete: string;
  thumbnailUrl: any;
  videoDisplayLimit: number;
  listCategory: string[]
}

interface SS {
  id: any;
}

export default class VideolibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  idFilter = "simple-popover";
  getVideoApiCallId: string = "";
  addTagApiCallId: string = "";
  postCreateTagId: string = "";
  editVideofileId: string = "";
  deleteVideoId: string = "";
  getAllTagsApiCallId: string = "";
  listTab = ["All Videos", "All Tags", "All Categories"];
  getTagListApiCallId:  string = "";
  getListCategoryApiCallId:  string = "";
  addNewTagApiCallId:  string = "";
  addNewCategoryApiCallId:  string = "";
  deleteTagApiCallId:  string = "";
  deleteCategoryApiCallId:  string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      anchorEl: null,
      selectedFilter: "",
      openModal: false,
      sampleData: [],
      searchInput: "",
      editItemData: {},
      editableType: "",
      newTag: "",
      fileBase64Url: "",
      file: null,
      base64URL: "",
      modalOpen: false,
      tags: [],
      selectedTags: [],
      activeTab: this.listTab[0],
      listTag: [],
      openModalAddTag: false,
      openModalAddCategory: false,
      allTags: [],
      newTagsArr: [],
      filteredTag: [],
      sortEnabled: false,
      sortedTagList: [],
      editsuccessmodal: false,
      editfailmsg: "",
      loading: false,
      videoToDelete: "",
      thumbnailUrl: "",
      videoDisplayLimit: 15,
      listCategory: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getVideoDataApi();
    this.getTagListApiCall();
    this.getListCategory()
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    console.log("true");
  }

  isVideoTabActive = () => {
    return this.state.activeTab === this.listTab[0];
  };

  handleLoadmoreVideo = () => {
    this.setState({
      videoDisplayLimit: this.state.videoDisplayLimit + 10
    })
  }

  isShowLoadMoreBtn = () => {
    return this.state.videoDisplayLimit < this.state.sampleData.length;
  }


  getVideoDataApi = () => {
    this.setState({ sampleData: [] })
    const { searchInput, selectedFilter } = this.state;
    const isSearchEmpty = searchInput === "";
    const searchParam = isSearchEmpty ? "" : `search=${searchInput}&`;

    const requestMessage = generateRequestMessage(
      `${configJSON.getAllVideos}?${searchParam}category=${selectedFilter}`,
      configJSON.getApiMethod
    );
    this.getVideoApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllTagsDataApi = () => {
    const requestMessage = generateRequestMessage(
      `${configJSON.getAllTags}`,
      configJSON.getApiMethod
    );
    this.getAllTagsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start

  searchInputFilter = (searchVal: string) => {
    this.setState({
      searchInput: searchVal,
    }, () => { this.getVideoDataApi(); });
  };



  handerCloseFilter = () => {
    this.setState({ anchorEl: null });
  };
  handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({ anchorEl: event.currentTarget });
  };
  /* istanbul ignore next */
  handleFilterTags = (selectedVal: string) => {
    const listTagTemp = this.state.searchInput === "" ? [...this.state.listTag] : [...this.state.filteredTag];
    const originalList = this.state.searchInput === "" ? [...this.state.listTag] : [...this.state.filteredTag];
    if(selectedVal === filterList[0]){
      this.setState({ listTag: originalList })
    }
    if(selectedVal === filterList[1]){
      listTagTemp.sort((a, b) => a.localeCompare(b))
      this.setState({ sortedTagList: listTagTemp })
    }
    if(selectedVal === filterList[2]){
      listTagTemp.sort((a, b) => b.localeCompare(a))
      this.setState({ sortedTagList: listTagTemp })
    }
  }
  handleChangeSelectedFilter = (selectedVal: string) => {
    this.setState({
      selectedFilter: selectedVal,
    }, () => {
      if(this.state.activeTab === this.listTab[0]){
        this.getVideoDataApi();
      }else{
        this.handleFilterTags(selectedVal)
      }
        });
    this.handerCloseFilter();
  };
  handleCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  handleAddTag = (tags: any[]) => {
    if(this.state.editItemData.attributes.add_tags){
      this.setState(prevState => ({
        modalOpen: false,
        editItemData: {
          ...prevState.editItemData,
          attributes: {
            ...prevState.editItemData.attributes,
            add_tags: [
              ...prevState.editItemData.attributes.add_tags,
              ...tags.map((val: any) => ({ name: val }))
            ]
          }
        },
      }));
    }
  };

  // Handle Modal
  handleCloseTagModal = () => {
    this.setState({ modalOpen: false, selectedTags: [] });
  };

  handleEditIconClick = (typeString: string) => {
    this.setState({ editableType: typeString });
  };

  handleOpenModal = () => {
    this.getAllTagsDataApi();
    this.setState({ modalOpen: true });
  };

  handleInputBlur = () => {
    this.setState({ editableType: "" });
  };

  handleTagToggle = (tag: string) => () => {
    this.setState((prevState) => {
      if (prevState.selectedTags.includes(tag)) {
        return {
          selectedTags: prevState.selectedTags.filter((t: string) => t !== tag),
        };
      } else {
        return {
          selectedTags: [...prevState.selectedTags, tag],
        };
      }
    });
  };

  handleInputChange = async (e: any, type: string) => {
    const updatedItem = { ...this.state.editItemData };
    const updatedText = { ...this.state?.editItemData?.attributes };

    if (type === "title") {
      updatedText.exercise_name = e.target.value;
    } else if (type === "category") {
      updatedText.category = e.target.value;
    }
    updatedItem.attributes = updatedText;
    this.setState({ editItemData: updatedItem });
  };

  handleFileInputChange = (e: any) => {
    const updatedItem = { ...this.state.editItemData };
    const updatedText = { ...this.state?.editItemData?.attributes };


    let file = e.target.files[0];
    if (file) {
      this.setState({ base64URL: URL.createObjectURL(file) });
    }
    updatedText.videos = file;
    updatedItem.attributes = updatedText;
    this.setState({ editItemData: updatedItem });
  };

  handleCloseModalAddTag = () => {
    this.setState({
      openModalAddTag: false,
    });
  };
  handleOpenModalAddTag = () => {
    this.setState({
      openModalAddTag: true,
    });
  };
  handleCloseModalAddCategory = () => {
    this.setState({
      openModalAddCategory: false,
    });
  };
  handleOpenModalAddCategory = () => {
    this.setState({
      openModalAddCategory: true,
    });
  };

  postCreateTags = async (data: any) => {
    const requestMessage = generateRequestMessage(
      configJSON.addNewTags,
      configJSON.postAPiMethod
    );
    this.postCreateTagId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editVideoFile = async (data: any) => {
    const requestMessage = generateRequestMessageData(
      `${configJSON.getAllVideos}/${this.state.editItemData?.id}`,
      configJSON.putAPiMethod
    );
    let body = data;
    this.editVideofileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOpenModalEdit = (data: any) => {
    if(data){
      const selectedTagNames = data.attributes.add_tags.map((item: any) => item.name);
      const finalData = selectedTagNames && this.state.allTags.filter((tag:any) => !selectedTagNames.includes(tag));
      finalData.sort((a: string, b: string) => a.localeCompare(b))
  
      this.setState({
        openModal: true,
        editItemData: data,
        base64URL: data?.attributes?.video_url[0]?.url,
        listTag: finalData
      });
    }
  };

  deleteConfirmation = (id: string) => {
    this.setState({ editsuccessmodal: true, loading: false, editfailmsg: "Are you sure you want to delete this video?", videoToDelete: id })
  }

  handleDeleteVideo = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.deleteVideoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteVideoAPIEndPoint}/${this.state.videoToDelete}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  arrayToObjectFormData = (dataObject: any, addTags: any[]) => {
    const formData = new FormData();

    for (const key in dataObject) {
      if (dataObject.hasOwnProperty(key)) {
        if (key === "add_tags") {
          const newTagsArr: any[] = [];
          dataObject[key].forEach((data: any) => {
            data.name ? newTagsArr.push(data.name) : newTagsArr.push(data);
          });
          let uniqueData: any = Array.from(new Set(newTagsArr));
          formData.append("add_tag_list", uniqueData.join(','));

        } else {
          formData.append(key, dataObject[key]);
        }
      }
    }

    return formData;
  };



  handleSave = () => {
    const addTags: any[] = [];
    const newFormData = this.arrayToObjectFormData(
      this.state.editItemData?.attributes, addTags
    );
    this.setState({ loading: true }, () => { this.editVideoFile(newFormData) })
  };

  handleDeleteTag = (tagName: any) => {
    const updatedItem = { ...this.state.editItemData };
    const updatedAttributes = { ...this.state?.editItemData?.attributes };
    const updatedTags = this.state.editItemData?.attributes?.add_tags.filter(
      (tag: any) => (tag.name ? tag.name : tag) !== (tagName.name ? tagName.name : tagName)
    );
    updatedAttributes.add_tags = updatedTags;
    updatedItem.attributes = updatedAttributes;
    this.setState({ editItemData: updatedItem });
  };

  handleChangeTab = (tab: string) => {
    this.setState({ activeTab: tab, searchInput: "", listTag: [...this.state.allTags], selectedFilter: this.isVideoTabActive() ? "" : filterList[0] });
  };
  handleDeleteATag = (tagDeleted: string) => {
    this.setState(prevState => ({
      listTag: prevState.listTag.filter(tag => tag !== tagDeleted)
    }));
  };
  handlePositiveButton = () => {
    if (this.state.editfailmsg === "Are you sure you want to delete this video?") {
      this.handleDeleteVideo()
    }
    this.setState({
      editsuccessmodal: false,
      editfailmsg: "",
    })
  }
  handleNegativeButton = () => {
    this.setState({
      editsuccessmodal: false,
      editfailmsg: "", videoToDelete: ""
    })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId =  message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleResponseCategory(responseJson, apiRequestCallId)
      this.handleResponseData(responseJson, apiRequestCallId)
      this.handleResponseTag(responseJson, apiRequestCallId)
      if (responseJson?.errors) {
        this.setState({
          editsuccessmodal: true,
          editfailmsg: responseJson?.errors[0],
          loading: false, videoToDelete: ""
        })
      }
      runEngine.debugLog("API Message Recived", message);

    }
  }
  handleResponseData = (responseJson: any, apiRequestCallId: string) => {
   
    if (this.getVideoApiCallId === apiRequestCallId) {
      this.setState({
        sampleData: responseJson?.data?.reverse(),
      });
    } else if (responseJson.data && apiRequestCallId === this.editVideofileId) {
        this.setState({ editsuccessmodal: true, loading: false, editfailmsg: "Updated Successfully" })
        this.getVideoDataApi();
        this.getTagListApiCall()
        this.handleCloseModal()
    } else if (apiRequestCallId === this.deleteVideoId) {
      this.getVideoDataApi(); this.setState({ videoToDelete: "" })
    }
   
  }
  handleResponseCategory = (responseJson: any, apiRequestCallId: string) => {
    // GET
    if (responseJson.Categories && apiRequestCallId === this.getListCategoryApiCallId) {
      this.setState({ listCategory: responseJson.Categories, loading: false});
    }
    // ADD
    if (apiRequestCallId === this.addNewCategoryApiCallId) {
      this.handleCloseModalAddCategory();
      this.getListCategory();
    }
    // DELETE
    if (apiRequestCallId === this.deleteCategoryApiCallId) {
      if (responseJson.message){
        this.setState({ editsuccessmodal: true, loading: false, editfailmsg: responseJson.message })
        this.getListCategory();
      }
    }
  }
  handleResponseTag = (responseJson: any, apiRequestCallId: string) => {
    // GET
    if (responseJson.tags && apiRequestCallId === this.getTagListApiCallId) {
      this.setState({ listTag: responseJson.tags, allTags: responseJson.tags, loading: false });
    }
    // ADD
    if (apiRequestCallId === this.addNewTagApiCallId) {
      this.handleCloseModalAddTag();
      this.getTagListApiCall();
    }
    // DELETE
    if (apiRequestCallId === this.deleteTagApiCallId) {
      if (responseJson.message.includes("has been deleted")){
        this.setState({ editsuccessmodal: true, loading: false, editfailmsg: responseJson.message })
        this.getTagListApiCall();
      }else{
        this.setState({ editsuccessmodal: true, loading: false, editfailmsg: responseJson.message })
      }
    }
  }
  getTagListApiCall = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTagListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.videosAPIEndPoint}/${configJSON.allTagsAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getListCategory = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.allCategoryAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addNewTagApiCall = (data: string[]) => {
    const { videosAPIEndPoint, addTagAPIEndPoint, postAPiMethod, validationApiContentType } = configJSON;

    const header = {
      "Content-Type": validationApiContentType
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addNewTagApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${videosAPIEndPoint}/${addTagAPIEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ "name": data.join(", ") }));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), postAPiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addNewCategoryApiCall = (data: string[]) => {
    this.setState({loading: true})
    const { addCategoryAPIEndPoint, postAPiMethod, validationApiContentType } = configJSON;

    const header = {
      "Content-Type": validationApiContentType
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addNewCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${addCategoryAPIEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ "name": data[0] }));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), postAPiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteTagApiCall = (tag: string) => {
    const { videosAPIEndPoint, deleteTagAPIEndPoint, deleteApiMethod, validationApiContentType } = configJSON;

    const header = {
      "Content-Type": validationApiContentType
    };

    const requestMessageDeleteTag = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteTagApiCallId = requestMessageDeleteTag.messageId ;

    requestMessageDeleteTag.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${videosAPIEndPoint}/${deleteTagAPIEndPoint}`);
    requestMessageDeleteTag.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessageDeleteTag.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ "name": tag }));
    requestMessageDeleteTag.addData(getName(MessageEnum.RestAPIRequestMethodMessage), deleteApiMethod);

    runEngine.sendMessage(requestMessageDeleteTag.id, requestMessageDeleteTag);
  }
  deleteCategoryApiCall = (category: string) => {
    const { deleteCategoryAPIEndPoint, deleteApiMethod, validationApiContentType } = configJSON;

    const header = {
      "Content-Type": validationApiContentType
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${deleteCategoryAPIEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ "name": category }));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), deleteApiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTagSearchLocally = (searchText: string) => {
    const { sortEnabled, sortedTagList, listTag } = this.state;
    const listToFilter = sortEnabled ? sortedTagList : listTag;
    const filteredItems = listToFilter.filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );

    this.setState({ searchInput: searchText, filteredTag: filteredItems });
  }

}
