import React, {useState, useEffect} from "react";
import { withRouter } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseIcon from '@material-ui/icons/Pause';
import ReplayIcon from "@material-ui/icons/Replay";
import { baseURL } from "../../blocks/bulkuploading/src/BulkUploadingController";

const DrawerMenu = ({location, history}: any) => {
  const currentUrl = location.pathname;
  const listTab = [
    { name: "Home", link: "/", activeLink: ["/Dashboard", "/"] },
    { name: "Schedule", link: "/Scheduling", activeLink: ["/Scheduling"] },
    { name: "Workout Editor", link: "/DragDropInterface2", activeLink: ["/DragDropInterface2", "/DragDropInterface"] },
    {
      name: "Video Library",
      link: "/Videolibrary2",
      activeLink: ["/BulkUploading", "/Videolibrary2"],
    },
    {
      name: "Workout Library",
      link: "/workoutlibrary",
      activeLink: ["/workoutlibrary"],
    },
    {
      name: "Template Manager",
      link: "/Categoriessubcategories",
      activeLink: ["/Categoriessubcategories", "/Createcategories"],
    },
    // {
    //   name: "Upload CSV",
    //   link: "/csvupload",
    //   activeLink: ["/csvupload"],
    // },
  ];
  const goBack = () => {
    history.goBack();
  }
  const [isPlayingZone, setIsPlayingZone] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  useEffect(() => {
    const intervalPlaying = setInterval(()=> {
      getStatusOfPlaying()
    }, 5000)
    return () => {
      clearInterval(intervalPlaying)
    }
  }, [])
  

  const getStatusOfPlaying = () => {
    fetch(baseURL + "/bx_block_cfmultidisplaymanager/zones/show_zone_play_pause?screen=management", {
      method: "GET",
      headers: { "Content-Type": "application/json"},
    }
    ).then(async (responseJson: any) => {
      const readableResponse = await responseJson.json();
      if(readableResponse.messages === "false" && isPlayingZone){
        setIsPlayingZone(false)
      }
      if(readableResponse.messages === "true" && !isPlayingZone){
        setIsPlayingZone(true)
      }
    })
  }
  const onClickPauseButton = () => {
    if(isPlayingZone){
      fetch(baseURL + "/bx_block_cfmultidisplaymanager/zones/update_zone_play_pause", {
        method: "PUT",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({play: false}),
      }
      ).then(() => {
        setIsPlayingZone(false)
      })
    }
  }
  const onClickPlayButton = () => {
    if(!isPlayingZone){
      fetch(baseURL + "/bx_block_cfmultidisplaymanager/zones/update_zone_play_pause", {
        method: "PUT",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({play: true}),
      }
      ).then(() => {
        setIsPlayingZone(true)
      })
    }
  }
  const onClickRefreshButton = () => {
    setIsRefreshing(true);
    fetch(baseURL + "/bx_block_cfmultidisplaymanager/zones/refresh_zones", {
      method: "POST",
      headers: { "Content-Type": "application/json"},
    }
    ).then(() => {
      setIsRefreshing(false);
    })
  }
  const theme = createTheme({
    palette: {
      text: {
        primary: "#000",
        secondary: "#fff",
      },
      primary: {
        main: "#000",
        contrastText: "#fff",
      },
      secondary: {
        main: "#fff",
        contrastText: "#000",
      },
    },
    typography: {
      h4: {
        fontWeight: "bold",
        color: "#e53600",
      },
      h5: {
        fontWeight: 600,
      },
      caption: {
          fontSize: 20,
          fontWeight: 600,
          color: '#000'
      },
      subtitle1: {
        fontWeight: 'normal',
        color: '#000'
      },
      subtitle2: {
          fontSize: 16,
          fontWeight: 'normal',
          color: '#808080'
      }
    },
  });

  const isMatchPath = (activeLink:string[]) => {
    return activeLink.includes(currentUrl);
  }
const  renderActionButtons = () => {
    return (
      <Box sx={{ ...webStyle.row,  }}>
        <Box
          onClick={onClickPlayButton}
          data-testid="play-button"
          sx={{ ...webStyle.actionButtonContainer, bgcolor: isPlayingZone && !isRefreshing ? "#fff" : 'transparent' }}
        >
          <PlayArrowRoundedIcon style={{ color: !isPlayingZone || isRefreshing ? "#fff" : '#303320' }} />
        </Box>
        <Box
          onClick={onClickPauseButton}
          data-testid="pause-button"
          sx={{ ...webStyle.actionButtonContainer, bgcolor: !isPlayingZone && !isRefreshing ? "#fff" : 'transparent' }}
        >
          <PauseIcon style={{ color: isPlayingZone || isRefreshing ? "#fff" : '#303320' }} />
        </Box>
        <Box
          onClick={onClickRefreshButton}
          data-testid="refresh-button"
          sx={{ ...webStyle.actionButtonContainer, bgcolor: isRefreshing ? '#fff' : 'transparent' }}
        >
          <ReplayIcon style={{ color: !isRefreshing ? "#fff" : "#303320" }} />
        </Box>
      </Box>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={webStyle.mainWrapper}
        width={220}
        display="flex"
        flexDirection="column"
      >
        <Box onClick={goBack} sx={webStyle.buttonBack}>
          <ArrowBackIcon/>
        </Box>
        <Box ml={2} mt={1}>
          <img
            width={100}
            height={100}
            style={{ objectFit: "contain" }}
            src={require("./unified_logo.png")}
            alt="logo"
          />
        </Box>
        {listTab?.map(
          (
            item: { name: string; link: string; activeLink: string[] },
            index: number
          ) => {
            return (
              <Link style={webStyle.link} to={item.link} key={item.link}>
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  sx={webStyle.tabItem}
                >
                  {isMatchPath(item.activeLink) && (
                    <Box sx={webStyle.leftSelectedItem} />
                  )}
                  <Box display="flex" ml={1.4} flex={1}>
                    <Typography>{item.name}</Typography>
                  </Box>
                  {isMatchPath(item.activeLink) && (
                    <Box sx={webStyle.rightSelectedItem} />
                  )}
                </Box>
              </Link>
            );
          }
        )}
        {renderActionButtons()}
      </Box>
    </ThemeProvider>
  );
};

const webStyle = {
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  actionButtonContainer: {
    borderRadius: 17,
    border: '1px solid #fff',
    alignContent: 'center',
    padding: 10,
    marginLeft: 5,
    "&:hover": {
      cursor: "pointer",
    },
    marginRight: 7
  },
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    background: "#303320",
    overflow: "hidden",
  },
  buttonBack: {
    position: "absolute",
    top: 25,
    left: 170,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 45,
    height: 45,
    borderRadius: 17,
    border: "1px solid #313320",
    background: '#fff',
    zIndex: 1000,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tabItem: {
    height: 60,
    alignItems: "center",
    color: "#fff",
    fontSize: 16,
    marginLeft: 5,
    paddingRight: 12,
    marginTop: 3,
    marginBottom: 3,
    fontWeight: 400,
  },
  leftSelectedItem: {
    width: 4,
    background: "#fff",
    height: 36,
    borderRadius: 10,
  },
  rightSelectedItem: {
    transform: "rotate(45deg)",
    width: 8,
    height: 8,
    background: "#fff",
  },
  link: {
    textDecoration: "none",
  }
};
export default withRouter(DrawerMenu);
