// Customizable Area Start
import React from "react";

import { Box, Typography } from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CommonButton from "../../../components/src/CommonButton";
import CommonSearchBox from "../../../components/src/CommonSearchBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { imgTemplate } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    },
    secondary: {
      main: "#eb7c58"
    }
  }
});

import CategoriessubcategoriesController, {
  Props
} from "./CategoriessubcategoriesController";
import CommonModalDelete from "../../../components/src/CommonModalDelete";

class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
  }

  renderTemplateItem(item: any) {
    const dataToSend = { id: item.id };
    const queryString = encodeURIComponent(JSON.stringify(dataToSend));

    return (
      <Box
        data-test-id="template-item"
        key={item.id}
        sx={webStyle.templateItemContainer}>
        <Box sx={webStyle.itemTemplateSide}>
          <Box sx={webStyle.imageTempate}>
            <img src={imgTemplate} width={24} height={24} />
          </Box>
          <Typography style={webStyle.textItemTemplate}>
            {item.attributes.title}
          </Typography>
        </Box>
        <Box sx={webStyle.itemTemplateSide}>
          <DeleteOutlineIcon
            data-test-id="delete-template"
            style={webStyle.icon}
            onClick={() => this.handleShowDeleteModal(item.id, item.attributes.is_used)}
          />
          <CommonButton
            height={46}
            width={104}
            mode="secondary"
            label="Edit"
            destination={`/Createcategories?data=${queryString}`}
          />
        </Box>
      </Box>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CommonModalDelete
          message= {this.state.isTemplateUsed ? "This template is already in use. Are you sure you want to delete this template?" : "Are you sure you want to delete this template?"}
          handlePositiveButton={this.deleteTemplate}
          handleNegativeButton={this.handleCloseModal}
          showDeleteModal={this.state.showDeletePopup}
          negativeButtonTitle={'Cancel'}
          showNegativeButton={true}
          positiveButtonTitle={'OK'}
          showPositiveButton={true}
          handleCloseModal = {this.handleCloseModal}
        />
        <Box width="100%" sx={{ overflow: "scroll" }}>
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.headerContainer}>
              <Typography style={webStyle.textHeader}>
                Template Mananger
              </Typography>
              <CommonSearchBox
                data-testid="search"
                style={{ marginRight: 10 ,height: 42,
                  fontSize: 16,
                  paddingLeft: 10,
                  paddingRight: 10,borderRadius: 10,
                  border: "1px solid #CBD5E1",
                  background: "#fff",}}
                onChange={e => {
                  this.handleSearch(e.target.value);
                }}
              />
              <CommonButton
                mode="add"
                style={{ display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 12,
                background: "#e53600",
                padding: 10,
                paddingLeft: 15,
                paddingRight: 15,
                fontSize: 16,
                color: "#fff",
                }}
                label="Create Template"
                destination={"/Createcategories"}
              />
            </Box>
            <Typography style={webStyle.textContentHeader}>
              Templates Library
            </Typography>
            <Box
              data-test-id="template-list"
              sx={webStyle.contentContainer}>
              {this.state.templateList?.map((item: any) =>
                this.renderTemplateItem(item)
              )}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default Categoriessubcategories

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#faf9f6",
    paddingTop: 14,
    paddingLeft: 30,
    paddingRight: 30
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  textHeader: {
    fontSize: 20,
    fontWeight: 700,
    color: "#E73700",
    display: "flex",
    flex: 1
  },
  textContentHeader: {
    fontSize: 16,
    fontWeight: 700,
    color: "#000",
    marginTop: 40
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 34,
    flex: 1
  },
  templateItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    borderRadius: 8,
    background: "#fff",
    marginBottom: 16,
    flex: 1,
    height: 88
  },
  imageTempate: {
    padding: 16,
    borderRadius: 8,
    background: "#FFEAE3"
  },
  textItemTemplate: {
    color: "#313320",
    fontSize: 17,
    fontWeight: 700,
    marginLeft: 16
  },
  itemTemplateSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  icon: {
    width: 24,
    height: 24,
    color: "#939393",
    marginRight: 22
  }
};
// Customizable Area End
