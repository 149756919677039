import React, { ChangeEvent } from "react";
import Typography from "@material-ui/core/Typography";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

interface CommonButtonProps extends BoxProps {
  label: string;
  mode: "add" | "secondary" | "simple" | "transparent" | "primary-rounded" | "rounded";
  destination?: string;
  renderRightIcon?: any;
  onClickButton?: () => void;
  testId?:string
}

const CommonButton: React.FC<CommonButtonProps> = ({
  label,
  mode,
  destination,
  onClickButton,
  renderRightIcon,
  testId,
  ...props
}) => {
  const getStyleButton = () => {
    if (mode === "secondary") {
      return webStyle.secondaryButton;
    }
    if (mode === "transparent") {
      return webStyle.transparentButton;
    }
    if (mode === "rounded") {
      return webStyle.roundedButton;
    }
    if (mode === "primary-rounded") {
      return webStyle.primaryRoundedButton;
    }
    return webStyle.primaryButton;
  };
  const buttonStyle = { ...webStyle.addVideoButton, ...getStyleButton() };
  return destination ? (
    <Link data-test-id={testId ?? "button"} style={webStyle.link} to={destination}>
      <Box {...props} sx={buttonStyle}>
        <Typography style={webStyle.labelButton}>{label}</Typography>
        {mode === "add" && <AddIcon fontSize="small" />}
      </Box>
    </Link>
  ) : (
    <Box data-test-id={testId ?? "button"} {...props} onClick={onClickButton} sx={buttonStyle}>
      <Typography style={webStyle.labelButton}>{label}</Typography>
      {mode === "add" && <AddIcon fontSize="small" />}
      {!!renderRightIcon && renderRightIcon}
    </Box>
  );
};
const webStyle = {
  link: {
    textDecoration: "none",
  },
  labelButton: {
    fontSize: 16,
    fontWeight: 400
  },
  addVideoButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  primaryButton: {
    background: "#e53600",
    color: "#fff",
  },
  primaryRoundedButton: {
    background: "#fff",
    border: "0.7px solid #e53600",
    color: "#e53600",
  },
  roundedButton: {
    background: "#FBFAF7",
    border: "1px solid #747474",
    color: "#747474",
  },
  secondaryButton: {
    background: "#EEECE7",
    color: "#313320",
  },
  transparentButton: {
    background: "transparent",
    color: "#747474",
  }
};

export default CommonButton;
