import React, { ChangeEvent } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";

interface CommonSearchBoxProps extends BoxProps {
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CommonSearchBox: React.FC<CommonSearchBoxProps> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <Box {...props} sx={webStyle.inputContainer}>
      <img src={require("./search.png")} width={24} height={24} />
      <InputBase
        placeholder="Search"
        value={value}
        onChange={onChange}
        style={webStyle.input}
      />
    </Box>
  );
};
const webStyle = {
  inputContainer: {
    borderRadius: 17,
    border: "1px solid #CBD5E1",
    background: "#fff",
    height: 56,
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 365,
  },
  input: {
    width: "100%",
    marginLeft: 10,
  },
};

export default CommonSearchBox;
