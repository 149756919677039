import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Zone, Workout } from "./types";
import { exerciseData } from "./assets";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  currentMonth: any;
  openModal: any;
  zoneSelected: number;
  dayBattleSelected: any;
  battleOfDaySelected: any[];
  listScheduleSlot: any;
  listTemplate: any;
  modalLoading: boolean;
  selectedSchedule: any;
  headerData: any;
  dayRemaining: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  getTemplatesApiCallId: string = "";
  dashboardApiCallId: string = "";
  remainingDayApiCallId: string = "";
  getWorkoutId: string = "";
  listZone: Zone[] = [
    { id: 1, text: "Zone 1" },
    { id: 2, text: "Zone 2" },
    { id: 3, text: "Zone 3" }
  ];
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: true,
      currentMonth: moment(),
      openModal: null,
      zoneSelected: 1,
      dayBattleSelected: moment(),
      battleOfDaySelected: exerciseData,
      listScheduleSlot:[],
      listTemplate: [],
      modalLoading: false,
      selectedSchedule: null,
      headerData: [
        { id: 1, text: "Today's Workout", icon: require("../assets/image_today.png"), data: null },
        { id: 2, text: "Tomorrow's Workout",icon: require("../assets/image_tomorrow.png"), data: null },
        { id: 3, text: "_ days remaining",icon: require("../assets/image_schedule.png"), data: null, hideEvent: true }
      ],
      dayRemaining: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getSchedule()
    this.getTemplate()
    this.getRemainingDay()
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
   
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        if (responseJson.data || responseJson.remaining_days) {
          this.handleMessageHaveData(apiRequestCallId, responseJson)
        }else{
          this.handleMessageNotHaveData(apiRequestCallId, responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getWorkout = (workoutId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.getWorkoutId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWorkoutAPIEndPoint + `/${workoutId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSchedule = () => {
    const currentMonth = this.state.currentMonth;

    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const startDate = startOfMonth.startOf("week");
    const endDate = endOfMonth.endOf("week");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.apiDashboardItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleListAPIEndPoint + `?start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTemplate = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.getTemplatesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.templateListAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRemainingDay = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.remainingDayApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRemainingDayEndPoint}?date=${moment().format('YYYY-MM-DD')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  foundTodayWorkout = () => {
    const foundSchedule = this.state.listScheduleSlot.find((scheduleItem: any) => {
      return moment().isSame(scheduleItem.attributes.schedule_date,'day') && scheduleItem.attributes.video_template_id;
    });
    if(foundSchedule){
      let newHeaderData = [...this.state.headerData]
      newHeaderData[0].data = foundSchedule
      this.setState({
        headerData: newHeaderData
      })
    }
  }
  foundTomorrowWorkout = () => {
    const tomorrowDate = moment().add(1, 'day')
    const foundSchedule = this.state.listScheduleSlot.find((scheduleItem: any) => {
      return tomorrowDate.isSame(scheduleItem.attributes.schedule_date,'day') && scheduleItem.attributes.video_template_id;
    });
    if(foundSchedule){
      let newHeaderData = [...this.state.headerData]
      newHeaderData[1].data = foundSchedule
      this.setState({
        headerData: newHeaderData
      })
    }
  }
  foundSelectedScheduleByDate = () => {
    const foundSchedule = this.state.listScheduleSlot.find((scheduleItem: any) => {
      return this.state.dayBattleSelected.isSame(scheduleItem.attributes.schedule_date, 'day') && scheduleItem.attributes.video_template_id;
    });
    if(foundSchedule){
      this.setState({
        selectedSchedule: foundSchedule,
      })
    }
  }
  findTemplate = (newListTemplate: any, scheduleSlot: any )=> {
    return newListTemplate.find((templateItem: any, index: any) => {
      if(templateItem.id == scheduleSlot.attributes.video_template_id){
        return true;

      }
      return false
    });
  }
  getDataTemplate = (scheduleSlot: any, templateFound: any) => {
    return {
      ...scheduleSlot,
      templateName: templateFound?.workout_name,
      workout_image_url: templateFound?.workout_image_url ?? "",
      workout_name: templateFound?.workout_name ?? ""
    }
  }
  renderDataSchedule = () => {
    if(this.state.listScheduleSlot.length > 0 && this.state.listTemplate.length > 0) {
      console.log('this.state.listScheduleSlot',this.state.listScheduleSlot)
      console.log('this.state.listTemplate',this.state.listTemplate)
      let newListScheduleSlot = [...this.state.listScheduleSlot];
      const newListTemplate = [...this.state.listTemplate];
      newListScheduleSlot = newListScheduleSlot.map(scheduleSlot => {
        console.log('scheduleSlot',scheduleSlot)
        if(scheduleSlot.attributes.video_template_id !== null){
          const templateFound = this.findTemplate(newListTemplate, scheduleSlot)
          return this.getDataTemplate(scheduleSlot, templateFound)
        }else{
          return scheduleSlot;
        }
      })
      this.setState({
        listScheduleSlot: newListScheduleSlot,
      }, () => {
        this.foundSelectedScheduleByDate()
        this.foundTodayWorkout()
        this.foundTomorrowWorkout()

      })
      
    }
  }
  handleDataWorkoutItem = (listItemWorkoutData: any, zoneNumber: any, stationItem: any) => {
    const setData = stationItem.sets;
    setData.forEach((setItem: any, setItemIndex: number) => {
      if (
        listItemWorkoutData[zoneNumber - 1][setItemIndex] && listItemWorkoutData[zoneNumber - 1][setItemIndex].length > 0
        ) {
          listItemWorkoutData[zoneNumber - 1][setItemIndex].push(setData[setItemIndex]);
        } else {
        
        listItemWorkoutData[zoneNumber - 1][setItemIndex] = [];
        listItemWorkoutData[zoneNumber - 1][setItemIndex].push(setData[setItemIndex]);
      }
    });
  }
  handleDataWorkout = (listStationData: any) => {
    let listDataWorkout: any[][] = [[],[],[]];
    if (listStationData && listStationData.length > 0) {
      listStationData.forEach((stationItem: any) => {
        const zoneId = stationItem.zone_id;
        if (typeof zoneId === "number") {
          this.handleDataWorkoutItem(listDataWorkout, zoneId, stationItem);
        }
      });
    }
    this.setState({
      modalLoading: false,
      battleOfDaySelected: listDataWorkout,
    });
  }
  handleMessageHaveData = (apiRequestCallId : any, responseJson : any) => {
    if (this.apiDashboardItemCallId === apiRequestCallId) {
      this.setState({
        listScheduleSlot: responseJson.data,
        loading: false,
      }, () => {
        this.renderDataSchedule()
      });
    }
    if(this.getWorkoutId === apiRequestCallId) {
      const listDataStation = responseJson.data?.attributes?.formatted_station_data
      this.handleDataWorkout(listDataStation)
    }
    if(this.remainingDayApiCallId === apiRequestCallId) {
      this.setState({
        dayRemaining: responseJson.remaining_days
      });
    }
  }
  filterWrongTemplates = (listTemplate: any[]) : any[] => {
    return listTemplate.filter(template => {
      return template.title !== null
    })
  }
  handleMessageNotHaveData = (apiRequestCallId : any, responseJson : any) => {

    if (this.getTemplatesApiCallId === apiRequestCallId) {
      this.setState({
        listTemplate: this.filterWrongTemplates(responseJson),
      }, () => {
        this.renderDataSchedule()
      });
    }
    if(responseJson.errors){
      this.setState({modalLoading: false})
    }
  }
  handleCloseModal = () => {
    this.setState({
      openModal: null
    });
  };
  handleShowModalWorkout = (cardData: any) => {
    if(cardData.id === 3) {
      this.setState({
        currentMonth: moment().clone().add(this.state.dayRemaining, 'days'),
        loading: true,  listTemplate: [], listScheduleSlot: []
      }, () => {
        this.getSchedule()
        this.getTemplate()
      });
      return;
    }
    if(cardData.data) {
      this.setState({
        openModal: cardData,
        modalLoading: true
      });
      this.getWorkout(cardData.data.attributes.video_template_id);
      return;
    }
  };
  handlePrevMonth = () => {
    this.setState(
      {
        currentMonth: this.state.currentMonth.clone().subtract(1, "month"),
        loading: true,
        listScheduleSlot: [],
        listTemplate: [],
      },
      () => {
        this.getSchedule();
        this.getTemplate()
      }
    );
  };

  handleNextMonth = () => {
    this.setState(
      {
        currentMonth: this.state.currentMonth.clone().add(1, "month"),
        loading: true,
        listScheduleSlot: [],
        listTemplate: [],
      },
      () => {
        this.getSchedule();
        this.getTemplate()
      }
    );
  };
  handleChangeDayBattle = (dayBattleSelected: any) => {
    this.setState({
      dayBattleSelected: dayBattleSelected
    }, () => {
      this.foundSelectedScheduleByDate()
    });
  };
  // Customizable Area End

}
