// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { exerciseData } from "../../dashboard/src/assets";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  openModal: boolean;
  zoneList: any[];
  zoneDetail: any;
}

interface SS {
}

export default class Cfmultidisplaymanager2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  showAllZonesApiCallId: any;
  showZoneDetailApiCallId: any;

  battleOfDaySelected = exerciseData
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      openModal: false,
      zoneList: [],
      zoneDetail: {},
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.showAllZonesApi()
  }

  handleCloseModal = () => {
    this.setState({ openModal: false });
  }
  handleOpenModal = () => {
    this.setState({ openModal: true });
  }

  async receive(from: string, message: Message) {
    const restApiResponseMessage = getName(MessageEnum.RestAPIResponceMessage);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
 
    runEngine.debugLog("API Message Received", message);
 
    if (restApiResponseMessage === message.id) {
      if (responseJson.data) {
        if (apiRequestCallId === this.showAllZonesApiCallId) {
          this.setState({zoneList:responseJson.data})
        } else if (apiRequestCallId === this.showZoneDetailApiCallId) {
          this.setState({zoneDetail:responseJson.data}, ()=> {
            console.log("zoneDetail==>", this.state.zoneDetail, "==>", responseJson.data)
            this.handleOpenModal()
          })
        }
      }else if (responseJson.error) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  showAllZonesApi = () => {
    const { validationApiContentType, multiscreenEndpoint, validationApiMethodType } = configJSON;
    const header = {
      "Content-Type": validationApiContentType
    };
 
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.showAllZonesApiCallId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      multiscreenEndpoint
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), validationApiMethodType);
 
    runEngine.sendMessage(requestMessage.id, requestMessage);
 
    return true;
  }

  showZoneDetailApi = (zone_id: number) => {
    const { validationApiContentType, multiscreenEndpoint, validationApiMethodType } = configJSON;
    const header = {
      "Content-Type": validationApiContentType
    };
 
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.showZoneDetailApiCallId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${multiscreenEndpoint}/${zone_id}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), validationApiMethodType);
 
    runEngine.sendMessage(requestMessage.id, requestMessage);
 
    return true;
  }

}
// Customizable Area End
