export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgFilter = require("../assets/iconoir_filter.png");
export const imgSearch = require("../assets/search.png");
export const imgSchedule = require("../assets/image_schedule.png");
export const imgLevel = require("../assets/image_level.png");
export const imgEdit = require("../assets/edit.png");
export const imgSort = require("../assets/sort.png");
export const videoWorkout = require("../assets/workout-video.mp4");

export const filterList = ["Date Created", "A-Z", "Z-A"]