// Customizable Area Start
import React from "react";

import { Box, Typography, Grid, RootRef } from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CommonInputText from "../../../components/src/CommonInputText";
import CommonSelection from "../../../components/src/CommonSelection";
import CommonButton from "../../../components/src/CommonButton";
import CommonAddTags from "../../../components/src/CommonAddTags";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineRounded from "@material-ui/icons/DeleteOutlineRounded";
import { imgRightDouble, listStation, listSetNumber } from "./assets";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    },
    secondary: {
      main: "#eb7c58"
    }
  }
});

import CreateCategoriesController, {
  Props
} from "./CreateCategoriesController";

export default class CreateCategories extends CreateCategoriesController {
  constructor(props: Props) {
    super(props);
  }

  renderHeader() {
    return (
      <Box sx={webStyle.headerContainer}>
        <Typography style={webStyle.textPreRouteHeader}>
          Template Mananger
        </Typography>
        <img src={imgRightDouble} style={webStyle.iconRightHeader} />
        <Typography data-test-id="text-header" style={webStyle.textHeader}>
          {this.state.template_id === "" ? "Create Template" : "Edit Template"}
        </Typography>
      </Box>
    );
  }
   /* istanbul ignore next */
  renderSetItem = (setItem: any, index: number) => {
    const handleChangeSet = (set_number: any) => {
      this.handleChangeSetNumber(set_number, index);
    };
    return (
      <Box
        data-test-id="set-item"
        key={index}
        sx={{
          ...webStyle.setContainer,
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
          justifyContent: "space-between",
          width: index === 0 ? "96%" : "100%"
        }}
      >
        <CommonSelection
          data-test-id="selection-set-number"
          selectionHeight={98}
          value={setItem.set_number}
          onChangeItem={handleChangeSet}
          data={listSetNumber}
          sx={{ ...webStyle.setSelectItem, marginTop: 6 }}
          label=""
        />
        <CommonInputText
          data-test-id="set-time"
          value={setItem.set_time}
          sx={webStyle.setItem}
          timeInput
          label=""
          onChange={e => {
            const value = e.target.value.replace(this.getFormatValue(setItem.set_time), '')
            this.handleSetDataChange(index, "set_time", value)
          }}
          keyValue={`set_time[${index}]`}
          handleUnitSelection={this.handleUnitSelection}
        />
        <CommonInputText
          data-test-id="rest-time"
          value={setItem.rest_time}
          sx={webStyle.setItem}
          timeInput
          label=""
          onChange={e => {
            const value = e.target.value.replace(this.getFormatValue(setItem.rest_time), '')
            this.handleSetDataChange(index, "rest_time", value)
          }}
          keyValue={`rest_time[${index}]`}
          handleUnitSelection={this.handleUnitSelection}
        />
        {index !== 0 && (
          <DeleteOutlineRounded
            data-test-id="delete-set"
            style={webStyle.deleteIcon}
            onClick={() => this.handleDeleteSet(index)}
          />
        )}
      </Box>
    );
  };

  renderStationItem = (stationItem: string, index: number) => {
    const isActiveStation =
      parseInt(stationItem, 10) <=
      parseInt(this.state.templateData.station, 10);
    return (
      <Grid key={stationItem} item xs={4}>
        {isActiveStation ? (
          <Box sx={webStyle.stationItemMainContainer}>
            <Box sx={webStyle.stationItemDisableContainer}>
              <Typography style={webStyle.textStationItem}>
                {stationItem}
              </Typography>
            </Box>
            <Droppable key={index.toString()} droppableId={stationItem}>
              {(providedDrop: any) => (
                <>
                  <RootRef rootRef={providedDrop.innerRef}>
                    <Draggable
                      key={index.toString()}
                      draggableId={stationItem}
                      index={index}
                    >
                      {(providedDrag, snapshot) => (
                        <RootRef rootRef={providedDrag.innerRef}>
                          <Box
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                            sx={{
                              ...webStyle.stationItemContainer,
                              ...this.getItemStyle(
                                snapshot.isDragging,
                                providedDrag.draggableProps.style
                              )
                            }}
                          >
                            <Typography style={webStyle.textStationItem}>
                              {stationItem}
                            </Typography>
                          </Box>
                        </RootRef>
                      )}
                    </Draggable>
                  </RootRef>
                  <Box sx={webStyle.stationItemDrag}>
                    {providedDrop.placeholder}
                  </Box>
                </>
              )}
            </Droppable>
          </Box>
        ) : (
          <Box sx={webStyle.stationItemDisableContainer}>
            <Typography style={webStyle.textStationItem}>
              {stationItem}
            </Typography>
          </Box>
        )}
      </Grid>
    );
  };

  
  renderInputField = (key: any, label: string, timeInput = false) => {
    const valueOrigin = this.state.templateData.hasOwnProperty(key)
      ? this.state.templateData[key]
      : "";
    return (
      <CommonInputText
        key={key}
        mb="29px"
        label={label}
        width="95%"
        timeInput={timeInput}
        value={valueOrigin}
        keyValue={key}
        showError={key === 'title' && this.state.error_message !== ""}
        errorMessage={this.state.error_message}
        handleUnitSelection={this.handleUnitSelection}
        onChange={e => {
          const value = key === 'title' ? e.target.value : e.target.value.replace(this.getFormatValue(valueOrigin), '')
            ;
          this.handleUpdateState(key, value);
        }} />
    );
  };
  renderInputImage = () => {
    return (
      <Box>
        <Typography 
          style={webStyle.labelFileInput}
          >
            Thumbnail
            </Typography>
        <Box>
        {this.state.templateData.image_url && <img
          src={this.state.templateData.image_url}
          style={{ width: 160, height: 90, marginBottom: 20, objectFit: 'cover' }}
          />}
        <input
          style={{ width: "100%"}}
          data-test-id="thumbnail-image"
          type="file"  
          accept="image/png, image/jpeg"
          onChange={(e) => this.handleFileChange(e)}
          />
        </Box>
        
      </Box>)
  }

  renderFormData() {
    return (
      <Grid key="form-data" item xs={7}>
        {this.renderInputField("title", "Title")}
        <CommonAddTags
          mb="29px"
          label="Focus"
          width="95%"
          listTag={this.state.focus_list}
          handlePositiveClick={this.addFocusTemplateAPICall}
          handleDeleteATag={this.handleDeleteATag}
          openModal={this.state.openModal}
          handleOpenModal={this.handleOpenModal}
          handleCloseModal={this.handleCloseModal}
        />
        {this.renderInputField("introduction", "Introduction", true)}
        {this.renderInputField("demo", "Demo", true)}
        {this.renderInputField("demo_video_shift", "Demo interval", true)}
        {this.renderInputField("warmup", "Warm up", true)}
        {this.renderInputField("number_of_loops", "Number Of Laps")}
        <CommonSelection
          data-test-id="selection-station"
          selectionHeight={218}
          value={
            this.state.templateData.hasOwnProperty("station") &&
              this.state.templateData.station
              ? this.state.templateData.station
              : ""
          }
          onChangeItem={e => this.handleUpdateState("station", e.value)}
          data={listStation}
          mb="29px"
          label="Stations"
          width="95%"
        />
        <Box sx={{ ...webStyle.setContainer, marginBottom: 1, width: "96%" }}>
          <Typography style={{ ...webStyle.setSelectItem,
          fontSize: 16,
          color: "#313320",
          marginBottom: 8
           }}>
            Set Number
          </Typography>
          <Typography style={{ ...webStyle.setItem, 
          fontSize: 16,
          color: "#313320",
          marginBottom: 8
          }}>
            Set Time
          </Typography>
          <Typography style={{ ...webStyle.setItem, ...webStyle.label }}>
            Rest Time
          </Typography>
        </Box>
        {this.state.templateData.exercise_sets_attributes.length > 0 &&
          this.state.templateData.exercise_sets_attributes
            .filter((item: any) => !("_destroy" in item))
            .map(this.renderSetItem)}

        {this.state.templateData.exercise_sets_attributes.filter(
          (item: any) => !("_destroy" in item)
        ).length < 3 && (
            <Box
              data-test-id="add-button"
              sx={webStyle.buttonAddContainer}
              onClick={this.handleAddSets}
            >
              <AddCircleOutlineIcon />
              <Typography style={webStyle.textButtonAdd}>Add</Typography>
            </Box>
          )}
        <Box sx={{ marginTop: "29px" }} />
        {this.renderInputField("change_zone", "Change Zone", true)}
        {this.renderInputField("cooling_down", "Cooling down", true)}
        {this.renderInputImage()}
      </Grid>
    );
  }

  renderGridStation() {
    return (
      <Grid key="grid-station" item xs={5}>
        <Typography style={webStyle.textPreview}>Stations</Typography>
        <Box sx={webStyle.stationContainer}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Grid style={webStyle.stationGridContainer} container spacing={3}>
              {this.state.templateData.station_sequence.map(
                this.renderStationItem
              )}
            </Grid>
          </DragDropContext>
        </Box>
        <CommonInputText
          mt="50px"
          ml="50px"
          label={"Total Workout Time"}
          width="40%"
          value={this.state.totalWorkoutTime}
        />
        <Box sx={webStyle.buttonContainer}>
          <CommonButton
            data-test-id="cancel-button"
            height={44}
            width={119}
            mode="transparent"
            label="Cancel"
            onClickButton={this.goBack}
          />
          <CommonButton
            data-test-id="submit-button"
            height={44}
            width={119}
            mode="simple"
            renderRightIcon={<ArrowRightAltIcon />}
            label={this.state.template_id === "" ? "Create" : "Update"}
            onClickButton={() => {
              this.state.saveEnabled && this.addEditTemplateAPICall()
            }}
            style={this.state.saveEnabled ? {} : { backgroundColor: '#D9D9D9', color: '#747474' }}
          />
        </Box>
      </Grid>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box width="100%" sx={{ overflow: "scroll" }}>
          <Box sx={webStyle.mainWrapper}>
            {this.renderHeader()}
            <Grid style={webStyle.contentContainer} container spacing={6}>
              {this.renderFormData()}
              {this.renderGridStation()}
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  labelFileInput: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
    fontWeight: 400,
    marginTop: 15,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#faf9f6",
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  textPreRouteHeader: {
    fontSize: 20,
    fontWeight: 400,
    color: "#313320"
  },
  textHeader: {
    fontSize: 20,
    fontWeight: 700,
    color: "#E73700",
    display: "flex"
  },
  iconRightHeader: {
    width: 24,
    height: 24,
    marginLeft: 9,
    marginRight: 9
  },
  contentContainer: {
    marginTop: 44,
    marginRight: 15
  },
  setContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10
  },
  buttonAddContainer: {
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer"
    }
  },
  textButtonAdd: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    marginLeft: 9
  },
  setItem: {
    marginRight: 8,
    width: "40%"
  },
  setSelectItem: {
    marginRight: 8,
    width: "25%"
  },
  textPreview: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    marginTop: 15,
  },
  stationGridContainer: {
    marginTop: 36,
    width: "75%"
  },
  stationItemContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    right: 0,
    bottom: 0,
    height: 75,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    background: "#ebeae6",
    border: "0.7px solid #313320"
  },
  stationItemDrag: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    right: 0,
    bottom: 0,
    height: 75
  },
  stationItemMainContainer: {
    display: "flex",
    position: "relative",
    height: 75,
    width: "100%",
    borderRadius: 8,
    background: "rgba(49, 51, 32, 0.08)"
  },
  stationItemDisableContainer: {
    display: "flex",
    height: 75,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    background: "rgba(49, 51, 32, 0.08)",
    border: "0.7px dashed #313320",
    opacity: 0.6
  },
  textStationItem: {
    fontSize: 24.2,
    fontWeight: 500,
    color: "#313320"
  },
  buttonContainer: {
    marginTop: 346,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: 20
  },
  stationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8
  },
  deleteIcon: {
    color: "red",
    alignSelf: "center"
  }
};
// Customizable Area End
