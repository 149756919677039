import {
    Box,
    Typography,
    Grid,
} from "@material-ui/core";
import moment from "moment";
import React, { useRef, useEffect, useState, useImperativeHandle } from "react";
import VideoPlayerPreview from "./VideoPlayerPreview";
import BoilerPlate from "./BoilerPlate.web";
import '../assets/TradeGothicNextLTPro-Bd.otf'

interface ZoneSpecificVideos {
    [key: number]: { set1?: any; set2?: any; set3?: any };
}

export interface DisplayFlow {
    phase: string; // PREPARE, INTRO, DEMO, WARM_UP, GET_STARTED, SET_1, SET_2, SET_3, REST, CHANGE_ZONE, COOL_DOWN, END
    time?: any; // seconds
    currentZone?: number;
    currentLap?: number;
    currentSet?: number;
    id?: number;
    currentStation?: number;
}

interface Props {
    introductionVideo: any;
    warmupVideo: any;
    zoneData: ZoneSpecificVideos;
    laps: any;
    sets: any;
    coolingVideo: any;
    station: number;
    displayFlow: DisplayFlow[];
    selectedClassType?: any;
    onEnd?: any;
    isZoneDisplay?: boolean;
    currentSlot?: { hour?: number; minute?: number };
}

const TRANSITION_DURATION = 8; // seconds

const PreviewFlow = React.forwardRef((props: Props, ref) => {
    const {
        introductionVideo,
        warmupVideo,
        zoneData,
        laps,
        sets,
        coolingVideo,
        station,
        displayFlow,
        selectedClassType,
        onEnd = () => { },
        isZoneDisplay,
        currentSlot
    } = props;

    const initVideoSequence = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const [countDown, setCountDown] = useState(0);
    const [intervalId, setIntervalId] = useState<any>(null);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
    const [demoIndex, setDemoIndex] = useState(-1);
    const [demoData, setDemoData] = useState<{ station: number, set: number }[]>([]);
    const [audioNext] = useState(new Audio(require('../assets/tick.mp3')));
    const [audioEnd] = useState(new Audio(require('../assets/long-tick.mp3')));

    const introVideoRef: any = useRef(null);
    const warmupVideoRef: any = useRef(null);
    const coolDownVideoRef: any = useRef(null);
    const demoVideoRefs: any[] = Array.from({ length: 9 }, () => useRef(null));
    const demo2VideoRefs: any[] = Array.from({ length: 9 }, () => useRef(null));
    const set1VideoRefs: any[] = Array.from({ length: 9 }, () => useRef(null));
    const set2VideoRefs: any[] = Array.from({ length: 9 }, () => useRef(null));
    const set3VideoRefs: any[] = Array.from({ length: 9 }, () => useRef(null));


    useImperativeHandle(ref, () => ({ resume, pause, reset }));

    const pauseAllVideo = () => {
        if (introVideoRef && introVideoRef.current) {
            introVideoRef?.current?.pause();
        }
        if (warmupVideoRef && warmupVideoRef.current) {
            warmupVideoRef?.current?.pause();
        }
        if (coolDownVideoRef && coolDownVideoRef.current) {
            coolDownVideoRef?.current?.pause();
        }
        demoVideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
            }
        });
        demo2VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
            }
        });
        set1VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
            }
        });
        set2VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
            }
        });
        set3VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
            }
        });
    }
    const getCorrectTimeOfPhase = (phaseTime: any) => {
        if (typeof phaseTime === 'string') {
            if (phaseTime.includes("Minutes")) {
                const data = phaseTime.split(" ")
                return parseFloat(data[0]) * 60
            }
            if (phaseTime.includes("Seconds")) {
                const data = phaseTime.split(" ")
                return parseInt(data[0])
            }
        }
        return parseInt(phaseTime)
    }
    const resetAllVideo = () => {
        if (introVideoRef && introVideoRef.current) {
            introVideoRef?.current?.pause();
            introVideoRef.current.currentTime = 0;
        }
        if (warmupVideoRef && warmupVideoRef.current) {
            warmupVideoRef?.current?.pause();
            warmupVideoRef.current.currentTime = 0;
        }
        if (coolDownVideoRef && coolDownVideoRef.current) {
            coolDownVideoRef?.current?.pause();
            coolDownVideoRef.current.currentTime = 0;
        }
        demoVideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
                e.current.currentTime = 0;
            }
        });
        demo2VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
                e.current.currentTime = 0;
            }
        });
        set1VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
                e.current.currentTime = 0;
            }
        });
        set2VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
                e.current.currentTime = 0;
            }
        });
        set3VideoRefs.map(e => {
            if (e && e.current) {
                e?.current?.pause();
                e.current.currentTime = 0;
            }
        });
    }
    useEffect(() => {
        if (displayFlow[currentVideoIndex]?.phase === "PREPARE" && countDown <= TRANSITION_DURATION + 2) {
            resetAllVideo();
        }
        if (displayFlow[currentVideoIndex]?.phase === "DEMO") {
            const demoTime = displayFlow[currentVideoIndex]?.time;
            const demoVideoShift = selectedClassType?.attributes?.demo_video_shift && selectedClassType?.attributes?.demo_video_shift !== "" ? parseInt(selectedClassType?.attributes?.demo_video_shift) : 5;
            if ((demoTime - countDown - 1) % demoVideoShift === 0) {
                setDemoIndex(demoIndex + 1);
            }
        }

        // Play background video when "move to next station"
        if (displayFlow[currentVideoIndex]?.phase === "REST"
            && (displayFlow[currentVideoIndex + 1]?.phase === "SET_1" || displayFlow[currentVideoIndex + 1]?.phase === "SET_2" || displayFlow[currentVideoIndex + 1]?.phase === "SET_3")
            && countDown <= TRANSITION_DURATION) {
            resumeVideoAtIndex(currentVideoIndex + 1);
        }
    }, [countDown]);

    useEffect(() => {
        let restSeconds = 0
        if (currentSlot && typeof currentSlot?.hour !== 'undefined' && typeof currentSlot?.minute !== 'undefined') {
            restSeconds = calculateCurrentWorkoutTime(currentSlot)
        }
        if (countDown === 0) {
            if (displayFlow[currentVideoIndex]?.phase === "PREPARE" && restSeconds > 0 && isZoneDisplay) {
                let indexForPhase = 0
                for (let index = 1; index < displayFlow.length; index++) {
                    const displayPhase = displayFlow[index];
                    const currentPhaseTime = getCorrectTimeOfPhase(displayPhase?.time)
                    if(restSeconds > currentPhaseTime) {
                        restSeconds = restSeconds - currentPhaseTime
                    }else{
                        indexForPhase = index
                        break; 
                    }
                }
                setCountDown((getCorrectTimeOfPhase(displayFlow[indexForPhase]?.time) || 0) - restSeconds)
                setCurrentVideoIndex(indexForPhase)
            } else {
                setCurrentVideoIndex(prevIndex => {
                    if (prevIndex < 0 && !isZoneDisplay) {
                        setCountDown(30)
                    }
                    return prevIndex + 1
                })
            }
        }
    }, [countDown, currentSlot])

    useEffect(() => {
        if (currentVideoIndex < 0) return;

        setCountDown((prevCountdown: number) => {
            if(prevCountdown > 2){
                return prevCountdown
            }else{
               return getCorrectTimeOfPhase(displayFlow[currentVideoIndex]?.time) || 0
            }
        });
        resetAllVideo();
        resumeVideoAtIndex(currentVideoIndex);
    }, [currentVideoIndex]);
    useEffect(() => {
        // const videoSequence = selectedClassType?.attributes?.station_sequence || initVideoSequence;
        const videoSequence = initVideoSequence;
        const { sets, station } = props;
        if (station <= 3) {
            for (let i = 1; i <= station; i++) {
                for (let j = 0; j < sets; j++) {
                    demoData.push({
                        station: i,
                        set: j + 1,
                    });
                }
            }
            setDemoData([...demoData]);
        } else {
            for (let i = 0; i < 9; i++) {
                if (parseInt(videoSequence[i]) <= station) demoData.push({
                    station: parseInt(videoSequence[i]),
                    set: 1,
                });
            }
            setDemoData([...demoData]);
        }

    }, [props.zoneData]);

    const calculateCurrentWorkoutTime = (curr: { hour?: number; minute?: number }) => {
        const currentTime = moment()
        const currentHour = parseInt(currentTime.format('HH'))
        const currentMinute = parseInt(currentTime.format('mm'))
        const currentSecond = parseInt(currentTime.format('ss'))
        const workoutTime = (curr && typeof curr?.hour !== 'undefined' && typeof curr?.minute !== 'undefined') ? 
            ((currentHour - curr?.hour) * 3600 + (currentMinute - curr?.minute) * 60 + currentSecond) : 0
        return workoutTime
    }

    const isShowRedBox = (station: number, set: number) => {
        if (displayFlow[currentVideoIndex]?.phase !== "DEMO") return false;
        const maxDemoIndex = demoData?.length;
        if (demoData?.[demoIndex % maxDemoIndex]?.station === station && demoData?.[demoIndex % maxDemoIndex]?.set === set) {
            return true;
        }
        return false;
    }

    interface DemoItem {
        station?: number, set?: number, data?: { set1: any, set2: any, set3: any }
    }
    const getDemoData: () => DemoItem[] = () => {
        const { sets, station } = props;
        if (station > 3) return [];

        const stationArr = Array.from({ length: station }, (_, index) => index + 1).reverse();
        // const stationArr = selectedClassType?.attributes?.station_sequence.filter((e: string) => parseInt(e) <= station) || [];
        const demoArr = stationArr.map((e: any) => {
            let set1Data = zoneData[e]?.set1 || {};
            let set2Data = zoneData[e]?.set2 || {};
            let set3Data = zoneData[e]?.set3 || {};
            return {
                station: parseInt(e),
                set: sets,
                data: {
                    set1: set1Data,
                    set2: set2Data,
                    set3: set3Data,
                }
            }
        })
        return demoArr;
    }



    const playAllVideo = async () => {
        introVideoRef?.current?.play();
        warmupVideoRef?.current?.play();
        demoVideoRefs.map(e => e?.current?.play());
        demo2VideoRefs.map(e => e?.current?.play());
        set1VideoRefs.map(e => e?.current?.play());
        set2VideoRefs.map(e => e?.current?.play());
        set3VideoRefs.map(e => e?.current?.play());
    }

    const resumeVideoAtIndex = (index: number) => {
        if (index < 0) return;
        if (displayFlow[index]?.phase === "END") {
            clearInterval(intervalId);
            setIntervalId(null);
            setCurrentVideoIndex(-1);
            onEnd();
        }
        if (displayFlow[index]?.phase === "PREPARE") {
            playAllVideo();
        }
        if (displayFlow[index]?.phase === "INTRO" && introVideoRef && introVideoRef.current) {
            introVideoRef?.current?.play();
        }
        if (displayFlow[index]?.phase === "DEMO") {
            if (station > 3) {
                demo2VideoRefs.map(e => {
                    if (e && e.current) {
                        e?.current?.play();
                    }
                });
            } else {
                demoVideoRefs.map(e => {
                    if (e && e.current) {
                        e?.current?.play();
                    }
                });
            }
        }
        if (displayFlow[index]?.phase === "WARM_UP" && warmupVideoRef && warmupVideoRef.current) {
            warmupVideoRef?.current?.play();
        }
        if (displayFlow[index]?.phase === "COOL_DOWN" && coolDownVideoRef && coolDownVideoRef.current) {
            coolDownVideoRef?.current?.play();
        }
        if (displayFlow[index]?.phase === "SET_1") {
            set1VideoRefs.map(e => {
                if (e && e.current) {
                    e?.current?.play();
                }
            });
        }
        if (displayFlow[index]?.phase === "SET_2") {
            set2VideoRefs.map(e => {
                if (e && e.current) {
                    e?.current?.play();
                }
            });
        }
        if (displayFlow[index]?.phase === "SET_3") {
            set3VideoRefs.map(e => {
                if (e && e.current) {
                    e?.current?.play();
                }
            });
        }
    }

    const resume = async () => {
        if (intervalId) return;
        resumeVideoAtIndex(currentVideoIndex);
        const internalCountDown = setInterval(() => {
            setCountDown((prevCountDown) => {
                if (prevCountDown > 0) {
                    if (prevCountDown <= 4) {
                        if (prevCountDown === 1) {
                            audioEnd?.play()
                        } else {
                            audioNext?.play()
                        }

                    }
                    return prevCountDown - 1;
                } 
                return prevCountDown
            });
        }, 1000);
        setIntervalId(internalCountDown);
    }


    const pause = () => {
        clearInterval(intervalId);
        setIntervalId(null);
        pauseAllVideo();
    }

    const reset = () => {
        resetAllVideo();
        setCurrentVideoIndex(-1);
        setCountDown(0);
        clearInterval(intervalId);
        setIntervalId(null);
    }

    const getPhaseLabel = () => {

        const currentPhase = displayFlow[currentVideoIndex]?.phase || "";

        const lowerCaseString = currentPhase.toLowerCase();
        const words = lowerCaseString.split('_');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const resultString = capitalizedWords.join(' ');

        return resultString;
    }

    const getProgressCountDown = () => {
        if (displayFlow[currentVideoIndex]?.time) {
            return countDown / (getCorrectTimeOfPhase(displayFlow[currentVideoIndex]?.time) || 1) * 100;
        }
        return 0;
    }

    const getGridDimension = () => {
        const headerHeight = 85;
        const gridParentWidth = window.innerWidth - 20;
        const gridParentHeight = window.innerHeight - headerHeight - 20;
        const isVertical = gridParentWidth < gridParentHeight;

        let numberOfColumns = 3;
        let numberOfRows = 3;

        if (station === 1) {
            numberOfColumns = 1;
            numberOfRows = 1;
        }
        if (station === 2) {
            numberOfColumns = 2;
            numberOfRows = 1;
        }
        if (station === 3) {
            if (isVertical) {
                numberOfColumns = 2;
                numberOfRows = 2;
            } else {
                numberOfColumns = 3;
                numberOfRows = 1;
            }
        }
        if (station === 4) {
            numberOfColumns = 2;
            numberOfRows = 2;
        }
        if (station === 5) {
            if (isVertical) {
                numberOfColumns = 2;
                numberOfRows = 3;
            } else {
                numberOfColumns = 3;
                numberOfRows = 2;
            }
        }
        if (station === 6) {
            if (isVertical) {
                numberOfColumns = 2;
                numberOfRows = 3;
            } else {
                numberOfColumns = 3;
                numberOfRows = 2;
            }
        }
        if (station === 7) {
            if (isVertical) {
                numberOfColumns = 2;
                numberOfRows = 4;
            } else {
                numberOfColumns = 3;
                numberOfRows = 3;
            }
        }
        if (station === 8) {
            if (isVertical) {
                numberOfColumns = 2;
                numberOfRows = 4;
            } else {
                numberOfColumns = 3;
                numberOfRows = 3;
            }
        }
        if (station === 9) {
            if (isVertical) {
                numberOfColumns = 2;
                numberOfRows = 5;
            } else {
                numberOfColumns = 3;
                numberOfRows = 3;
            }
        }

        const gridWrapperRatio = (16 * numberOfColumns) / (9 * numberOfRows);

        if (gridParentWidth / gridParentHeight > gridWrapperRatio) {
            return {
                gridWrapperWidth: gridParentHeight * gridWrapperRatio,
                gridItemWidth: (gridParentHeight * gridWrapperRatio) / numberOfColumns,
            };
        } else {
            return {
                gridWrapperWidth: gridParentWidth,
                gridItemWidth: gridParentWidth / numberOfColumns,
            };
        }
    };

    const getDemoVideoDimension = () => {
        const headerHeight = 155;
        const gridParentWidth = window.innerWidth;
        const gridParentHeight = window.innerHeight - headerHeight;

        const { station, sets } = props;

        const maxItemWidth = gridParentWidth / station;
        const maxItemHeight = gridParentHeight / sets;

        if (maxItemWidth / maxItemHeight > 16 / 9) {
            return {
                width: "auto",
                height: "100%",
            }
        } else {
            return {
                width: "100%",
                height: "auto",
            }
        }
    }

    const renderNumberBoxSelected = (label: string, length: any, selected: any) => {
        return (
            <Box
                style={webStyle.numberBoxSelectedContainer}
            >
                <Typography style={webStyle.numberBoxSelectedText}>{label}</Typography>
                {Array.from({ length }).map((item, index) => (
                    <Box
                        style={{
                            width: 30,
                            height: 35,
                            border: selected === index + 1 ? "1px solid #fff" : undefined,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 3,
                        }}
                    >
                        <Typography
                            style={{
                                color: index + 1 < selected ? "gray" : "#fff",
                                fontSize: 20,
                                fontWeight: index + 1 < selected ? undefined : "bold"
                            }}>
                            {index + 1}
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    };

    const isShowCurrentSetAndLap = () => {
        if (displayFlow[currentVideoIndex]?.phase.includes("SET") || displayFlow[currentVideoIndex]?.phase === "REST") {
            return true;
        }
        return false;
    }

    const isGreenBackground = () => {
        return ['INTRO', 'DEMO', 'WARM_UP', 'SET_1', 'SET_2', 'SET_3', 'COOL_DOWN', 'CHANGE_ZONE'].includes(displayFlow[currentVideoIndex]?.phase);
    }


    const getRestText = () => {
        displayFlow[currentVideoIndex + 1]?.phase === "SET_1" ? "MOVE TO NEXT STATION" : "STAY IN STATION";
        if (displayFlow[currentVideoIndex + 1]?.phase === "SET_1") {
            return "MOVE TO NEXT STATION";
        }
        if (displayFlow[currentVideoIndex + 1]?.phase.includes("SET")) {
            return "STAY IN STATION";
        }
        return "MOVE TO NEXT STATION"
    }

    const introVideoShowAttribute = () => {
        let isDisplay = false;
        let opacity = 0;
        isDisplay = displayFlow[currentVideoIndex]?.phase === "INTRO" || displayFlow[currentVideoIndex + 1]?.phase === "INTRO";

        if (displayFlow[currentVideoIndex]?.phase === "INTRO") {
            opacity = 1;
        } else if (displayFlow[currentVideoIndex + 1]?.phase === "INTRO") {
            if (countDown <= TRANSITION_DURATION) opacity = 0.5;
        } else {
            opacity = 0;
        }

        return { isDisplay, opacity }
    }

    const set1ShowAttribute = () => {
        if (
            displayFlow[currentVideoIndex]?.phase === "INTRO" ||
            displayFlow[currentVideoIndex]?.phase === "WARM_UP" ||
            displayFlow[currentVideoIndex]?.phase === "PREPARE" ||
            displayFlow[currentVideoIndex]?.phase === "CHANGE_ZONE"
        ) {
            return {
                isDisplay: false, opacity: 0
            }
        }

        let isDisplay = false;
        let opacity = 0;
        isDisplay = displayFlow[currentVideoIndex]?.phase === "SET_1" || displayFlow[currentVideoIndex + 1]?.phase === "SET_1";

        if (displayFlow[currentVideoIndex]?.phase === "SET_1") {
            opacity = 1;
        } else if (displayFlow[currentVideoIndex + 1]?.phase === "SET_1") {
            if (countDown <= TRANSITION_DURATION) opacity = 0.5;
        } else {
            opacity = 0;
        }

        return { isDisplay, opacity }
    }

    const set2ShowAttribute = () => {
        let isDisplay = false;
        let opacity = 0;
        isDisplay = displayFlow[currentVideoIndex]?.phase === "SET_2" || displayFlow[currentVideoIndex + 1]?.phase === "SET_2";

        if (displayFlow[currentVideoIndex]?.phase === "SET_2") {
            opacity = 1;
        } else if (displayFlow[currentVideoIndex + 1]?.phase === "SET_2") {
            if (countDown <= TRANSITION_DURATION) opacity = 0.5;
        } else {
            opacity = 0;
        }

        return { isDisplay, opacity }
    }

    const set3ShowAttribute = () => {
        let isDisplay = false;
        let opacity = 0;
        isDisplay = displayFlow[currentVideoIndex]?.phase === "SET_3" || displayFlow[currentVideoIndex + 1]?.phase === "SET_3";

        if (displayFlow[currentVideoIndex]?.phase === "SET_3") {
            opacity = 1;
        } else if (displayFlow[currentVideoIndex + 1]?.phase === "SET_3") {
            if (countDown <= TRANSITION_DURATION) opacity = 0.5;
        } else {
            opacity = 0;
        }

        return { isDisplay, opacity }
    }

    const textPhaseAttribute = () => {
        let opacity = 1;

        if (countDown <= 2) {
            opacity = 0;
        }

        if (
            displayFlow[currentVideoIndex]?.phase === "PREPARE" ||
            displayFlow[currentVideoIndex]?.phase === "END"
        ) opacity = 1;

        return { opacity }
    }

    const renderHeader = () => {
        const topBarStyle = webStyle.topBarStyle;
        const topBarBoxStyle = webStyle.topBarBox;
        const logoStyle = webStyle.logo;
        const customStyle = webStyle.customStyle;

        return (
            <Box sx={topBarStyle} onClick={() => {
                const totalTime = displayFlow.reduce((accumulator, currentValue) => {
                    const timeValue = parseInt(currentValue.time);
                    return isNaN(timeValue) ? accumulator : accumulator + timeValue;
                }, 0);
            }}>
                <Box sx={topBarBoxStyle} flexDirection="column" onClick={() => {

                    const totalTime = displayFlow.filter(e => e.phase !== "PREPARE" && e.phase !== "END").reduce((sum, currentItem) => sum + (getCorrectTimeOfPhase(currentItem.time) || 0), 0);
                    const totalTimeString = `${Math.floor(totalTime / 60)}m${totalTime % 60}s`;
                }}>
                    {isShowCurrentSetAndLap() ? (
                        <>
                            {renderNumberBoxSelected("SET", sets, displayFlow[currentVideoIndex]?.currentSet)}
                            {renderNumberBoxSelected("LAP", laps, displayFlow[currentVideoIndex]?.currentLap)}
                        </>
                    ) : undefined}
                </Box>
                <Box sx={logoStyle}>
                    <img
                        width={"auto"}
                        height={65}
                        style={{ objectFit: "contain" }}
                        src={require("./unified_logo.png")}
                        alt="logo"
                    />
                </Box>
                <Box sx={topBarBoxStyle}>
                    <BoilerPlate
                        insideCircleText={countDown}
                        outsideCircleText={getPhaseLabel()}
                        progress={getProgressCountDown()}
                        style={customStyle}
                        color={isGreenBackground() ? "white" : "#F3030C"}
                    />
                </Box>
            </Box>
        );
    }

    const renderProgressBar = () => {
        if((displayFlow[currentVideoIndex]?.phase.includes("SET")) || (displayFlow[currentVideoIndex]?.phase.includes("DEMO"))){
            let allZone1Item = displayFlow.filter(e => e?.currentZone === 1);
            let allZone2Item = displayFlow.filter(e => e?.currentZone === 2);
            let allZone3Item = displayFlow.filter(e => e?.currentZone === 3);
            let totalSetPhase = sets * laps * station
    
            let percentage1 = allZone1Item.filter(e => (e.id || 0) <= currentVideoIndex && e?.phase.includes("SET")).length / totalSetPhase * 100;
            let percentage2 = allZone2Item.filter(e => (e.id || 0) <= currentVideoIndex && e?.phase.includes("SET")).length / totalSetPhase * 100;
            let percentage3 = allZone3Item.filter(e => (e.id || 0) <= currentVideoIndex && e?.phase.includes("SET")).length / totalSetPhase * 100;
            

            const demoTime = getCorrectTimeOfPhase(displayFlow[currentVideoIndex]?.time)
            let demoPercentage1 =  0
            let demoPercentage2 = 0
            let demoPercentage3 = 0
            const timeCount = demoTime - countDown
           
            if(timeCount < demoTime / 3){
                demoPercentage1 = timeCount / (demoTime / 3) * 100
            }else if(timeCount <= demoTime / 3 * 2){
                demoPercentage2 = (timeCount - (demoTime / 3)) / (demoTime / 3) * 100
                demoPercentage1 = 100
            }else if(timeCount < demoTime){
                demoPercentage3 = (timeCount - (demoTime / 3 * 2)) / (demoTime / 3) * 100
                demoPercentage1 = 100
                demoPercentage2 = 100
            }
            let percentage1String = displayFlow[currentVideoIndex]?.phase.includes("SET") ?  `${Math.floor(percentage1)}%` : `${demoPercentage1}%`
            let percentage2String = displayFlow[currentVideoIndex]?.phase.includes("SET") ?  `${Math.floor(percentage2)}%` : `${demoPercentage2}%`
            let percentage3String = displayFlow[currentVideoIndex]?.phase.includes("SET") ?  `${Math.floor(percentage3)}%` : `${demoPercentage3}%`
            return (
                <Box sx={webStyle.progressBarWrapper} >
                    <Box style={webStyle.progressBarItem} >
                        <Box
                            style={{
                                height: "100%",
                                backgroundColor: "#F3030C",
                                width: percentage1String,
                            }}
                        />
                    </Box>
                    <Box style={{ width: 30 }} />
                    <Box style={webStyle.progressBarItem} >
                        <Box
                            style={{
                                height: "100%",
                                backgroundColor: "#F3030C",
                                width: percentage2String,
                            }}
                        />
                    </Box>
                    <Box style={{ width: 30 }} />
                    <Box style={webStyle.progressBarItem} >
                        <Box
                            style={{
                                height: "100%",
                                backgroundColor: "#F3030C",
                                width: percentage3String,
                            }}
                        />
                    </Box>
                </Box>
            );
        }
        return <></>
    };

    const renderIntro = () => (
        <Grid container spacing={2} style={{
            flexDirection: 'row',
            height: window.innerHeight - 130,
            display: introVideoShowAttribute().isDisplay ? "flex" : "none",
            overflow: "hidden",
            opacity: introVideoShowAttribute().opacity,
            transition: "opacity 1s ease-in-out"
        }}>
            <Grid item md={12} sm={12} xs={12} >
                <VideoPlayerPreview
                    ref={introVideoRef}
                    loop={false}
                    label={""}
                    videoUrl={introductionVideo?.attributes?.video_url[0]?.url || introductionVideo?.video_url} />
            </Grid>
        </Grid>
    )

    const renderDemo = () => (
        <>
            <Grid container spacing={2} style={{
                display: displayFlow[currentVideoIndex]?.phase === "DEMO" && station <= 3 ? "flex" : "none",
                flexDirection: 'row',
                height: window.innerHeight - 130,
                overflow: "hidden"
            }}>
                {getDemoData().map((item: DemoItem, index: number) => (
                    // @ts-ignore
                    <Grid key={index} item md={12 / station} sm={12 / station} xs={12 / station} style={webStyle.demoGridContainer}>
                        <div style={{ color: "white", fontSize: 38, fontFamily: 'TradeGothicNextLTPro-Bd, Arial, sans-serif', fontWeight: 'bold' }}>STATION {item?.station}</div>
                        <div style={webStyle.demoVideoWrapper}>
                            <video
                                ref={demoVideoRefs[3 * index + 0]}
                                style={{
                                    objectFit: "contain",
                                    border: isShowRedBox(item?.station || 0, 1) ? "5px solid red" : "none",
                                    height: getDemoVideoDimension().height,
                                    width: getDemoVideoDimension().width,
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    backgroundColor: "rgba(0,0,0,0.3)",
                                }}
                                loop={true}
                                autoPlay={false}
                                muted
                            >
                                <source src={item.data?.set1?.attributes?.video_url[0]?.url || item.data?.set1?.video_url} type="video/mp4" />
                            </video>
                            <div style={{
                                height: getDemoVideoDimension().height,
                                width: getDemoVideoDimension().width,
                                ...webStyle.demoSetNumberWrapper
                            }}>
                                <div style={webStyle.demoSetContainer}>
                                    <Typography style={webStyle.demoSetText}>1</Typography>
                                </div>
                            </div>
                        </div>
                        {sets >= 2 ? (
                            <div style={webStyle.demoVideoWrapper}>
                                <video
                                    ref={demoVideoRefs[3 * index + 1]}
                                    style={{
                                        objectFit: "contain",
                                        border: isShowRedBox(item?.station || 0, 2) ? "5px solid red" : "none",
                                        height: getDemoVideoDimension().height,
                                        width: getDemoVideoDimension().width,
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        backgroundColor: "rgba(0,0,0,0.3)",
                                    }}
                                    loop={true}
                                    autoPlay={false}
                                    muted
                                >
                                    <source src={item.data?.set2?.attributes?.video_url[0]?.url || item.data?.set2?.video_url} type="video/mp4" />
                                </video>
                                <div style={{
                                    height: getDemoVideoDimension().height,
                                    width: getDemoVideoDimension().width,
                                    ...webStyle.demoSetNumberWrapper
                                }}>
                                    <div style={webStyle.demoSetContainer}>
                                        <Typography style={webStyle.demoSetText}>2</Typography>
                                    </div>
                                </div>
                            </div>
                        ) : undefined}
                        {sets >= 3 ? (
                            <div style={webStyle.demoVideoWrapper}>
                                <video
                                    ref={demoVideoRefs[3 * index + 2]}
                                    style={{
                                        objectFit: "contain",
                                        border: isShowRedBox(item?.station || 0, 3) ? "5px solid red" : "none",
                                        height: getDemoVideoDimension().height,
                                        width: getDemoVideoDimension().width,
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        backgroundColor: "rgba(0,0,0,0.3)",
                                    }}
                                    loop={true}
                                    autoPlay={false}
                                    muted
                                >
                                    <source src={item.data?.set3?.attributes?.video_url[0]?.url || item.data?.set3?.video_url} type="video/mp4" />
                                </video>
                                <div style={{
                                    height: getDemoVideoDimension().height,
                                    width: getDemoVideoDimension().width,
                                    ...webStyle.demoSetNumberWrapper
                                }}>
                                    <div style={webStyle.demoSetContainer}>
                                        <Typography style={webStyle.demoSetText}>3</Typography>
                                    </div>
                                </div>
                            </div>
                        ) : undefined}

                    </Grid>
                ))}
            </Grid>

            <div
                style={{
                    width: getGridDimension().gridWrapperWidth,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    display: displayFlow[currentVideoIndex]?.phase === "DEMO" && station > 3 ? "flex" : "none",
                    overflow: "hidden",
                    paddingLeft: 50
                }}
            >
                {initVideoSequence?.map((item: any, index: number) => {
                    const isShowItem = parseInt(item) <= station;
                    let setData = zoneData[item]?.set1 || {};
                    const setVideoUrl = setData?.attributes?.video_url[0]?.url || setData?.video_url;
                    return (
                        <>
                            {isShowItem ? (
                                <div
                                    style={{
                                        width: getGridDimension().gridItemWidth - 18,
                                        height: (getGridDimension().gridItemWidth / 16) * 9 - 10,
                                        overflow: "hidden",
                                        position: "relative",
                                    }}
                                >
                                    <VideoPlayerPreview
                                        ref={demo2VideoRefs[index]}
                                        loop={true}
                                        label={item}
                                        videoUrl={setVideoUrl}
                                        border={isShowRedBox(parseInt(item), 1) ? "5px solid red" : "none"}
                                    />
                                    <Typography style={webStyle.stationTextMarker2}>STATION {item}</Typography>
                                </div>
                            ) : undefined}
                        </>
                    );
                })}
            </div>
        </>
    )

    const renderWarmUp = () => (
        <Grid container spacing={2} style={{
            flexDirection: 'row',
            height: window.innerHeight - 130,
            display: displayFlow[currentVideoIndex]?.phase === "WARM_UP" ? "flex" : "none",
            overflow: "hidden",
        }}>
            <Grid item md={12} sm={12} xs={12} >
                <VideoPlayerPreview
                    ref={warmupVideoRef}
                    loop={false}
                    label={""}
                    videoUrl={warmupVideo?.attributes?.video_url[0]?.url || warmupVideo?.video_url} />
            </Grid>
        </Grid>
    )

    const renderCoolDown = () => (
        <Grid container spacing={2} style={{
            flexDirection: 'row',
            height: window.innerHeight - 130,
            display: displayFlow[currentVideoIndex]?.phase === "COOL_DOWN" ? "flex" : "none",
            overflow: "hidden",
        }}>
            <Grid item md={12} sm={12} xs={12} >
                <VideoPlayerPreview
                    ref={coolDownVideoRef}
                    loop={false}
                    label={""}
                    videoUrl={coolingVideo?.attributes?.video_url[0]?.url || coolingVideo?.video_url} />
            </Grid>
        </Grid>
    )

    const renderSet1 = () => (
        <div
            style={{
                width: getGridDimension().gridWrapperWidth,
                flexDirection: "row",
                flexWrap: "wrap",
                display: set1ShowAttribute().isDisplay ? "flex" : "none",
                overflow: "hidden",
                opacity: set1ShowAttribute().opacity,
                transition: "opacity 1s ease-in-out",
                paddingLeft: 50
            }}
        >
            {(selectedClassType?.attributes?.station_sequence || initVideoSequence)?.map((item: any, index: number) => {
                const isShowItem = parseInt(item) <= station;
                let setData = zoneData[item]?.set1 || {};
                const setVideoUrl = setData?.attributes?.video_url[0]?.url || setData?.video_url;
                return (
                    <>
                        {isShowItem ? (
                            <div
                                style={{
                                    width: getGridDimension().gridItemWidth - 18,
                                    height: (getGridDimension().gridItemWidth / 16) * 9 - 10,
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                            >
                                <VideoPlayerPreview
                                    ref={set1VideoRefs[index]}
                                    loop={true}
                                    label={item}
                                    videoUrl={setVideoUrl}
                                />
                                <Typography style={webStyle.stationTextMarker}>{item}</Typography>

                            </div>
                        ) : undefined}
                    </>
                );
            })}
        </div>
    )

    const renderSet2 = () => (
        <div
            style={{
                width: getGridDimension().gridWrapperWidth,
                flexDirection: "row",
                flexWrap: "wrap",
                display: set2ShowAttribute().isDisplay ? "flex" : "none",
                overflow: "hidden",
                opacity: set2ShowAttribute().opacity,
                transition: "opacity 1s ease-in-out",
                paddingLeft: 50
            }}
        >
            {(selectedClassType?.attributes?.station_sequence || initVideoSequence)?.map((item: any, index: number) => {
                const isShowItem = parseInt(item) <= station;
                let setData = zoneData[item]?.set2 || {};
                const setVideoUrl = setData?.attributes?.video_url[0]?.url || setData?.video_url;

                return (
                    <>
                        {isShowItem ? (
                            <div
                                style={{
                                    width: getGridDimension().gridItemWidth - 18,
                                    height: (getGridDimension().gridItemWidth / 16) * 9 - 10,
                                    overflow: "hidden",
                                    position: "relative"
                                }}
                            >
                                <VideoPlayerPreview
                                    ref={set2VideoRefs[index]}
                                    loop={true}
                                    label={item}
                                    videoUrl={setVideoUrl}
                                />
                                <Typography style={webStyle.stationTextMarker}>{item}</Typography>
                            </div>
                        ) : undefined}
                    </>
                );
            })}
        </div>
    )

    const renderSet3 = () => (
        <div
            style={{
                width: getGridDimension().gridWrapperWidth,
                flexDirection: "row",
                flexWrap: "wrap",
                display: set3ShowAttribute().isDisplay ? "flex" : "none",
                overflow: "hidden",
                opacity: set3ShowAttribute().opacity,
                transition: "opacity 1s ease-in-out",
                paddingLeft: 50
            }}
        >
            {(selectedClassType?.attributes?.station_sequence || initVideoSequence)?.map((item: any, index: number) => {
                const isShowItem = parseInt(item) <= station;
                let setData = zoneData[item]?.set3 || {};
                const setVideoUrl = setData?.attributes?.video_url[0]?.url || setData?.video_url;
                return (
                    <>
                        {isShowItem ? (
                            <div
                                style={{
                                    width: getGridDimension().gridItemWidth - 18,
                                    height: (getGridDimension().gridItemWidth / 16) * 9 - 10,
                                    overflow: "hidden",
                                    position: "relative"
                                }}
                            >
                                <VideoPlayerPreview
                                    ref={set3VideoRefs[index]}
                                    loop={true}
                                    label={item}
                                    videoUrl={setVideoUrl}
                                />
                                <Typography style={webStyle.stationTextMarker}>{item}</Typography>
                            </div>
                        ) : undefined}
                    </>
                );
            })}
        </div>
    )

    const renderNoneVideoPhase = () => (
        <div style={{
            position: "absolute",
            zIndex: 100,
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            opacity: textPhaseAttribute().opacity,
            transition: "opacity 1s ease-in-out"
        }}>
            {displayFlow[currentVideoIndex]?.phase === "PREPARE" ? (
                <Typography variant="h3" align="center" style={{ ...webStyle.restLabel, fontWeight: "bold" }}>GET READY</Typography>
            ) : undefined}
            {displayFlow[currentVideoIndex]?.phase === "GET_STARTED" ? (
                <Typography variant="h3" align="center" style={{ ...webStyle.restLabel, fontWeight: "bold" }}>GET READY</Typography>
            ) : undefined}
            {displayFlow[currentVideoIndex]?.phase === "REST" ? (
                <Typography variant="h3" align="center" style={{ ...webStyle.restLabel, fontWeight: "bold" }}>{getRestText()}</Typography>
            ) : undefined}
            {displayFlow[currentVideoIndex]?.phase === "CHANGE_ZONE" ? (
                <Typography variant="h3" align="center" style={{ ...webStyle.restLabel, fontWeight: "bold" }}>WATER BREAK</Typography>
            ) : undefined}
            {displayFlow[currentVideoIndex]?.phase === "END" ? (
                <Typography variant="h3" align="center" style={{ ...webStyle.restLabel, fontWeight: "bold" }}>FINISH</Typography>
            ) : undefined}
        </div>
    )

    return (
        <Box style={{
            display: "flex",
            fontFamily: "Roboto-Medium",
            flexDirection: "column",
            width: "100%",
            padding: 10,
            backgroundColor: isGreenBackground() ? "#313320" : "#3C1710",
            alignItems: "center",
            overflow: "hidden",
        }}>
            {renderHeader()}
            {renderProgressBar()}
            {renderIntro()}
            {renderWarmUp()}
            {renderCoolDown()}
            <div style={webStyle.emptyContainer} />
            {renderDemo()}
            {renderSet1()}
            {renderSet2()}
            {renderSet3()}
            <div style={webStyle.emptyContainer} />
            {renderNoneVideoPhase()}
        </Box>
    )
})

export default PreviewFlow;

interface WebStyle {
    [key: string]: React.CSSProperties;
}

const webStyle: WebStyle = {
    numberBoxSelectedText: {
        color: "#fff", fontSize: 25, marginRight: 15, fontWeight: "bold" as "bold"
    },
    numberBoxSelectedContainer: {
        display: "flex",
        flexDirection: 'row' as 'row',
        alignItems: "center",
        marginTop: 5
    },
    emptyContainer: {
        flex: 1
    },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        width: "100%",
        padding: 10,
        backgroundColor: "#2A2D1E",
        alignItems: "center"
    },
    restLabel: {
        marginTop: 100,
        color: "white",
        fontSize: 170,
    },
    setNumberLabel: {
        position: "absolute",
        top: 0,
        right: 10,
        fontSize: 30
    },
    topBarStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "20px"
    },
    topBarBox: {
        width: "200px",
        height: "60px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: 10,
    },
    headerText: {
        color: "white",
        fontSize: 20
    },

    hrStyle: {
        height: "1px",
        width: "80px",
        border: "none",
        background: "#EEECE7",
        marginBlock: 0
    },
    templatePic: {
        width: "443px",
        height: "740px"
    },
    logo: {
        height: '59px',
        width: '142px'
    },
    customStyle: {
        justifyContent: "spaceBetween",
        minWidth: '60px',
        height: '60px',
        paddingInline: "12%",
        display: "flex",
    },
    videoWarpper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    progressBarWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 5,
        width: "100%",
        marginBottom: 10,
    },
    progressBarItem: {
        height: "100%",
        flex: 1,
        backgroundColor: "#931710",
        overflow: "hidden",
        borderRadius: 3,
    },
    setMarker: {
        fontSize: 30,
        color: "#dedede",
        position: "absolute",
        top: 10,
        right: 20,
        zIndex: 2,
        padding: 10,
        paddingBottom: 5,
        paddingTop: 7,
        backgroundColor: "rgba(0,0,0,0.3)",
        borderRadius: 5,
        paddingRight: 20,
        paddingLeft: 20,
    },
    stationTextMarker: {
        position: "absolute",
        top: 0,
        right: 20,
        fontSize: 60,
        fontWeight: "bold"
    },
    stationTextMarker2: {
        position: "absolute",
        top: 10,
        right: 20,
        fontSize: "2vmin",
        fontWeight: "bold",
        color: "black",
        fontFamily: 'TradeGothicNextLTPro-Bd, Arial, sans-serif'
    },
    demoGridContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flex: 1,
        height: "100%"
    },
    demoVideoWrapper: {
        flex: 1,
        overflow: "hidden",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 5,
        padding: 5,
        position: "relative"
    },
    demoSetNumberWrapper: {
        maxWidth: "100%",
        maxHeight: "100%",
        position: "absolute",
        zIndex: 10,
        aspectRatio: "1.78",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        textAlign: "end"
    },
    demoSetContainer: {
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 5,
        backgroundColor: "rgba(0,0,0,0.5)",
        marginTop: 20,
        marginRight: 25
    },
    demoSetText: {
        fontSize: "3vmin",
        color: "#E8E8E8",
        fontFamily: 'TradeGothicNextLTPro-Bd, Arial, sans-serif'
    }
}