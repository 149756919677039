import React, { ChangeEvent, useEffect, useState } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography, IconButton, Modal, InputBase } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import CommonButton from "./CommonButton";

interface CommonModalAddTagsProps extends BoxProps {
  popupHeading: string;
  handleCloseModal: any;
  openModal: boolean;
  allTags?: string[];
  handlePositiveClick: (data: string[]) => void;
}

const CommonModalAddTags: React.FC<CommonModalAddTagsProps> = (props) => {
  const { popupHeading, handleCloseModal, openModal, allTags, handlePositiveClick } = props;
  const [listTag, setListTag] = useState<string[]>([])
  const [inputText, setInputText] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const handleDeleteATag = (tagDeleted: string) => {
    let listTagTemp = [...listTag]
    listTagTemp = listTagTemp.filter(tag => tag !== tagDeleted)
    setListTag(listTagTemp)
  }

  const renderTag = (item: string, index: number) => {
    return (
      <Box sx={webStyle.tagContainer} key={index}>
        <Typography style={webStyle.input}>{item}</Typography>
        <Box onClick={() => handleDeleteATag(item)}>
          <Close fontSize="small" />
        </Box>
      </Box>
    );
  };
  const onChangeInputText = (text: ChangeEvent<HTMLInputElement>) => {
    setInputText(text.target.value);
    setErrorMsg("")
  }
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      data-testid="modal-add-tag-workout"
      aria-labelledby="modal-add-tag-title"
      aria-describedby="modal-add-tag-description"
    >
      <Box sx={webStyle.modalWrapper}>
        <Box
          height={391}
          sx={{ ...webStyle.modalContainer, paddingBottom: 12 }}
        >
          <Box sx={webStyle.modalHeader}>
            <Typography
              style={webStyle.textHeader}
              data-testid="modal-add-tag-title"
            >
              {popupHeading}
            </Typography>
            <IconButton
              data-testid="modal-button-close"
              onClick={() => {
                setListTag([]);
                setInputText("");
                handleCloseModal()
              }}
            >
              <Close fontSize="default" />
            </IconButton>
          </Box>
          <Box sx={webStyle.contentContainer}>
            <Typography style={webStyle.textAddTag}>{popupHeading.toLowerCase().includes("tag") ? 'Input new tag' : "Input new category"}</Typography>
            <Box sx={webStyle.containerModalTag}>
              <InputBase
                placeholder= {popupHeading.toLowerCase().includes("tag") ? "Enter a tag" :  "Enter a category" }
                value={inputText}
                style={{ width: "100%", fontWeight: "normal" }}
                onChange={onChangeInputText} />
            </Box>
          </Box>
          <Typography style={webStyle.errorMsgText}>{errorMsg}</Typography>
          <Box sx={webStyle.listTagContent}>
            {listTag.map((tag, index) => renderTag(tag, index))}
          </Box>
          <Box display="flex" flex={1} />
          <Box sx={webStyle.buttonContainer}>
            <CommonButton
              width={93}
              height={38}
              label="Cancel"
              onClickButton={()=> {
                setListTag([]);
                setInputText("");
                handleCloseModal();
              }}
              mode="transparent"
            />
            <CommonButton
              width={93}
              height={38}
              label="Add"
              onClickButton={() => {
              if (inputText.trim() === "") { } 
              else { 
                const input = inputText.trim()
                if (allTags?.some(x => x.toLowerCase() === input.toLowerCase()) || listTag.some(x => x.toLowerCase() === input.toLowerCase())) {
                  setErrorMsg("Tag already exists.")
                } else {
                  let tagsList: string[] = []
                  if(input.trim()!=="")
                  tagsList.push(input.trim())
                  setListTag([]);
                  setInputText("");
                  handlePositiveClick(tagsList); 
                }
              } 
            }}
              mode={inputText.trim() === "" ? "rounded" : "primary-rounded"}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
const webStyle = {
  tagContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "0.76px solid #EEECE7",
    background: "#fff",
    borderRadius: 13,
    marginRight: 14,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16
  },
  listTagContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
  },
  icon: {
    marginLeft: 10,
  },
  iconAdd: {
    marginRight: 20,
  },
  containerModalTag: {
    borderRadius: 17,
    border: "1px solid #C2C2C2",
    background: "#F1F0EE",
    flex: 1,
    height: 63,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 15
  },
  containerInput: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    height: 49,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    marginRight: 10
  },
  modalWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textHeader: {
    fontSize: 16,
    fontWeight: 700,
    color: "#313320",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 630,
    borderRadius: 16,
    background: "#fff",
    overflow: "hidden",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 24,
    paddingRight: 18,
    background: "#EEECE7",
  },
  headerContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 31,
    marginTop: 20,
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 31,
    paddingTop: 15,
    borderTop: "0.5px solid #EEECE7",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 32px 0px 32px",
  },
  textAddTag: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
  textTagAvailable: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
    alignSelf: "center",
    marginTop: 16,
  },
  listTagContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "0px 32px 0px 32px",
    overflowY: "auto",
  },
  errorMsgText: {
    fontSize: 14,
    fontWeight: 400,
    color: "red",
    marginLeft: 32
  },
};

export default CommonModalAddTags;