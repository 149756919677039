import React from "react";
import {
  TextField,
  withStyles,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

const CustomTextField = withStyles({
  root: {
    "& .MuiInput-underline::before": {
      borderBottom: "none", // Remove the default underline/border bottom
    },
    "& .MuiInput-underline::after": {
      borderBottom: "none", // Remove the underline/border bottom on focus
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "none", // Remove the underline/border bottom on hover
    },
    "& .MuiInputBase-input": {
      fontSize: "16px", // Customize the font size
      color: "#747474",
      width: "250px",
      textAlign: "right",
    },
  },
})(TextField);
interface Props {
  dateCalendarSelected: any;
  handleChangeDateCalendarSelected: any;
}
const CalendarDatePicker = (props: Props) => {
  const { dateCalendarSelected, handleChangeDateCalendarSelected } = props;
  const TextFieldComponent = (props: any) => <CustomTextField {...props} />;
  const isDateSelected = (date: Date | null) => {
    return (
      dateCalendarSelected !== null &&
      date !== null &&
      moment(dateCalendarSelected).isSame(date, "day")
    );
  };
  return (
    <DatePicker
      value={dateCalendarSelected}
      onChange={handleChangeDateCalendarSelected}
      animateYearScrolling
      format="dddd, D MMMM, yyyy"
      disableToolbar
      variant="inline"
      autoOk
      TextFieldComponent={TextFieldComponent}
      renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
        const isSelected = isDateSelected(day);

        return React.cloneElement(dayComponent, {
          style: {
            borderRadius: "50%",
            backgroundColor: isSelected ? "#E73700" : undefined, // Set orange color for selected date
            color: isSelected ? "#fff" : undefined, // Set white color for text of selected date
          },
        });
      }}
    />
  );
};

export default CalendarDatePicker;
