import React, { useState } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";


interface VideoPlayerPreviewProps {
  videoUrl: string;
  label: string;
  width?: any;
  height?: any;
  loop?: boolean;
  border?: any
}

const VideoPlayerPreview = React.forwardRef((props: VideoPlayerPreviewProps, ref: any) => {
  const videoRef: any = React.useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { videoUrl, label, loop = false , border} = props;
  const videoID = `full-screenVideo${label}`;
  React.useEffect(() => {
    if (ref) {
      ref.current = videoRef.current;
    }
  }, [ref]);

  React.useEffect(() => {
    window.addEventListener('resize', setVideoDimension);

    return () => {
      window.removeEventListener('resize', setVideoDimension);
    };
  }, []);

  const setVideoDimension = () => {
    const videoWidth = videoRef?.current?.offsetWidth;
    const videoHeight = videoRef?.current?.offsetHeight;

    setWidth(videoWidth);
    setHeight(videoHeight);

    if (videoWidth / videoHeight < 16 / 9) {
      setWidth(videoWidth);
      setHeight(videoWidth / 16 * 9);
    } else {
      setWidth(videoHeight / 9 * 16);
      setHeight(videoHeight);
    }
  }

  const renderVideo = () => (
    <video
      ref={videoRef}
      id={videoID}
      style={{
        width:  '98%' ,
        height: '98%',
        objectFit: "contain",
        border,
        // backgroundColor: "rgba(0,0,0,0.1)"
      }}
      loop={loop}
      autoPlay={false}
      muted
      onPlay={setVideoDimension}
    >
      <source src={videoUrl} type="video/mp4" />
    </video>
  )

  return (
    <Box key={label} sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
    >
      {renderVideo()}
    </Box>
  );
})

export default VideoPlayerPreview;

const webStyle = {
  labelBox: {
    display: "flex",
    height: 32,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 10
  },
  container: {
    // backgroundColor: "black",
  },
  loadingContainer: {
    // backgroundColor: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textStation: {
    fontSize: 40,
    color: "black",
  },
  absoluteView: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 10
  },
};