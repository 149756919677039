import React, { ChangeEvent, useState, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Box, { BoxProps } from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

interface InputTextProps extends BoxProps {
  label: string;
  value?: string;
  isDisabled?: boolean;
  timeInput?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleUnitSelection?: (unit: {}) => void;
  
  keyValue?: string;
  showError?: boolean;
  errorMessage?: string;
}

const CommonInputText: React.FC<InputTextProps> = ({
  label,
  value,
  onChange,
  isDisabled,
  timeInput,
  handleUnitSelection,
  
  keyValue,
  showError,
  errorMessage,
  ...props
}) => {
  const TYPE_TIMES = ["Seconds", "Minutes"];
  const [typeTime, setTypeTime] = useState<string>(TYPE_TIMES[0]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [modifyData, setModifyData] =  useState<boolean>(false);
  const idFilter = useRef("idFilter");
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleClickItemType = (item: any) => {
    setModifyData(true);
    setTypeTime(item);
    handleClosePopover()
    const data = {
      "key": keyValue,
      "value" : value,
      "unit": item
    }
    handleUnitSelection && handleUnitSelection(data)
  }
  const renderPopover = () => {
    return (
      <Popover
        id={idFilter.current}
        open={Boolean(anchorEl || null)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        PaperProps={{
          style: {
            borderRadius: 17,
          },
        }}
        data-testid="filter-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={webStyle.popoverContainer}>
          {TYPE_TIMES.map((item: any , index:number) => {
            const styleItem = 
              item === (modifyData === false && timeInput && typeof value === 'string' && value.includes(" ") ? value.split(" ")[1] : typeTime) ? webStyle.activeItem : webStyle.inactiveItem;
            return (
              <Box
                style={{ ...webStyle.itemTypeTime, ...styleItem }}
                onClick={() => handleClickItemType(item)}
                key={index}
              >
                <Typography style={webStyle.textItemType}>{item}</Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    );
  };
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Box {...props}>
      {renderPopover()}
      <Typography style={webStyle.label}>{label}</Typography>
      <Box sx={webStyle.inputContainer}>
        <input
          style={{ ...webStyle.input, width: "100%", outline: 'none' }}
          type="text"
          value={timeInput && typeof value === 'string' && value.includes(" ") ? value.split(" ")[0] : value}
          disabled={isDisabled}
          onChange={onChange}
        />
        {timeInput && <Box display="flex" flex={1} />}
        {timeInput && (
          <Box onClick={handleOpenPopover} sx={webStyle.timeContainer}>
            <Typography style={webStyle.textTime}>{modifyData === false && timeInput && typeof value === 'string' && value.includes(" ") ? value.split(" ")[1] : typeTime}</Typography>
            <ExpandMoreOutlinedIcon fontSize="small" />
          </Box>
        )}
      </Box>
      {showError && <Typography style={{...webStyle.label, color: 'red'}}>{errorMessage}</Typography>}    
      </Box>
  );
};
const webStyle = {
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
    fontWeight: 400,
    marginTop: 15,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    height: 49,
    width: "100%",

    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    borderRadius: 17,
    height: 49,
    paddingRight: 16,
    border: "none",
    background: "#f1f0ee",
    fontSize: 16,
    paddingLeft: 20,
    paddingTop: 12,
    paddingBottom: 12,
    "&:focus": {
      outline: "none",
      border: "none !important",
      boxShadow: "none !important",
    },
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
  },
  textTime: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
    marginRight: 20,
  },
  popoverContainer: {
    width: 191,
    height: 90,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FBFAF7",
    padding: 4,
  },
  itemTypeTime: {
    width: 171,
    height: 41,
    borderRadius: 17,
    paddingLeft: 16,
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
  },
  activeItem: {
    background: "#EEECE7",
  },
  inactiveItem: {
    background: "#FBFAF7",
  },
  textItemType: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
  required: {
    color: "red",
  },
};

export default CommonInputText;
