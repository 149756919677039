import React, {useState} from 'react'
import Box, { BoxProps } from "@material-ui/core/Box";
import {
  Typography,
  IconButton,
  Modal,
  TextField,
  InputAdornment,
  withStyles,
  Popover,
  CircularProgress,
  Backdrop,
  Fade,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";
import CommonButton from "./CommonButton";

type Props = {
    open: boolean,
    onClose: () => void
    handleClickDeleteButton: (value: string) => void
    isDate?: boolean

}

function ModalConfirmDelete({open, onClose, handleClickDeleteButton, isDate = false}: Props) {
    const [radioDeleteValue, setRadioDeleteValue] = useState('0')
  return (
    <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-confirm-delete"
    aria-describedby="modal-confirm-delete-description"
    closeAfterTransition
    BackdropComponent={Backdrop}
    style={webStyle.modalConfirmDeleteContainer}
    BackdropProps={{
      timeout: 500,
    }}>
    <Fade in={open}>
          <Box sx={{bgcolor:'#fff', padding: 16, borderRadius: 12, paddingLeft: 22, paddingRight: 22}}>
            <Typography style={{fontSize: 18, fontWeight: 'bold'}} id="transition-modal-title">{isDate ? "Delete recurring date" :"Delete recurring event"}</Typography>
            <RadioGroup aria-label="gender" name="gender1" value={radioDeleteValue} onChange={(e)=> setRadioDeleteValue(e.target.value)}>
              <FormControlLabel value="1" control={<Radio />} label={isDate ? "This date and all slots" : "This event"} />
              <FormControlLabel value="0" control={<Radio />} label={isDate? "This date, all slots and following events" : "This and following events"} />
            </RadioGroup>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 12}}>
            <CommonButton
              width={93}
              height={38}
              label="Cancel"
              onClickButton={onClose}
              mode="transparent"
            />
            <CommonButton
              width={93}
              height={38}
              label="Delete"
              onClickButton={() => {
                handleClickDeleteButton(radioDeleteValue)
                setRadioDeleteValue('0')
              }}
              mode="primary-rounded"
            />
            </Box>
          </Box>
        </Fade>
  </Modal>
  )
}
const webStyle = {
    modalConfirmDeleteContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
}
export default ModalConfirmDelete