import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PreviewFlow from "../../../components/src/PreviewFlow";

import PreviewController, {
  Props,
} from "./PreviewController";
import { withRouter } from 'react-router-dom';


class Preview extends PreviewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <PreviewFlow 
          {...this.state}
          ref={this.previewRef}
        />
      </>
    );
  }

}
export { Preview };

//@ts-ignore
export default withRouter(Preview);

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  topBarStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px"
  },
  topBarBox: {
    width: "190px",
    height: "65px",
    borderRadius: "8px",
    border: "1px solid #C2C2C2",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  hrStyle: {
    height: "1px",
    width: "80px",
    border: "none",
    background: "#EEECE7",
    marginBlock: 0
  },
  templatePic: {
    width: "443px",
    height: "740px"
  },
  customProgress: {
    width: "52px !important",
    color: "#E73700",
    height: "52px !important",
    transform: "rotate(-0deg) !important",
    "& .MuiCircularProgress-circle": { strokeWidth: "9px" },
    "& .MuiCircularProgress-svg": {
      overflow: "visible",
    },
  },
  hiddenText: {
    position: "absolute",
    bottom: "12%",
  },
  textOverlay: {
    fontWeight: 500,
    lineHeight: "23px",
    position: "absolute",
    marginTop: "19px",
    left: "20px",
    letterSpacing: "-0.01em",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    width: "40px",
    height: "42px",
  },
  hiddenBoilerPlate: {
    justifyContent: "center",
    flexDirection: "column",
  },
  customStyle: {
    justifyContent: "spaceBetween",
    minWidth: '40px',
    height: '40px',
    paddingInline: "12%",
    display: "flex",
  },
  row: {
    flexDirection: "row",
    display: "flex",
  },

  actionButtonContainer: {
    borderRadius: 17,
    border: '1px solid black',
    alignContent: 'center',
    padding: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },

  logo: {
    height: '59px',
    width: '142px'
  }

};