export const findMaxPosAndMaxNeg = (arr: number[], upperLimit: number) => {
    let max = 0
    let min = 0
    arr.forEach(num => {
        if (num > 0 && num < (upperLimit - 10)) {
          max = num > max ? num : max
        }
        if (num < 0) {
          if (min === 0) min = num
          else min = num > min ? num : min
        }
    })
    return { max, min }
}

export const toDuration = (time: string) => {
    if(time){
      let minute = parseInt(time.split(":")[0])
      let second = parseInt(time.split(":")[1])
      return minute * 60 + second
    }
    return 0
}