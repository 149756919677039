import React, { ChangeEvent, useState, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Box, { BoxProps } from "@material-ui/core/Box";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

interface SelectionItem {
  label: string;
  value: string;
  [key: string]: any;
}
interface CommonSelectionProps extends BoxProps {
  data?: SelectionItem[];
  label: string;
  selectionHeight?: number;
  value?: string;
  onChangeItem?: (item: SelectionItem) => void;
  renderLeftIcon?: any
}

const CommonSelection: React.FC<CommonSelectionProps> = ({
  data,
  label,
  value,
  selectionHeight,
  onChangeItem,
  renderLeftIcon,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const idFilter = useRef("idSelection");
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleClickItemType = (item: SelectionItem) => {
    handleClosePopover();
    onChangeItem?.(item);
  };
  const renderPopover = () => {
    return (
      <Popover
        id={idFilter.current}
        open={Boolean(anchorEl || null)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        PaperProps={{
          style: {
            borderRadius: 17,
          },
        }}
        data-testid="filter-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            ...webStyle.popoverContainer,
            width: anchorEl ? anchorEl.clientWidth : 0,
            height: selectionHeight,
            overflow: selectionHeight ? 'scroll' : "none",
          }}
        >
          {data && data.map((item: SelectionItem, index: number) => {
            const styleItem =
              item.value === value
                ? webStyle.activeItem
                : webStyle.inactiveItem;
                const onClickItem = () => handleClickItemType(item);
            return (
              <Box
                style={{ ...webStyle.itemSelection, ...styleItem }}
                key={item.value.toString()}
                data-test-id="selection-item"
                onClick={onClickItem}
              >
                <Typography style={webStyle.textItemType}>
                  {item.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    );
  };
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const getLabel = () => {
    if(data && data.length > 0){
    const itemSelect =  data.find((item) => item.value === value?.toString());
      return itemSelect ? itemSelect.label : ""
    }else{
      return value
    }
  }
  return (
    <Box {...props}>
      {renderPopover()}
      <Typography style={webStyle.label}>{label}</Typography>
      <Box onClick={handleOpenPopover} sx={webStyle.containerInput}>
        <Box sx={webStyle.leftSide}>
          {renderLeftIcon}
          <Typography style={webStyle.input}>{getLabel()}</Typography>
        </Box>
        <ExpandMoreOutlinedIcon style={webStyle.icon} fontSize="small"/>
      </Box>
    </Box>
  );
};
const webStyle = {
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
    fontWeight: 400
  },
  leftSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  containerInput: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    height: 49,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    fontSize: 16,
    marginLeft: 20,
    color: "#747474",
    fontWeight: 400
  },
  icon: {
    marginRight: 20,
  },
  popoverContainer: {
    display: "flex",
    flexDirection: "column",
    background: "#FBFAF7",
    padding: 4,
  },
  itemSelection: {
    height: 40,
    borderRadius: 17,
    padding: 8,
    paddingLeft: 17,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  activeItem: {
    background: "#EEECE7",
  },
  inactiveItem: {
    background: "#FBFAF7",
  },
  textItemType: {
    fontSize: 16,
    fontWeight: 400,
    color: "#313320",
  },
};

export default React.memo(CommonSelection);
