import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link,
  // Customizable Area Start
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  IconButton,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { imgRightArrow, imgRightDouble } from "./assets";
import CommonInputText from "../../../components/src/CommonInputText";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ReactPlayer from "react-player";
import CommonModalDelete from "../../../components/src/CommonModalDelete";
import CommonTagsField from "../../../components/src/CommonTagsField";
import CommonButton from "../../../components/src/CommonButton";
import { ImageComponent } from "../../../components/src/ImageBulkUpload";




const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E73700",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      color: "#313320",
    },
    body1: {
      fontSize: 14,
      fontWeight: 600,
    },
    body2: {
      fontSize: 14,
      color: "#747474",
    },
  },
});


// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUploadItem(videoFile: any, index: number, activeFile: any, thumbnailUrl: any, uploadProgress: any,) {
    return (
      <Box
        sx={activeFile === index ? webStyle.active : webStyle.uploadingItemContainer}
        key={index}
        data-testid={`selectedFile${index + 1}`}
        onClick={() => this.handleFileClick(index, videoFile)}
      >
        <ImageComponent
          key={index}
          imageBase64Path={thumbnailUrl?.length > 0 && thumbnailUrl[index]}
        />

        <Box sx={webStyle.itemUploadingContent}>
          <Typography variant="body1">{videoFile.name}</Typography>
          <Typography variant="body2">{(videoFile.size / (1024 * 1024)).toFixed(2)} MB</Typography>
        </Box>
        <Typography style={webStyle.textPercentUpload}>{uploadProgress[index] < 100 ? uploadProgress[index] : 100}%</Typography>
        {uploadProgress[index] < 100 ? (
          <CircularProgress  data-testid="circular-progress" style={{ marginRight: 21 }} size={28} color="secondary" />
        ) : (
          <CheckCircleOutlinedIcon data-testid="check-circle-icon" style={{ marginRight: 21 }} htmlColor="rgb(231, 55, 0)" />
        )}
        <CloseIcon
          style={webStyle.uploadCloseIcon}
          data-testid="removeFile"
          fontSize="medium"
          onClick={() => this.removeFile(index, videoFile)}
        />
      </Box>
    );
  }

  renderFileUpload() {
    return (
      <input
        multiple
        type="file"
        accept="video/*"
        data-video-preview
        onChange={(e) => {
          this.handleFileChange(e.target.files);
        }}
        data-testid="filePicker"
        ref={this.state.fileInputRef}
      />
    );
  }

  renderLeftContent() {
    const { selectedFiles, activeFile, thumbnailUrl, uploadProgress } = this.state;

    return (
      <Grid item xs={6}>
        <Box gridRowGap={22} sx={webStyle.contentWrapper}>
          <Box sx={webStyle.uploadContainer} onDrop={this.handleFileDrop}
          onDragOver={(e) => e.preventDefault()}>
            {this.renderFileUpload()}
            <Typography variant="subtitle1" style={webStyle.textSizeFiles}>
              Drag & drop your files here or choose file
            </Typography>
            <Typography style={webStyle.textSizeFile}></Typography>
          </Box>

          {selectedFiles && selectedFiles.length ? (
            selectedFiles.map((videoFile: any, index: number) =>
              this.renderUploadItem(videoFile, index, activeFile, thumbnailUrl, uploadProgress)
            )
          ) : (
            <></>
          )}
        </Box>
      </Grid>
    );
  }
  renderTagSelect() {
    const label = 'Add tags';
    const field = 'add_tag_list';
    const tagList = this.state.formDetails[this.state.activeFile]?.[field] || [];

    return (
      <CommonTagsField currentTag={tagList}  label={label} handlePositiveClick={this.handleChangeTag} listTag={this.state.tags} tagsCache={this.state.tagsCache}/>
    );
  }

  renderThumbnailInput() {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.handleThumbnail(e);
    };

    return (
      <input
        type="file"
        accept="image/*"
        onChange={onChange}
        data-testid="filePicker2"
        disabled={this.state.isDisableInput}
      />
    );
  }

  renderCancelButton() {
    return (
      <CommonButton
      height={46}
      width={104}
      label="Cancel"
      mode="transparent"
      onClickButton={() => {
        history.back()
      }}
    />
);

  }

  renderPreviewButton() {
    return (
      <Box
        sx={webStyle.buttonPreviewContainer}
        onClick={this.state.selectedFiles?.length && this.handleOpenModalPreview}
      >
        <Typography>Preview</Typography>
      </Box>
    );
  }

  renderCreateButton() {
    return (
      <CommonButton
            height={46}
            width={104}
            label="Create"
            mode="simple"
            renderRightIcon={<img src={imgRightArrow} style={webStyle.iconButtonCreate} />}
            onClickButton={() => {
              if(!this.state.isSubmitEnabled){
                return
              }
              this.handleSubmit();
            }}
          />
    );
  }

  renderRightContent() {
    return (
      <Grid item xs={6}>
        <Box gridRowGap={20} sx={webStyle.contentWrapper}>
          {this.renderTagSelect()}
          {this.renderSelect('Category', 'category', this.state.listCategory)}
          <Box sx={webStyle.buttonContainer} >
            {this.renderCancelButton()}
            {this.renderCreateButton()}
          </Box>
        </Box>
      </Grid>

    );
  }
  renderSelect(label: string, field: string, values: string[]) {
    const value = this.state.formDetails[this.state.activeFile]?.[field] || '';
    const onChange = (e: React.ChangeEvent<{ value: unknown }>) => {
      this.handleInputChange(this.state.activeFile, field, e.target.value as string);
    };

    return (
      <div>
        <Typography style={webStyle.label}>{label}</Typography>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-names"
          value={value}
          onChange={onChange}
          style={webStyle.input}
          disabled={this.state.activeFile === -1}
        >
          {values.map((value: string) => (
            <MenuItem
              key={value}
              value={value}
              style={{ fontWeight: value === this.state.formDetails[this.state.activeFile]?.[field] ? 800 : 100 }}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderPreviewModal() {
    return (<Modal
      open={this.state.openPreviewModal}
      onClose={this.handleCloseModalPreview}
      data-testid="modal-detail"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={webStyle.modalWapper}>
        <Box sx={webStyle.modalContainer}>
          <Box sx={webStyle.modalHeader}>
            <Typography
              style={{ fontSize: 16, fontWeight: 700 }}
              id="modal-modal-title"
            >
              Preview
            </Typography>
            <IconButton
              data-testid="button-close-edit-modal"
              onClick={this.handleCloseModalPreview}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box sx={webStyle.modalContentContainer}>
            <ReactPlayer
              url={this.state.base64url}
              controls={true} // Show native video controls
              playing={false} // Auto-play when the component mounts
              volume={0.8} // Set the initial volume (0 to 1)
              width="100%" // Set the width of the player
              height="auto" // Set the height of the player
              loop={false} // Loop the video
              muted={false} // Start muted
              playbackRate={1.0} // Set the playback speed
            />

            <Box sx={webStyle.modalButtonContainer}>

              <Box
                onClick={this.handleCloseModalPreview}
                sx={webStyle.modalButtonSave}
              >
                <Typography>Close</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.activeFile >= 0 && this.renderPreviewModal()}
        {this.state.loading && (
                <Box
                  sx={webStyle.loadingContainer}
                >
                  <CircularProgress style={{color: '#E73700'}} />
                </Box>
              )}
        {<CommonModalDelete
              message={ this.state.createfailmsg}
              handlePositiveButton={this.handlePositiveButton}
              handleNegativeButton={this.handlePositiveButton}
              showDeleteModal={this.state.createsuccessmodal}
              negativeButtonTitle="Cancel"
              showNegativeButton={false}
              positiveButtonTitle="OK"
              showPositiveButton={true}
              handleCloseModal={this.handlePositiveButton} />}
        <Box overflow='scroll' width="100%" height="100%">
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.headerContainer}>
              <Typography style={webStyle.leftTextHeader}>
                Video Library
              </Typography>
              <img src={imgRightDouble} style={webStyle.iconRightHeader} />
              <Typography style={webStyle.rightTextHeader}>
                Add Video
              </Typography>
            </Box>
            <Grid container spacing={6}>
              {this.renderLeftContent()}
              {this.renderRightContent()}
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    flex: 1,
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#faf9f6",
    padding: 30,
  },
  leftTextHeader: {
    fontSize: 20,
    color: "#313320",
    fontWeight: 400,
  },
  rightTextHeader: {
    fontSize: 20,
    fontWeight: 700,
    color: "#E73700",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    paddingTop: 50,
  },
  imageGroupUpload: {
    width: 104,
    height: 90,
    marginBottom: 16,
  },
  textSizeFile: {
    fontSize: 16,
    color: "#747474",
    marginTop: 10,
  },
  textSizeFiles: {
    fontSize: 17,
    color: "#000",
    marginTop: 10,
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 242,
    border: "1px dashed #bebeb7",
    background: "#f1f0ee",
    borderRadius: 17,
  },
  uploadingItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 17,
    background: "#fff",
    border: "0.7px solid #313320",
    width: "100%",
    height: 75,
  },
  imageUploadingItem: {
    width: 59,
    height: 56,
    borderRadius: 17,
    marginLeft: 10,
  },
  uploadCloseIcon: {
    marginRight: 10
  },
  itemUploadingContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    marginLeft: 10,
  },
  textPercentUpload: {
    fontSize: 14,
    fontWeight: 600,
    color: "#E73700",
    marginRight: 21,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gridColumnGap: 15,
    marginTop: 38,
  },
  iconButtonCreate: {
    width: 24,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonCreateContainer: {
    display: "flex",
    padding: 10,
    gridColumnGap: 8,
    flexDirection: "row",
    paddingLeft: 16,
    paddingRight: 16,
    background: "#E73700",
    borderRadius: 17,
    color: "#fff",
    fontSize: 16,
  },
  buttonCancelContainer: {
    color: "#747474",
    fontSize: 16,
    padding: 10,
    flexDirection: "row",
    paddingLeft: 16,
    paddingRight: 16,
  },
  buttonPreviewContainer: {
    color: "#747474",
    fontSize: 16,
    padding: 10,
    flexDirection: "row",
    paddingLeft: 16,
    paddingRight: 16,
    border: "1px solid #747474",
    borderRadius: 17,
  },
  iconRightHeader: {
    width: 24,
    height: 24,
    marginLeft: 9,
    marginRight: 9,
  },
  option: {
    borderRadius: "0px",
    background: "rgb(255, 255, 255)",
    height: "45px",
    width: "100%",
    boxShadow: "rgb(0 0 0 / 18%) -3px 4px 5px",
    margin: "5px 0px",
    fontWeight: 400,
  },
  label: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 8,
    fontWeight: 400,
    marginTop: 15,
  },
  labelnew: {
    fontSize: 16,
    color: "#313320",
    marginBottom: 5,
    fontWeight: 400
  },
  input: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    background: "#f1f0ee",
    height: 49,
    fontSize: 16,
    paddingLeft: 20,
    paddingTop: 12,
    paddingBottom: 12,
    width: "100%",
    fontWeight: 100,
    marginRight: "10px"
  },
  menuSelect: {
    "& .MuiInput-underline:after": {
      transform: "none",
      transition: "none",
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      transform: "none",
      transition: "none",
      borderBottom: "none",
    },
  },
  select: {
    borderRadius: 17,
    border: "1px solid #bebeb7",
    height: 49,
    fontSize: 16,
    paddingLeft: 20,
    paddingTop: 12,
    paddingBottom: 12,
    width: "100%",
    ".MuiInput-underline:after": {},
  },

  active: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 17,
    background: "#fff",
    border: "0.7px solid red",
    width: "100%",
    height: 75,
  },
  modalWapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 631,
    borderRadius: 16,
    background: "#fff",
    overflow: "auto",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 61,
    paddingLeft: 31,
    paddingRight: 20,
    background: "#edece6",
  },

  modalContentContainer: {
    paddingTop: 19,
    paddingRight: 31,
    paddingLeft: 31,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  modalButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 33,
    borderTop: "0.5px solid #EEECE7",
    height: 61,
  },
  modalButtonSave: {
    border: "1px solid #E73700",
    padding: 8,
    color: "#E73700",
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 13,
    "&:hover": {
      cursor: "pointer",
    },
  },
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    zIndex:333,
    opacity: 0.5,
  },
};
// Customizable Area End
