// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { SetItem } from "./types";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  [x: string]: any;
  navigation: any;
  id: string;
}

interface S {
  template_id: string;
  templateData: any;
  focus_list: string[];
  new_focus_list: string[];
  openModal: boolean;
  selectedUnit: string;
  error_message: string;
  saveEnabled: boolean;
  totalWorkoutTime: string;
}

interface SS {
  id: any;
}

type StateKeys = "title" | "introduction" | "demo" | "demo_video_shift" | "warmup" | "number_of_loops" | "change_zone" | "cooling_down" | "station" | "exercise_sets_attributes" | "station_sequence";

export default class CreateCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  addEditTemplateApiCallId: any;
  showTemplateApiCallId: any;
  getFocusListApiCallId: any;
  addFocusApiCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      template_id: "",
      templateData: {
        "station_sequence": ["3", "4", "9", "2", "5", "8", "1", "6", "7"],
        "exercise_sets_attributes": [{ set_number: "1", set_time: "0", rest_time: "0" }],
      },
      focus_list: [],
      new_focus_list: [],
      openModal: false,
      selectedUnit: "Seconds",
      error_message: "",
      saveEnabled: false,
      totalWorkoutTime: "00:00",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getTemplateID()
    this.getFocusList()
  }

  getTemplateID() {
    const queryParams = new URLSearchParams(window.location.search);
    const receivedDataParam = queryParams.get('data');
    const receivedData = JSON.parse(receivedDataParam ?? 'null');
    console.log("received data==>", receivedData)

    const template_id = receivedData?.id ?? '';
    this.setState({ template_id });
    console.log("template id data==>", template_id)

    if (template_id) {
      this.showTemplateAPICall(template_id);
      console.log("template id has value")
    }
  }




  handleAddSets = () => {
    const { exercise_sets_attributes } = this.state.templateData;
    const nextIndex = exercise_sets_attributes.filter((setItem: any) => setItem._destroy === undefined).length + 1
    const newSetRow = {
      set_number: nextIndex.toString(),
      set_time: "0",
      rest_time: "0"
    };

    const newSetList = [...exercise_sets_attributes, newSetRow];

    newSetList.sort((a, b) => (a._destroy ? 1 : 0) - (b._destroy ? 1 : 0));

    this.handleUpdateState("exercise_sets_attributes", newSetList);
  };

  handleDeleteSet = (index: number) => {
    const newArr = [...this.state.templateData["exercise_sets_attributes"].filter((item: any) => !("_destroy" in item))];

    if (index >= 0 && index < newArr.length) {
      if (newArr[index].id !== undefined) {
        newArr[index]._destroy = true;
      } else {
        newArr.pop();
      }
      this.handleUpdateState("exercise_sets_attributes", newArr);
      console.log("newArr data==>", newArr)
    }
  };
  /* istanbul ignore next */
  handleSetDataChange = (
    index: number,
    key: string,
    newValue: string
  ) => {
    const newArr = [...this.state.templateData["exercise_sets_attributes"].filter((item: any) => !("_destroy" in item))];
    const deletedSets = [...this.state.templateData["exercise_sets_attributes"].filter((item: any) => ("_destroy" in item))]
    const oldValue = newArr[index][key];
    if(oldValue && typeof oldValue === 'string' && oldValue.includes('Minutes')){
      newArr[index][key] = `${newValue} Minutes`;
    } else {
      newArr[index][key] = newValue;
    }
    this.handleUpdateState("exercise_sets_attributes", [...newArr, ...deletedSets])
  };

  getFormatValue = (value: any) => {
    if(typeof value === 'string' && value?.includes("Minutes")){
      return /[^\d.]/g
    }
    return /\D/g
  }
 
  handleUpdateState = (key: StateKeys, value: any) => {
    const { templateData } = this.state;
    const updatedObject = { ...templateData };
    if (key === 'title')
      this.setState({ error_message: "" })

    if (updatedObject.hasOwnProperty(key) && typeof updatedObject[key] === 'string' && updatedObject[key].includes('Minutes')) {
      updatedObject[key] = `${value} Minutes`;
    } else {
      updatedObject[key] = value;
    }

    this.setState({ templateData: updatedObject }, () => {
      this.checkFields()
      this.handleTemplateTime()

    });
  };

  checkFields = () => {
    const { title, introduction, demo, demo_video_shift, warmup, number_of_loops, station, change_zone, cooling_down } = this.state.templateData;

    const isNotEmpty = (value: any) => value && value !== "" && value !== " Minutes" && value !== " Seconds";

    if (
      isNotEmpty(title) &&
      isNotEmpty(introduction) &&
      isNotEmpty(demo) &&
      isNotEmpty(demo_video_shift) &&
      isNotEmpty(warmup) &&
      isNotEmpty(number_of_loops) &&
      isNotEmpty(station) &&
      isNotEmpty(change_zone) &&
      isNotEmpty(cooling_down) &&
      this.state.focus_list.length !== 0
    ) {
      this.setState({ saveEnabled: true });
    } else {
      this.setState({ saveEnabled: false });
    }
  }

  handleChangeSetNumber = (set_number: any, index: number) => {
    let listSetTemp = [...this.state.templateData["exercise_sets_attributes"].filter((item: any) => !("_destroy" in item))];
    listSetTemp = listSetTemp.map((itemSet: SetItem, innerIndex: number) => {
      if (innerIndex === index) {
        return {
          ...itemSet,
          set_number: set_number.value
        };
      }
      return itemSet;
    });
    this.handleUpdateState("exercise_sets_attributes", listSetTemp)
  };

  getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    border: isDragging ? "0.7px solid #E73700" : "0.7px solid #313320",
    ...draggableStyle
  });

  onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const swappedPosition = this.swapElements(
      this.state.templateData["station_sequence"],
      source.index,
      destination.index
    );
    this.handleUpdateState("station_sequence", swappedPosition)

  };



  async receive(from: string, message: Message) {
    const restApiResponseMessage = getName(MessageEnum.RestAPIResponceMessage);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    runEngine.debugLog("API Message Received", message);

    if (restApiResponseMessage === message.id) {
      if (responseJson.data) {
        if (apiRequestCallId === this.addEditTemplateApiCallId) {
          history.back();
        } else if (apiRequestCallId === this.showTemplateApiCallId) {
          const templateObj = { ...this.state.templateData, ...responseJson.data.attributes };
          const updatedObject = {
            ...templateObj,
            exercise_sets_attributes: templateObj.exercise_sets,
          };

          delete updatedObject.exercise_sets;
          const namesArray = updatedObject.focus.map((item: any) => item.name);
          const workout_time = updatedObject.workout_time ?? "00:00";
          this.setState({ focus_list: namesArray, templateData: updatedObject, saveEnabled: true, totalWorkoutTime: workout_time });
        }
      } else if (responseJson.tags && apiRequestCallId === this.addFocusApiCallId) {
        const newList = [...this.state.focus_list, ...this.state.new_focus_list];
        this.setState({ focus_list: newList }, () => {
          this.checkFields()
        });
        console.log("newList data==>", newList)
        this.handleCloseModal();
      } else if (responseJson.errors) {
        const errorMessage = responseJson.errors.title[0];
        this.setState({ error_message: errorMessage });
      }
    }
  }

  handleFileChange = (event: any) => {
    const files = event.target.files;
    const file = files[0]
    const originType = file.type
    const content_type = originType.replace('image', 'thumbnail')
    
    const filename = file.name
    this.convertImageToBase64(file, (thumbnail: any) => {
      this.setState({ templateData:
        {...this.state.templateData, thumbnail, content_type, filename}})
    })
   
  };
  convertImageToBase64 = (file:any, callback: any) => {
    let thumbnail = null
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result)
    };
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  swapElements(arr: string[], sourceIndex: number, destinationIndex: number) {
    const newArr = [...arr];
    if (
      sourceIndex < 0 ||
      sourceIndex >= newArr.length ||
      destinationIndex < 0 ||
      destinationIndex >= newArr.length
    ) {
      return newArr;
    }
    const temp = newArr[sourceIndex];
    newArr[sourceIndex] = newArr[destinationIndex];
    newArr[destinationIndex] = temp;
    return newArr;
  }

  addEditTemplateAPICall = () => {
    const { templateData, focus_list, template_id } = this.state;
    const { categoryApiContentType, templateAPIEndPoint, httpPostType, httpPutType } = configJSON;

    const updatedData = {
      ...templateData,
      focu_list: focus_list.join(", "),
      workout_time: this.state.totalWorkoutTime,
    };

    const dataToSend = {
      ...templateData,
      focu_list: focus_list.join(", "),
      workout_time: this.state.totalWorkoutTime,
    };
    const updatedTemplateData = template_id === "" ? updatedData : dataToSend;

    const data = {
      template: updatedTemplateData
    };

    const header = {
      "Content-Type": categoryApiContentType
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addEditTemplateApiCallId = requestMessage.messageId;
    const requestMethod = template_id === "" ? httpPostType : httpPutType
    const endPoint = template_id === "" ? templateAPIEndPoint : `${templateAPIEndPoint}/${template_id}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showTemplateAPICall = (templateID: string) => {
    const { categoryApiContentType, templateAPIEndPoint, httpGetType } = configJSON;
    const header = {
      "Content-Type": categoryApiContentType
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.showTemplateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${templateAPIEndPoint}/${templateID}?zone_id=1`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), httpGetType);

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  addFocusTemplateAPICall = (data: string[]) => {
    const { categoryApiContentType, templateAPIEndPoint, createFocusAPIEndPoint, httpPostType } = configJSON;
    const header = {
      "Content-Type": categoryApiContentType
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addFocusApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${templateAPIEndPoint}/${createFocusAPIEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ "name": data.join(", ") }));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), httpPostType);

    runEngine.sendMessage(requestMessage.id, requestMessage);

    // Update state after sending the request
    this.setState({ new_focus_list: data });
  };

  getFocusList = () => {
    const { focusListAPIEndPoint, categoryApiContentType, templateAPIEndPoint, httpGetType } = configJSON;
    const header = {
      "Content-Type": categoryApiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFocusListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${templateAPIEndPoint}/${focusListAPIEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), httpGetType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteATag = (tagDeleted: string) => {
    this.setState(prevState => ({
      focus_list: prevState.focus_list.filter(tag => tag !== tagDeleted)
    }), () => {
      this.checkFields()
    });
    console.log("handleDeleteATag data==>", this.state.focus_list)
  }

  handleCloseModal = () => this.setState({ openModal: false });

  handleOpenModal = () => this.setState({ openModal: true });


  getLocalValue = (data: any, useValue?: boolean) => {
    const valueData = useValue ? data.value : this.state.templateData[data.key]
    if (valueData !== undefined && (valueData.includes("Minutes") || valueData.includes("Seconds"))) {
      const localValue = valueData.split(" ")
      let timeNumber = localValue[0] 
      if(data.value?.includes("Minutes") && data.unit === "Seconds" && timeNumber.includes(".")){
        timeNumber = 0
      }
      return timeNumber
    }else{
      return valueData ?? ''
    }
   
  }

  handleUnitSelection = (data: any) => {
    let updatedData: any = {};
    
    if (!data.key.includes("time")) {
      const localValue = this.getLocalValue(data)
        updatedData = {
          ...this.state.templateData,
          [data.key]: localValue + " " + data.unit,
        };
      console.log(updatedData);
    } else {
      const localValue = this.getLocalValue(data, true)
      const splitKey = data.key.split("[");

      updatedData = { ...this.state.templateData };
      const attributeName = splitKey[0];
      const setNumber = parseInt(splitKey[1].replace("]", ""));

      if (updatedData.exercise_sets_attributes[setNumber]) {
        updatedData.exercise_sets_attributes[setNumber][attributeName] =
        localValue + " " + data.unit;
      }
    }

    this.setState({ templateData: updatedData }, () => {
      this.handleTemplateTime()
    });
  };

  goBack = () => {
    history.back();
  }
  getSecondsOfField = (fieldValue: any) => {
    if(fieldValue.includes("Minutes")){
      const data = fieldValue.split(" ")
      return parseFloat(data[0]) * 60
    }
    if(fieldValue.includes("Seconds")){
      const data = fieldValue.split(" ")
      return parseInt(data[0])
    }
    return parseFloat(fieldValue)
  }
  handleTemplateTime = () => {
    const { templateData } = this.state;
    let section1 = 0, section2 = 0, section3 = 0, section5 = 0, zoneTime = 0, totalTime = 0, deleteTime = 0;

    // Calculate Section1
    if (templateData.introduction) section1 += this.getSecondsOfField(templateData.introduction);
    if (templateData.demo) section1 += this.getSecondsOfField(templateData.demo);
    if (templateData.warmup) section1 += this.getSecondsOfField(templateData.warmup);
    if (templateData.cooling_down) section1 += this.getSecondsOfField(templateData.cooling_down);

    // Calculate a station
    if (templateData.exercise_sets_attributes) {
      (templateData.exercise_sets_attributes).map((item: any) => {
        section2 = section2 + this.getSecondsOfField(item.set_time) + this.getSecondsOfField(item.rest_time)
      })
    }
    // calculate with all station
    if (templateData.station){
      section2 *= parseInt(templateData.station)
    }
    // calculate a zone with full lap
    if (templateData.number_of_loops){
      section3 = section2 * parseInt(templateData.number_of_loops);
    }
    // remove last rest time
    if(templateData.exercise_sets_attributes){
      deleteTime = templateData.exercise_sets_attributes[templateData.exercise_sets_attributes.length - 1].rest_time
    }
    // add change zone time
    if (templateData.change_zone){
      section5 = this.getSecondsOfField(templateData.change_zone) * 3;
    }
    totalTime = section1 + section5 +  (section3 * 3) - deleteTime
    this.setState({ totalWorkoutTime: this.timeConversion(totalTime.toString()) })

  }

  timeConversion = (timeString: string) => {
    const match = RegExp(/^(\d+)\s*(?:Seconds|Minutes)?$/i).exec(timeString);
    if (match) {
      const minutes = parseInt(match[1]);
      if (timeString.includes("Minutes")) {
        return `${minutes.toString().padStart(2, "0")}:00`;
      } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours.toString().padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}`;
      }
    }
    return timeString;
  }

}
// Customizable Area End
