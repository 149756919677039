import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  workouts: {
    id: number;
    workout_name: any;
    template_id: number;
    introduction: any;
    demo: any;
    warmup: any;
    cooling_down: any;
    class_type: any;
    title: any;
    focus_tag: any;
    workout_image_url: any;
    created_at: any;
    updated_at: any;
    draft_status: any;
    is_used: any;
  }[][]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class WorkoutLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getWorkoutsApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      workouts: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getAllWorksout();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getWorkoutsApiCallId && responseJson) {
      const groupedData: any = {};
      responseJson.forEach((item: any) => {
        if (!groupedData?.[item.template_id]) {
          groupedData[item?.template_id] = [];
        }
        groupedData[item.template_id].push(item);
      });
      const groupedDataArr: any = Object.values(groupedData);

      this.setState({ workouts: groupedDataArr });
    }
  }

  getAllWorksout() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.getWorkoutsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWorkoutAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
