// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
// import firebase from 'firebase'
// import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import DrawerMenu from "../../components/src/DrawerMenu";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import DragDropInterface2 from "../../blocks/dragdropinterface/src/DragDropInterface2";
import ZoneDisplay from "../../blocks/dragdropinterface/src/ZoneDisplay";
import Preview from "../../blocks/dragdropinterface/src/Preview.web";
import Cfmultidisplaymanager2 from "../../blocks/cfmultidisplaymanager/src/Cfmultidisplaymanager2";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Stopwatch from "../../blocks/stopwatch/src/Stopwatch";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfoptisigndriverintegration from "../../blocks/cfoptisigndriverintegration/src/Cfoptisigndriverintegration";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Videolibrary2 from "../../blocks/videolibrary2/src/Videolibrary";
import Videos from "../../blocks/videos/src/Videos";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import TimeTracker from "../../blocks/timetracker/src/TimeTracker";
import Cfhighresolutiondesign from "../../blocks/cfhighresolutiondesign/src/Cfhighresolutiondesign";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Cfpresavedworkout from "../../blocks/cfpresavedworkout/src/Cfpresavedworkout";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import WorkoutLibrary from "../../blocks/categoriessubcategories/src/WorkoutLibrary.web";
import CreateCategories from "../../blocks/categoriessubcategories/src/CreateCategories.web";
import UploadCSV from "../../blocks/bulkuploading/src/UploadCSV.web";




const routeMap = {
DragDropInterface:{
 component:DragDropInterface2,
path:"/DragDropInterface/:templateId/:tag"},
DragDropInterface2:{
 component:DragDropInterface2,
path:"/DragDropInterface2"},
ZoneDisplay:{
  component:ZoneDisplay,
 path:"/ZoneDisplay/:zoneId"},
Preview:{
 component:Preview,
path:"/Preview/:id"},

Cfmultidisplaymanager2:{
 component:Cfmultidisplaymanager2,
path:"/Cfmultidisplaymanager"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Stopwatch:{
 component:Stopwatch,
path:"/Stopwatch"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfoptisigndriverintegration:{
 component:Cfoptisigndriverintegration,
path:"/Cfoptisigndriverintegration"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"}, 
Videolibrary2:{
 component:Videolibrary2,
path:"/Videolibrary2"},
Videos:{
 component:Videos,
path:"/Videos"},
Dashboard:{
 component:Dashboard,
 exact: true,
path:"/"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
Cfhighresolutiondesign:{
 component:Cfhighresolutiondesign,
path:"/Cfhighresolutiondesign"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
UploadCSV:{
  component:UploadCSV,
 path:"/csvupload"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Cfpresavedworkout:{
 component:Cfpresavedworkout,
path:"/Cfpresavedworkout"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
WorkoutLibrary:{
  component:WorkoutLibrary,
 path:"/workoutlibrary"},
CreateCategories:{
 component:CreateCategories,
path:"/Createcategories"},

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
//   authDomain: "rnmasterapp-c11e9.firebaseapp.com",
//   databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
//   projectId: "rnmasterapp-c11e9",
//   storageBucket: "rnmasterapp-c11e9.appspot.com",
//   messagingSenderId: "649592030497",
//   appId: "1:649592030497:web:7728bee3f2baef208daa60",
//   measurementId: "G-FYBCF3Z2W3"
// });

class App extends Component {
   
  render() {

    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');
    
    const currentRoute = window.location.pathname; 
    const isPreviewRoute = currentRoute.startsWith('/Preview')
    const isZoneDisplayRoute = currentRoute.includes('ZoneDisplay')
    const shouldShowDrawerMenu = !isPreviewRoute && !isZoneDisplayRoute;

    return (
      <View style={{ height: '100vh', width: '100vw', flexDirection: 'row', backgroundColor: '#faf9f6' }}>
        {shouldShowDrawerMenu && <DrawerMenu />} 
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
