import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
export const config = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    minute: number;
    second: number;
    startTime: boolean;
    remainingTime: number;
    timeTrackerID: number;
    // Customizable Area End
}
interface Datapost {
    start_time: number
}
interface Dataput {
    id: number
}
interface Response {
    timetracker: {
        id: number,
        start_time: number,
        end_time: number,
        created_at: number,
        updated_at: number
    }
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class TimeTrackerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    stopTraval: number | NodeJS.Timeout = 0;
    startTimeApiCallId: string = '';
    stopTimeApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
            // Customizable Area End
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            minute: 0,
            second: 0,
            startTime: false,
            remainingTime: 0,
            timeTrackerID: 0
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentWillUnmount() {
        clearInterval(this.stopTraval)
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.startTimeSuccessCallBack(responseJson);
        }

        // Customizable Area End
    }

    // Customizable Area Start
    minuteSet = (number: string) => {
        let value = Number(number)
        this.setState({ minute: value })
    }

    secondSet = (number: string) => {
        let value = Number(number)
        this.setState({ second: value })
    }

    startCount = () => {
        let seconds = 0
        let total = 0
        let minutes = 0
        seconds = this.state.second
        minutes = this.state.minute * 60
        total = minutes + seconds
        this.setState({
            startTime: true,
            remainingTime: total
        }, () => {
            this.startBtn()
            this.startTime()
        })
    }

    startTime = async () => {
        let startTimeData = {
            start_time: this.state.remainingTime
        }

        this.startTimeApiCallId = await this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: 'bx_block_timetracker/time_trackers',
            body: startTimeData
        })
    }

    startTimeSuccessCallBack = async (responseJson: Response) => {
        this.setState({
            timeTrackerID: responseJson && responseJson.timetracker && responseJson.timetracker.id
        })
    }

    stopTime = async () => {
        let stopTimeData = {
            id: this.state.timeTrackerID
        }
        this.startTimeApiCallId = await this.apiCall({
            contentType: 'application/json',
            method: 'PUT',
            endPoint: 'bx_block_timetracker/time_trackers/stop',
            body: stopTimeData
        })
    }

    apiCall = async (timeData: { contentType: string, method: string, endPoint: string, body: Datapost | Dataput }) => {
        const { contentType, method, endPoint, body } = timeData;
        const header = {
            "Content-Type": contentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    startBtn = () => {
        this.stopTraval = +setInterval(() => {
            if (this.state.remainingTime > 0) {
                this.setState(prevState => ({
                    remainingTime: prevState.remainingTime - 1
                }));
            } else {
                //intanbul ignore next
                clearInterval(this.stopTraval);
                this.stopTime()
            }
        }, 1000)
    }
    //istanbul ignore next
    stopBtn = () => {
        clearInterval(this.stopTraval)
        this.stopTraval = 0;
        this.stopTime()
    }
    //istanbul ignore next
    resetBtn = () => {
        clearInterval(this.stopTraval)
        this.setState({ minute: 0, second: 0, startTime: false })
    }
    // Customizable Area End
}
