// Customizable Area Start
import React from "react";

import { Box, Typography } from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgTemplate } from "./assets";
import ExpandableItem from "../../../components/src/ExpandableItem";
import WorkoutLibraryController, {
  Props,
} from "./WorkoutLibraryController";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    },
    secondary: {
      main: "#eb7c58"
    }
  }
});

export interface WorkOutItem {
  id: number;
  workout_name: any;
  template_id: number;
  introduction: any;
  demo: any;
  warmup: any;
  cooling_down: any;
  class_type: any;
  title: any;
  focus_tag: any;
  workout_image_url: any;
  created_at: any;
  updated_at: any;
  draft_status: any;
  is_used: any;
}

class WorkoutLibrary extends WorkoutLibraryController {
  constructor(props: Props) {
    super(props);
  }

  renderWorkoutHeader(item: WorkOutItem[]) {
    return (
      <Box
        data-test-id="workout-item"
        key={item[0].id}
        style={{
          marginTop: 15,
        }}
        sx={webStyle.workoutItemContainer}
      >
        <Box sx={webStyle.imageTempate}>
          <img src={imgTemplate} width={24} height={24} />
        </Box>
        <Typography style={webStyle.textItemworkout}>
          {item[0].class_type}
        </Typography>
      </Box>
    )
  }

  renderWorkoutChildren(item: WorkOutItem[]) {
    return (
      <Box sx={webStyle.workoutListWrapper}>
        {item?.map((workoutItem: any) => (
          <Box sx={webStyle.workoutItemWrapper} key={workoutItem.id}>
            <Box sx={webStyle.workoutItemTitleWrapper}>
              <img style={webStyle.workoutThumb} src={workoutItem.workout_image_url} />
              <Typography style={{
                flex: 1,
                fontWeight: "bold",
                fontSize: 16,
              }}>{workoutItem?.workout_name}</Typography>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.navigation.navigate("DragDropInterface", { templateId: workoutItem.template_id, tag: workoutItem.focus_tag })
                }}>
                <EditIcon fontSize="small" />
              </Box>
            </Box>
            <Typography style={{
              color: "rgb(116, 116, 116)",
              fontSize: 16
            }}>{`${workoutItem?.focus_tag} | ${moment(workoutItem?.updated_at).format("MMM Do YY")}`}</Typography>
          </Box>
        ))}
      </Box>
    )
  }

  render() {
    return (
      <ThemeProvider theme={theme} >
        <Box sx={webStyle.mainWrapper}>
          <Typography
            variant="h1"
            style={{
              fontSize: 20,
              color: "rgb(231, 55, 0)",
              fontWeight: "bold",
              marginBottom: 50,
            }}
          >
            Workout Library
          </Typography>
          <Box
            data-test-id="workout-list"
            sx={webStyle.templateListWrapper}>
            {this.state.workouts?.map((item) =>
              <ExpandableItem
                key={item[0].id}
                renderHeader={() => this.renderWorkoutHeader(item)}
                renderChildren={() => this.renderWorkoutChildren(item)}
              />
            )}
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default WorkoutLibrary

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#faf9f6",
    paddingTop: 25,
    paddingLeft: 37,
    paddingRight: 30,
    width: "100%",
    overflow: "scroll"
  },
  headerText: {
    fontSize: 24,
    marginBottom: 100,
  },
  contentContainer: {

  },
  workoutItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 8,
    flex: 1,
    height: 88,
    background: "#fff",
    // "&:hover": {
    //   cursor: "pointer",
    // },
  },
  imageTempate: {
    padding: 16,
    borderRadius: 8,
    background: "#FFEAE3"
  },
  textItemworkout: {
    color: "#313320",
    fontSize: 17,
    fontWeight: 700,
    marginLeft: 16,
    flex: 1,
  },
  icon: {
    width: 24,
    height: 24,
    color: "#939393",
    marginRight: 22
  },
  itemworkoutSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 2,
    "&:hover": {
      cursor: "pointer",
    },
  },
  templateListWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  workoutListWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  workoutItemWrapper: {
    width: "30%",
    marginLeft: 15,
    marginBottom: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 10,
    backgroundColor: "rgb(238, 236, 231)"
  },
  workoutItemTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  workoutThumb: {
    width: 50,
    height: 50,
    borderRadius: 10,
    marginRight: 10
  },
};
// Customizable Area End
