// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import PreviewFlow from "../../../components/src/PreviewFlow";

import ZoneDisplayController, {
  Props,
} from "./ZoneDisplayController";
import { background } from "./assets";
const companyLogo = require("../assets/firm_logo.png");

export default class ZoneDisplay extends ZoneDisplayController {
  constructor(props: Props) {
    super(props);
  }
  renderWaitingScreen = () => {
    return <Box sx={webStyle.waitingContainer}>
        {this.state.dataWorkout && this.state.isHavingNextWorkout ? <>
          <Typography style={webStyle.textWaiting}>Next Workout</Typography>
          <img src={require('../../../components/src/unified_logo.png')} style={{width: 280, paddingLeft: 20, paddingRight: 20, height: 180, borderRadius: 20,  objectFit: 'contain',  background: "#313320"}} alt="image workout"/>
          <Typography style={webStyle.textWaiting}>{`Starting in ${this.state.minuteForNextWorkout} minutes`}</Typography>

        </> : 
        <>
          <img src={require('../../../components/src/unified_logo.png')} style={{width: 280, paddingLeft: 20, paddingRight: 20, height: 180, borderRadius: 20,  objectFit: 'contain',  background: "#313320"}} alt="image workout"/>
        </> }
    </Box>
  }
  
  renderTypography(label: string, value: string, color: string) {
    return (
      <Typography variant="subtitle1" component="div">
        <span style={{ color }}>{value} </span>{label}
      </Typography>
    );
  }
  render() {
    const isPlaying =
      this.state.displayPhases.length > 0 && this.state.isInWorkoutTime;

    return (
      <>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: isPlaying ? "flex" : "none",
          }}
        >
          {this.state.dataWorkout &&<PreviewFlow
            isZoneDisplay={true}
            currentSlot={this.state.currentSlot}
            displayFlow={this.state.displayPhases}
            station={this.state.dataWorkout?.data?.attributes?.station ?? 9}
            zoneData={this.state.zoneData}
            introductionVideo={{
              video_url:
              this.state.dataWorkout.video_info.introduction.video_url,
            }}
            warmupVideo={{
              video_url:  this.state.dataWorkout.video_info.warmup.video_url,
            }}
            coolingVideo={{
              video_url:
              this.state.dataWorkout.video_info.cooling_down.video_url,
            }}
            laps={this.state.dataWorkout?.data.attributes.number_of_loops}
            sets={this.state.dataWorkout?.data.attributes.exercise_sets.length}
            ref={this.coreDisplayRef}
            selectedClassType={this.state.templateData}
            onEnd={this.onEndAWorkoutPlaying}
          />}
        </Box>

        <Box
          sx={{
            display: isPlaying ? "none" : "flex",
            fontFamily: "Roboto-Medium",
            flexDirection: "column",
            width: "100%",
            bgcolor: "#2A2D1E",
            position: 'relative'
          }}
        >
          <img src={background} style={{width: '100%', height: '100%'}} alt="image background"/>
          <Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
            {this.state.loading && (
              <Box sx={webStyle.loadingContainer}>
                <CircularProgress style={{ color: "#E73700" }} />
              </Box>
            )}
            {this.renderWaitingScreen()}
          </Box>
          
        </Box>
      </>
    );
  }
}


const webStyle = {
  container: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#2A2D1E"
  },
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    opacity: 0.5,
  },
    waitingContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        height: "100%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    textWaiting: {
        fontSize: 40,
        fontWeight: 700,
        color: "#FFF",
    },
    topBarStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginBottom: "20px"
    },
    topBarBox: {
      width: "190px",
      height: "65px",
      borderRadius: "8px",
      border: "1px solid #C2C2C2",
      background: "#FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    customStyle: {
      justifyContent: "spaceBetween",
      minWidth: '40px',
      height: '40px',
      paddingInline: "12%",
      display: "flex",
    },
    hrStyle: {
      height: "1px",
      width: "80px",
      border: "none",
      background: "#EEECE7",
      marginBlock: 0
    },
    templatePic: {
      width: "443px",
      height: "740px"
    },
    logo: {
      height: '59px',
      width: '142px'
    }
};

// Customizable Area End
