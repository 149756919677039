import React from "react";

import {
  Box,
  Typography,
  InputBase,
  Popover,
  RootRef,
  Grid,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Replay from "@material-ui/icons/Replay";
import {
  imgSearch,
} from "./assets";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DragDropInterfaceController2, {
  Props,
} from "./DragDropInterfaceController2";
import TransitionsModal from "../../../components/src/ModalPreview.web";
import CommonModalDelete from "../../../components/src/CommonModalDelete";
import ThumbVideo from "../../../components/src/ThumbVideo";


export default class DragDropInterface2 extends DragDropInterfaceController2 {
  constructor(props: Props) {
    super(props);

  }

  render() {
    return (
      <>
        <Box width="100%" sx={{ overflow: "scroll" }}>
          <Box sx={webStyle.mainWrapper}>
            {this.state.loading && (
              <Box
                sx={webStyle.loadingContainer}
              >
                <CircularProgress style={{ color: '#E73700' }} />
              </Box>
            )}
            {<CommonModalDelete
              message={this.state.popupMessage}
              handlePositiveButton={this.handlePositiveButton}
              handleNegativeButton={this.handlePositiveButton}
              showDeleteModal={this.state.showDeleteModal}
              negativeButtonTitle="Cancel"
              showNegativeButton={false}
              positiveButtonTitle={this.state.positiveButtonText}
              showPositiveButton={true}
              handleCloseModal={this.handlePositiveButton}
              destination={(this.state.templateOverviewList && this.state.templateOverviewList.length === 0) ? "/Categoriessubcategories" : ""} />}
            <Typography style={webStyle.textHeader}>Workout Editor</Typography>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable
                droppableId="videoDroppable">
                {(provided) => (
                  <>
                    <RootRef rootRef={provided.innerRef}>
                      <Box sx={{ ...webStyle.row, }}>
                        <Box sx={{ ...webStyle.innerWrapper, width: "65%", }} data-testid="class-type-container">
                          {this.renderHeading()}
                          {this.renderStatus()}
                          <Typography style={{ color: '#747474', fontWeight: 400, fontSize: 14, marginTop: 25, }}>Drag and drop your videos here</Typography>
                          {this.renderDragDropVideo()}
                          {this.renderZone()}
                          {this.renderSets()}
                          <Typography style={{ color: '#747474', fontWeight: 400, fontSize: 14, marginTop: 25, }}>Drag and drop your videos here</Typography>
                          <Box sx={webStyle.row}>
                            {this.cooling()}
                          </Box>
                        </Box>
                        <Box sx={{ width: "30%", marginLeft: 15, }}>
                          {this.renderVideoLibrary()}
                        </Box>
                      </Box>
                    </RootRef>
                    {provided.placeholder}

                  </>
                )
                }
              </Droppable>
            </DragDropContext>

          </Box>
          <TransitionsModal
            open={this.state.openModal}
            close={this.handleClickPreview}
            tempalteImages={this.state.tempalteImages}
            isHorizontalLayout={this.state.isHorizontalLayout}
            introductionVideo={this.state.introductionVideo}
            warmupVideo={this.state.warmupVideo}
            coolingVideo={this.state.coolingVideo}
            zone1={this.state.zone1}
            zone2={this.state.zone2}
            zone3={this.state.zone3}
            laps={this.state.laps}
            sets={this.state.sets}
            restTime={this.state.restTime}
            selectedZone={this.state.selectedZone}
          />

        </Box>
      </>
    );
  }

  timeConversion = (timeString: string) => {
    const matchString = timeString.match(/^(\d+)\s*(?:Seconds|Minutes)?$/i);
    if (matchString) {
      const minutes = parseInt(matchString[1]);
      if (timeString.includes("Minutes")) {
        return `${minutes.toString().padStart(2, "0")}:00`;
      } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours.toString().padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}`;
      }
    }
    return timeString;
  }


  renderSelectBox(icon: any, text: any, onClick: any, color: any, dataTestId: any) {
    return (
      <Box
        onClick={onClick}
        sx={webStyle.selectClassContainer}
        data-testid={dataTestId}
      >
        <Typography>{text}</Typography>
        {icon && <ExpandMoreIcon fontSize="small" />}
      </Box>
    );
  }

  renderHeading() {
    const {
      selectedFocus,
      isHorizontalLayout,
    } = this.state;
    return (
      <Box sx={{ ...webStyle.cardContainer, ...webStyle.row, justifyContent: 'space-between' }}>
        <Box sx={{ ...webStyle.cardContainer, ...webStyle.row }}>
          {this.renderClassTypePopover()}
          {this.renderSelectBox(
            <ExpandMoreIcon fontSize="small" />,
            this.state.selectedClassType.attributes?.title,
            this.handleClickClassTypePopover,
            undefined,
            "select-class-type"
          )}
          {this.renderFocusPopover()}
          {this.renderSelectBox(
            <ExpandMoreIcon fontSize="small" />,
            selectedFocus,
            this.handleClickFocusPopover,
            undefined,
            "select-focus"
          )}
           <input
            style={{ ...webStyle.inputName, width: "50%", outline: 'none' }}
            type="text"
            data-test-id="input-workout-name"
            value={this.state.workoutName}
            onChange={(e) => this.onChangeWorkoutName(e.target.value)}
            placeholder="Input workout name"
            />
        </Box>
        
        <Box data-testid="clear" sx={{ ...webStyle.clearPreviewSaveButton, ...webStyle.row, marginRight: 10 }} onClick={this.handleClickClear}>
          <Replay fontSize="small" style={{ color: '#313320' }} />
          <Typography variant="body1" style={{ ...webStyle.clearPreviewSaveButtonText, color: '#313320' }}>Clear</Typography>
        </Box>
        <Box data-testid="preview" sx={{ ...webStyle.clearPreviewSaveButton, marginRight: 10 }} onClick={() => this.handleClickPreview(true)}>
          <Typography variant="body1" style={{ ...webStyle.clearPreviewSaveButtonText, color: '#313320' }}>Preview</Typography>
        </Box>
        <Box data-testid="save" sx={{ ...webStyle.clearPreviewSaveButton, border: '1px solid #E73700' }} onClick={this.saveWorkoutApiCall}>
          <Typography variant="body1" style={{ ...webStyle.clearPreviewSaveButtonText, color: '#E73700' }}>
            {this.isDraft() ? "Draft" : "Save"}
          </Typography>
        </Box>
      </Box>
    );
  }

  renderClassTypePopover() {

    return (
      <Popover
        id={this.idFilter}
        open={Boolean(this.state.classTypeAnchorEl || null)}
        anchorEl={this.state.classTypeAnchorEl}
        onClose={this.handleClosePopover}
        PaperProps={{
          style: {
            borderRadius: 17,
          },
        }}
        data-testid="class-type-popover-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ ...webStyle.classTypePopoverContainer, }}>
          {this.state.templateOverviewList?.map((item: any) => {
            const classTypeSelectedStyle =
              item === this.state.selectedClassType
                ? webStyle.classTypeSelectedItem
                : webStyle.classTypeDeselectedItem;

            return (
              <Box
                data-testid="class-type-item"
                onClick={
                  this.handleChangeSelectedClassType.bind(this, item)}
                sx={{
                  ...webStyle.classTypePopoverItem,
                  ...classTypeSelectedStyle,
                }}
                key={`class-type-item${item.id}`}
              >
                <Typography>{item.attributes.title ?? ""}</Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    );
  }

  renderFocusPopover() {
    return (
      <Popover
        id={this.idFilter}
        open={Boolean(this.state.focusAnchorEl || null)}
        anchorEl={this.state.focusAnchorEl}
        onClose={this.handleClosePopover}
        PaperProps={{
          style: {
            borderRadius: 17,
          },
        }}
        data-testid="focus-popover-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ ...webStyle.classTypePopoverContainer, }}>
          {(this.state.focusList || []).map((item: any, index: number) => {
            const classTypeSelectedStyle =
              item === this.state.selectedFocus
                ? webStyle.classTypeSelectedItem
                : webStyle.classTypeDeselectedItem;
            return (
              <Box
                key={item}
                data-testid="focus-item"
                onClick={
                  this.handleChangeSelectedFocus.bind(this, item)}
                sx={{
                  ...webStyle.classTypePopoverItem,
                  ...classTypeSelectedStyle,
                }}

              >
                <Typography>{item}</Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    );
  }

  renderStatus() {
    return (
      <Box sx={{ ...webStyle.row, marginTop: 15, }}>
        <Box sx={{ ...webStyle.statusBox, marginRight: 10, }}>
          <Typography style={webStyle.statusValue}>{this.state.laps}</Typography>
          <Typography style={webStyle.statusText}>Laps</Typography>
        </Box>
        <Box sx={{ ...webStyle.statusBox, marginRight: 10, }}>
          <Typography style={webStyle.statusValue}>{this.state.stations}</Typography>
          <Typography style={webStyle.statusText}>Stations</Typography>
        </Box>
        <Box sx={{ ...webStyle.statusBox, marginRight: 10, }}>
          <Typography style={webStyle.statusValue}>{this.state.sets}</Typography>
          <Typography style={webStyle.statusText}>Sets</Typography>
        </Box>
        <Box sx={{ ...webStyle.statusBox, marginRight: 10, }}>
          <Typography style={webStyle.statusValue}>{this.timeConversion((this.state.restTime).toString())}</Typography>
          <Typography style={webStyle.statusText}>Rest Time</Typography>
          
        </Box>
        <Box sx={{...webStyle.statusBox, marginRight: 10,}}>
          <Typography style={webStyle.statusValue}>{this.timeConversion((this.state.zoneChange).toString())}</Typography>
          <Typography style={webStyle.statusText}>Zone Change</Typography>
        </Box>
        <Box sx={webStyle.statusBox}>
          <Typography style={webStyle.statusValue}>{this.timeConversion((this.state.workoutTime || "").toString())}</Typography>
          <Typography style={webStyle.statusText}>Total Workout Time</Typography>
        </Box>
      </Box>
    )
  }


  /* istanbul ignore next */
  renderDragDropVideoSection(droppableId: any, title: any, videoState: any) {
    const image = videoState?.attributes?.video_image_url || videoState?.video_image_url
    return (
      <Box sx={{position: 'relative'}}>
      <Droppable key={droppableId} droppableId={droppableId} data-testid="dragable-section">
        {(provided) => (
          <>
            <RootRef rootRef={provided.innerRef}>
              {videoState && Object.keys(videoState).length !== 0 ?
              <Box sx={{position: 'relative', width: 120,
              height: 100,
              overflow: "hidden",
              marginRight: 15,
              borderRadius: 17,
              bgcolor: "rgba(0,0,0,0.1)"}}>
                <ThumbVideo
                  item={videoState}
                  style={webStyle.thumbSelectedVideoWrapper}
                />
                <IconButton data-testid={`${droppableId}-delete-button`} onClick={() => {
                  this.handleRemoveVideo(droppableId)
                }} style={{position: 'absolute', right: 0, top: 0, zIndex: 1000}}>
                  <DeleteOutlineIcon  fontSize="small" />
                </IconButton>
              </Box>
                
                :
                <Box sx={{ ...webStyle.dragDropVideosContainer, marginRight: 15, height: 100 }}>
                  <Typography style={webStyle.dragDropVideosPlaceholder}>{title}</Typography>
                  <Typography style={webStyle.dropFileText}>drop your file here</Typography>
                </Box>
              }
            </RootRef>
            <Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10}}>
            {provided.placeholder}
            </Box>
          </>
        )}
      </Droppable>
      </Box>
    );
  }

  renderDragDropVideo() {
    return (
      <Box sx={{ ...webStyle.row, marginTop: 15 }}>
        {this.renderDragDropVideoSection("introductionDroppable", "Introduction", this.state.introductionVideo)}
        {this.renderDragDropVideoSection("warmupDroppable", "Warmup", this.state.warmupVideo)}
      </Box>
    );
  }

  renderZoneButton(zone: any) {
    const isSelected = this.state.selectedZone === zone;
    return (
      <Box
        data-testid={`${zone}-button`}
        sx={{
          ...webStyle.actionButtonContainer,
          padding: '10px 25px 10px 25px',
          marginRight: 10,
          border: isSelected ? '1px solid #313320' : null,
        }}
        onClick={() => {
          this.handleChangeSelectedZone(zone);
        }}>
        <Typography
          style={{
            ...webStyle.zoneText,
            color: isSelected ? '#313320' : '#939393',
          }}>
          {`Zone ${zone.charAt(zone.length - 1)}`}
        </Typography>
      </Box>
    );
  }

  renderZone() {
    return (
      <Box sx={{ ...webStyle.row, marginTop: 15 }}>
        {this.renderZoneButton('zone1')}
        {this.renderZoneButton('zone2')}
        {this.renderZoneButton('zone3')}
      </Box>
    );
  }

  renderSetBox(droppableId: any) {
    const isActiveStation =
      parseInt(droppableId) <= parseInt(this.state.selectedClassType.attributes?.station);

    const activeStationBorderTopStyle = isActiveStation ? webStyle.activeStationBorderTop : webStyle.horizontalLine
    const activeStationBorderStyle = isActiveStation ? webStyle.activeStationBorder : webStyle.inactiveStationBorder

    if(!isActiveStation) return <></>;

    return (
      <Box key={`set-box${droppableId}`} 
        sx={{
          ...webStyle.setsContainer,
          ...activeStationBorderStyle,
          position: 'relative',
          top: 0, left: 0,
          marginRight: 15,
          marginBottom: 15,
        }}
        style={{
          width: "25%"
        }}>
        {this.renderSetData(droppableId, 1, "set1")}
        {this.state.sets > 1 && (
          <>
            <Box sx={{ ...webStyle.horizontalLine, ...activeStationBorderTopStyle }} />
            {this.renderSetData(droppableId, 2, "set2")}
          </>
        )}
        {this.state.sets > 2 && (
          <>
            <Box sx={{ ...webStyle.horizontalLine, ...activeStationBorderTopStyle }} />
            {this.renderSetData(droppableId, 3, "set3")}
          </>
        )}
        <Box sx={webStyle.indexContainer}>
          <Typography style={{ color: '#747474', fontWeight: 500, fontSize: 13 }}>{droppableId}</Typography>
        </Box>
      </Box>
 
    );
  }
  /* istanbul ignore next */
  renderSetData(droppableId: any, index: number, set: string) {
    const selectedZone = this.state.selectedZone;
    const zoneCopy = (this.state as any)[selectedZone];
    const setAttributes = zoneCopy[droppableId]?.[set]?.attributes?.exercise_name || zoneCopy[droppableId]?.[set]?.exercise_name;
    return (
      <Box sx={webStyle.setBox}>
        <Droppable key={droppableId} droppableId={`${droppableId}.${index}`}>
          {(provided) => (
            <>
              <RootRef rootRef={provided.innerRef}>
                {setAttributes ?
                  <>
                    {/* <img src={image} alt="dummy" style={{ ...webStyle.drapImg }} /> */}
                    <ThumbVideo
                      item={zoneCopy[droppableId]?.[set]}
                      style={{ ...webStyle.thumbWrapper, width: 100, borderRadius: 10 }}
                    />
                    <Box display='flex' justifyContent='space-between' alignItems='center'
                      style={{ ...webStyle.drapSection }}>

                      <Box ml={0.5}>
                        <Typography style={{ ...webStyle.drapHeading }}>{setAttributes}</Typography>
                        <Typography style={{ ...webStyle.dragParagraph }}>{`Set ${index}`}</Typography>
                      </Box>
                    </Box>
                    <IconButton data-testid={`set-delete-button`} style={{zIndex: 1000}} onClick={() => {
                  this.handleRemoveVideo(`${droppableId}.${index}`)
                }}>
                      <DeleteOutlineIcon  fontSize="small" />
                    </IconButton>
                  </>
                  :
                  <Box sx={webStyle.setBox}>
                    <Typography style={{ ...webStyle.dropFileText, fontSize: 12 }}>{`drop your file here for set ${index}`}</Typography>
                  </Box>
                }
              </RootRef>
              <Box style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10}}>
              {provided.placeholder}
              </Box>
            </>
          )}
        </Droppable>
      </Box>
    )
  }
  renderSets() {
    return (
      <Grid key="sets-grid" data-test-id="sets-grid" container spacing={3}
        style={webStyle.setListWrapp}
      >
        {this.state.selectedClassType.attributes?.station_sequence?.map((index: number) => this.renderSetBox(index))}
      </Grid>

    );
  }


  renderCoolingDown(droppableId: string, title: any, videoState: any) {
    const image = videoState?.attributes?.video_image_url || videoState?.video_image_url

    return (
      <Droppable key={droppableId} droppableId={droppableId}>
        {(provided) => (
          <>
            <RootRef rootRef={provided.innerRef}>
              {(videoState && Object.keys(videoState).length !== 0) ?
                // <img src={image} alt="dummy" style={{ ...webStyle.drapImgSec, objectFit: 'fill' }} />
                <Box sx={{position: 'relative', width: 120,
                height: 100,
                overflow: "hidden",
                marginRight: 15,
                borderRadius: 17,
                marginTop: 15,
                bgcolor: "rgba(0,0,0,0.1)"}}>
                <ThumbVideo
                  item={videoState}
                  style={{ ...webStyle.thumbSelectedVideoWrapper,  height: 130, width: 150 }}
                />
                <IconButton data-testid={`${droppableId}-delete-button`} onClick={() => {
                  this.handleRemoveVideo(droppableId)
                }} style={{position: 'absolute', right: 0, top: 0, zIndex: 10}}>
                  <DeleteOutlineIcon  fontSize="small" />
                </IconButton>
                </Box>
                :
                <>
                  <Box sx={{ ...webStyle.dragDropVideosContainer, marginRight: 15, height: 120, marginTop: 15 }}>
                    <Typography style={webStyle.dragDropVideosPlaceholder}>Cooling Down</Typography>
                    <Typography style={webStyle.dropFileText}>drop your file here</Typography>
                  </Box>
                </>
              }
            </RootRef>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    )
  }

  cooling() {
    return (<>{
      this.renderCoolingDown("coolingDropable", "Cooling", this.state.coolingVideo)
    }</>)
  }

  renderRestTime() {
    return (
      <Box sx={{ ...webStyle.statusBox, marginTop: 15, padding: '22px 12px 22px 12px', flexDirection: "column", alignItems: "center", display: "flex", }}>
        <Typography data-testid="rest-time1" style={webStyle.statusValue}>{this.state.restTimeSet1 ? this.timeConversion((this.state.restTimeSet1).toString()) : "00:00"}</Typography>
        <Typography style={webStyle.statusText}>Rest time - set 1</Typography>
        {this.state.sets > 1 && (
          <>
            <Typography data-testid="rest-time2" style={webStyle.statusValue}>{this.state.restTimeSet2 ? this.timeConversion((this.state.restTimeSet2).toString()) : "00:00"}</Typography>
            <Typography style={webStyle.statusText}>Rest time - set 2</Typography>
          </>
        )}
        {this.state.sets > 2 && (
          <>
            <Typography data-testid="rest-time2" style={webStyle.statusValue}>{this.state.restTimeSet3 ? this.timeConversion((this.state.restTimeSet3).toString()) : "00:00"}</Typography>
            <Typography style={webStyle.statusText}>Rest time - set 3</Typography>
          </>
        )}
      </Box>
    )
  }


  videoLibrary(item: any, index: any) {
    return (
      <Draggable
        key={item.id.toString()}
        draggableId={item.id.toString()}
        index={index}>
        {

          (provided) => (

            <RootRef
              rootRef={provided.innerRef}>
              <Box
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={{
                  ...webStyle.row, padding: '15px 1px 15px 1px', alignItems: 'center'
                }}>
                <Box sx={{ borderRadius: 17, width: 70, height: 50, alignItems: 'center', justifyContent: 'center', display: "flex", marginRight: 10 }}>
                  {/* <img style={{ borderRadius: 17, width: 70, height: 50, }} src={item.attributes.video_image_url} /> */}
                  <ThumbVideo
                    item={item}
                    style={webStyle.thumbWrapper}
                  />
                </Box>
                <Typography style={{ color: '#313320', fontWeight: 600, fontSize: 14, marginBottom: 5, }}>{item.attributes.exercise_name}</Typography>
              </Box>
            </RootRef>
          )
        }
      </Draggable>
    );
  }

  renderTemplateOverview() {


    return (
      <Box sx={{ ...webStyle.innerWrapper, overflow: "auto", height: 300 }}>
        <Typography style={{ color: '#313320', fontWeight: 700, fontSize: 20, }}>Template Overview</Typography>
        {this.state.templateOverviewList?.map((item: any, index: any) => {

          return (
            <Box key={index} sx={{ ...webStyle.row, padding: '15px 1px 15px 1px', }}>
              <Box sx={{ borderRadius: 17, width: 70, height: 50, alignItems: 'center', justifyContent: 'center', display: "flex", marginRight: 10 }}>
                <img style={{ borderRadius: 17, width: 70, height: 50, }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcFbPjBYc9G98aTsB6VqubW5tNssJd5GMOgQ&usqp=CAU" />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* <Typography style={{ color: '#313320', fontWeight: 600, fontSize: 14, marginBottom: 5, }}>5 May, 2023</Typography> */}
                {/* <Typography style={{ color: '#747474', fontWeight: 400, fontSize: 16, }}>BR3 | Conditioning</Typography> */}
                <Typography style={{ color: '#747474', fontWeight: 400, fontSize: 16, }}>{item?.attributes?.title}</Typography>
              </Box>
            </Box>
          )
        })}
      </Box>
    )
  }
  renderListVideo(){
    const listVideo = this.state.videoLibraryListFilter.length > 0 ? this.state.videoLibraryListFilter : this.state.videoLibraryList
    return  listVideo.slice(0, this.state.videoDisplayLimit)?.map(this.videoLibrary)
  }
  renderVideoLibrary() {

    return (
      <Box sx={{ ...webStyle.innerWrapper, ...webStyle.videoLib }}>
        <Box sx={{ ...webStyle.row, alignItems: 'center', justifyContent: 'space-between', }}>
          <Typography style={{ color: '#313320', fontWeight: 700, fontSize: 20, }}>Video Library</Typography>
          <Box sx={{ ...webStyle.row, alignItems: 'center', marginLeft: 20 }}>
            {this.renderSortPopover()}
            <Box
              onClick={this.handleClickSortPopover}
              sx={webStyle.selectClassContainer}
              data-testid="select-sort">
              <Typography>Category</Typography>
              <ExpandMoreIcon fontSize="small" />
            </Box>
            {this.renderFilterPopover()}
            <Box
              onClick={this.handleClickFilterPopover}
              sx={webStyle.selectClassContainer}
              data-testid="select-filter">
              <Typography>Tags</Typography>
              <ExpandMoreIcon fontSize="small" />
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.inputContainer}>
          <img src={imgSearch} height={24} />

          <InputBase data-testid="search-data" placeholder="Search from library" style={webStyle.input} onChange={(value: any) => this.filteringString(value)} value={this.state.searchStr} />
        </Box>

        <Typography style={{ color: '#747474', fontWeight: 400, fontSize: 14, marginTop: 15, }}>Drag videos to add to your workout</Typography>
       {this.renderListVideo()}
        {this.isShowLoadMoreBtn() ? (
          <Box
            onClick={this.handleLoadmoreVideo}
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 30,
              marginBottom: 50,
              borderWidth: 1,
              backgroundColor: "rgba(0,0,0,0.1)",
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <Typography variant="h6">Load more</Typography>
          </Box>
        ) : undefined}
      </Box>
    )
  }

  renderSortPopoverItem(item: any) {
    const isSelected = item === this.state.selectedSort;
    const classTypeSelectedStyle = isSelected
      ? webStyle.classTypeSelectedItem
      : webStyle.classTypeDeselectedItem;

    return (
      <Box
        data-testid="sort-item"
        onClick={() => {
          this.handleChangeSelectedSort(item);
        }}
        sx={{
          ...webStyle.classTypePopoverItem,
          ...classTypeSelectedStyle,
        }}
        key={item}
      >
        <Typography>{item}</Typography>
      </Box>
    );
  }

  renderSortPopover() {
    return (
      <Popover
        id={this.idFilter}
        open={Boolean(this.state.sortAnchorEl || null)}
        anchorEl={this.state.sortAnchorEl}
        onClose={this.handleClosePopover}
        PaperProps={{
          style: {
            borderRadius: 17,
          },
        }}
        data-testid="sort-popover-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ ...webStyle.classTypePopoverContainer }}>
          {this.state.listCategoryOfVideo?.map((item) => {
            return this.renderSortPopoverItem(item);
          })}
        </Box>
      </Popover>
    );
  }

  renderFilterItem(item: any) {
    const classTypeSelectedStyle =
      item === this.state.selectedFilter
        ? webStyle.classTypeSelectedItem
        : webStyle.classTypeDeselectedItem;

    return (
      <Box
        data-testid="filter-item"
        onClick={() => {
          this.handleChangeSelectedFilter(item);
        }}
        sx={{
          ...webStyle.classTypePopoverItem,
          ...classTypeSelectedStyle,
        }}
        key={item}
      >
        <Typography>{item}</Typography>
      </Box>
    );
  }

  renderFilterPopover() {
    const filterItems = this.state.listTagOfVideo.map((item) =>
      this.renderFilterItem(item)
    );

    return (
      <Popover
        id={this.idFilter}
        open={Boolean(this.state.filterAnchorEl || null)}
        anchorEl={this.state.filterAnchorEl}
        onClose={this.handleClosePopover}
        PaperProps={{
          style: {
            borderRadius: 17,
          },
        }}
        data-testid="filter-popover-modal"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ ...webStyle.classTypePopoverContainer }}>
          {filterItems}
        </Box>
      </Popover>
    );
  }

}

interface WebStyle {
  [key: string]: React.CSSProperties | any
}

const webStyle: WebStyle = {
  inputName: {
    borderRadius: 17,
    height: 49,
    paddingRight: 16,
    border: "none",
    background: "#f1f0ee",
    fontSize: 16,
    paddingLeft: 20,
    paddingTop: 12,
    paddingBottom: 12,
    "&:focus": {
      outline: "none",
      border: "none !important",
      boxShadow: "none !important",
    },
  },
  loadingContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    background: "#faf9f6",
    opacity: 0.5,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    padding: '20px 20px 30px 30px',
    background: "#FBFAF7"
  },
  innerWrapper: {
    boxShadow: "3px 3px 5px 3px #3133201F",
    borderRadius: 16,
    backgroundColor: 'white',
    padding: 20,
    borderTopRightRadius: 10,
  },
  textHeader: {
    fontSize: 20,
    fontWeight: 700,
    color: "#E73700",
    marginBottom: 28,
  },
  row: {
    flexDirection: "row",
    display: "flex",
  },
  cardContainer: {
    width: "100%",
    alignItems: 'center',
  },
  layoutPreviewContainer: {
    borderRadius: 10,
    border: '1px solid black',
    height: 48,
  },
  layoutPreviewButton: {
    width: '50%',
    alignItems: 'center',
    display: "flex",
    padding: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  clearPreviewSaveButton: {
    borderRadius: 17,
    border: '1px solid #313320',
    padding: 13,
    "&:hover": {
      cursor: "pointer",
    },
  },
  clearPreviewSaveButtonText: {
    fontSize: 16,
    fontWeight: 400,
  },
  statusBox: {
   flex: 1,
    borderRadius: 17,
    border: '1px solid #747474',
    padding: 22,
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  statusValue: {
    color: '#E73700',
    fontSize: 16,
    fontWeight: 600,

  },
  statusText: {
    fontSize: 12,
    color: '#747474',
    fontWeight: 400,
    textAlign: "center"
  },
  actionButtonContainer: {
    borderRadius: 17,
    border: '1px solid black',
    alignContent: 'center',
    padding: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  thumbSelectedVideoWrapper: {
    width: 120,
    height: 100,
  },
  thumbWrapper: {
    width: 70,
    height: 50,
    backgroundColor: "rgba(0,0,0,0.1)",
    overflow: "hidden",
    borderRadius: 17,
  },
  loadmoreVideoBtn: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "red",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 30,
    marginBottom: 50,
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
    borderRadius: 10
  },
  dragDropVideosContainer: {
    width: 120,

    borderRadius: 17,
    border: '1px dashed #747474',
    background: '#F3F7FF',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    display: "flex",
  },
  dragDropVideosPlaceholder: {
    color: '#B1B2AA',
    fontWeight: 600,
    fontSize: 14,
  },
  dropFileText: {
    color: '#939393',
    fontWeight: 400,
    fontSize: 9,
  },
  zoneText: {
    fontWeight: 600,
    fontSize: 16,
  },
  setsContainer: {
    width: '235px',
    borderRadius: 17,
    border: '1px dashed #747474',
    background: '#F5F5F4',
  },
  setBox: {
    margin: '15px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '75px',
    position: 'relative'
  },
  setBoxGridContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: -15
  },
  horizontalLine: {
    borderTop: '1px dashed #939393',
  },
  indexContainer: {
    borderRadius: 9,
    background: '#C2C2C2',
    position: 'absolute',
    top: 10,
    left: 10,
    width: 25,
    height: 25,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: '0.5px solid #fff',
  },
  inputContainer: {
    borderRadius: 16,
    border: "1px solid #C2C2C2",
    height: 56,
    fontSize: 16,
    fontWeight: 400,
    fontcolor: '#747474',
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
    marginTop: 15,
  },
  input: {
    width: "100%",
    marginLeft: 10,
  },
  classTypePopoverContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    background: "#FFF",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 17,
    border: "1px solid #747474",
    width: 250,
  },
  classTypePopoverItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 16,
    height: 40,
    paddingLeft: 15,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
    }
  },
  classTypeSelectedItem: {
    background: "#EEECE7",
    color: "#313320"
  },
  classTypeDeselectedItem: {
    background: "#fff",
    color: "#747474"
  },
  selectClassContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 700,
    color: "#313320",
    paddingRight: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  drapHeading: {
    color: "#313320",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "140%"
  },
  dragParagraph: {
    color: "#313320",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "140%",
    marginTop: 5
  },
  drapImg: {
    width: "73px",
    height: "50px",
    borderRadius: "17px"
  },
  drapImgSec: {
    width: "100%",
    height: "100%",
    maxHeight: '100px',
    borderRadius: "17px"
  },
  drapSection: {
    width: "220px",
    height: "62px",
    borderRadius: "10px",
    // background: "#FFF",
    padding: '6px 8px'
  },
  activeStationBorderTop: {
    borderTop: '1px solid #747474'
  },
  activeStationBorder: {
    border: '1px solid #747474'
  },
  inactiveStationBorder: {
    border: '1px dashed #747474'
  },
  inputStyle: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: '1px solid #007BFF',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
  },
  labelStyle: {
    content: 'Choose a file',
    display: 'inline-block',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    color: '#fff',
  },
  imageContainer: {
    flexDirection: 'column',
    display: 'flex',
  },
  image: {
    borderRadius: 17,
    height: '120px',
    width: '120px',
    marginBottom: 10,
  },
  videoLib: {
    marginTop: 20, position: 'fixed', zIndex: 1, top: 10, bottom: 20, maxWidth: '23%', overflow: 'hidden', overflowY: "auto",
  },
  setListWrapp: {
    marginTop: 15,
    marginLeft: 5,
    width: "90%",
  }
};