import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { generateRequestMessage, generateRequestMessageData } from "../../../components/src/MessageSupport";
export const baseURL = require("../../../framework/src/config.js").baseURL;
import React, { RefObject } from 'react';
const _ = require('lodash');


interface IUploadedFile {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      files:
      | {
        id: number;
        file_name: string;
        file_url: string;
      }[]
      | null;
      status: string;
    };
  };
  meta: {
    message: string;
  };
}

interface IFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

interface IDownloadResponse {
  jobId: number;
  statusCode: number;
  bytesWritten: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  value: string;
  onChange: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  filesWeb: File[];
  files: any[];
  uploadedFiles: IUploadedFile[];
  filesStatus: (undefined | "uploading" | "success" | "failed")[];
  selectedFiles: any;
  uploadProgress: any;
  isDisableInput: boolean;
  activeFile: number;
  newFiles: any;
  isSubmitEnabled: boolean;
  formDetails: any;
  tags: any;
  listCategory: string[]
  formDataReq: any;
  openPreviewModal: boolean;
  base64url: any;
  selectedNewTags: any[];
  base64VideoUrl: string;
  thumbnailUrl: any[];
  createsuccessmodal: boolean;
  createfailmsg: string;
  loading: boolean;
  error_message: string;
  fileInputRef: RefObject<HTMLInputElement>;
  tagsCache: string[]

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  postCreateVideoId: string = "";
  maxFileSize = 5e6;
  getAllTagsApiCallId: string = "";
  getListCategoryApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      filesWeb: [],
      files: [],
      uploadedFiles: [],
      filesStatus: [],
      selectedFiles: [],
      uploadProgress: {},
      isDisableInput: true,
      activeFile: -1,
      newFiles: {},
      isSubmitEnabled: false,
      formDetails: [],
      tags: [],
      listCategory: [],
      formDataReq: [],
      openPreviewModal: false,
      base64url: "",
      selectedNewTags: [],
      base64VideoUrl: "",
      thumbnailUrl: [],
      createsuccessmodal: false,
      createfailmsg: "",
      loading: false,
      error_message: "",
      fileInputRef: React.createRef(),
      tagsCache: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if(responseJson.data){
      if (this.postCreateVideoId === apiRequestCallId) {
        this.handleCreateVideoResponse();
      } }else if (responseJson.tags && apiRequestCallId === this.getAllTagsApiCallId) {
        this.handleAllTagsResponse(responseJson);
      } else if (responseJson.errors) {
        this.handleErrorResponse(responseJson);
      }else if (apiRequestCallId === this.getListCategoryApiCallId) {
        this.handleListCategoryResponse(responseJson);
      } 
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleCreateVideoResponse() {
    
      this.setState({
        createsuccessmodal: true,
        formDetails: [],
        selectedFiles: [],
        createfailmsg: "Video Uploaded Successfully",
        loading: false
      });
      this.clearFileInput();
  }
  
  handleAllTagsResponse(responseJson: any) {
    this.setState({ tags: responseJson.tags.sort((a: string, b: string) => a.localeCompare(b)) });
  }
  handleListCategoryResponse(responseJson: any) {
    if(responseJson.Categories){
      this.setState({ listCategory: responseJson.Categories.sort((a: string, b: string) => a.localeCompare(b)) });
    }
  }
  
  handleErrorResponse(responseJson: any) {
    this.setState({
      createsuccessmodal: true,
      formDetails: this.state.formDetails,
      selectedFiles: this.state.selectedFiles,
      createfailmsg: responseJson.errors[0],
      loading: false
    });
    this.clearFileInput();
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAllTagsDataApi();
    this.getListCategoryDataApi();
  }


  handleOpenModalPreview = () => {
    this.setState({
      openPreviewModal: true,
    });

  };
  handleCloseModalPreview = () => {
    this.setState({
      openPreviewModal: false,
    });
  };

  clearFileInput = () => {
    if (this.state.fileInputRef.current) {
      this.state.fileInputRef.current.value = ''; // Reset the input value to clear the selection
    }
  };


  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  uploadFile = () => {
    const length = this.isPlatformWeb()
      ? this.state.filesWeb.length
      : this.state.files.length;

    if (length > this.state.filesStatus.length) {
      this.setState({ filesStatus: [...this.state.filesStatus, "uploading"] });
    }
  };
  handleChangeTag = (tags: any) => {
    this.setState({tagsCache: _.uniq([...this.state.tagsCache, ...tags])},
     () => {
      this.handleInputChange(this.state.activeFile, 'add_tag_list', tags)
    })
  }

  handleInputChange = (fileId: number, field: string, value: string) => {
    const { formDetails } = this.state;
    const updatedFormData = { ...formDetails };
    if (!updatedFormData[fileId]) {
      updatedFormData[fileId] = {};
    }
    updatedFormData[fileId][field] = value;

    const isSubmitEnabled = this.checkSubmitEnabled(
      Object.values(updatedFormData)
    );
    this.setState({
      formDetails: Object.values(updatedFormData),
      isSubmitEnabled,
    });
  };

  checkSubmitEnabled = (formDetails: any) => {
    return this.state.selectedFiles?.length === formDetails?.length;
  };

  handleFileDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleFileChange(event.dataTransfer.files);
  };
  
  handleSubmit = async () => {
    this.setState({ loading: true, error_message: "" })
    const result = this.validationCheckCreate();
    if (result) {
      await this.postCreateVideo();
      this.setState({ createfailmsg: "Video Uploaded Successfully" })
    } else{
      this.setState({
        createfailmsg: "Feilds Can't be Blank",
        createsuccessmodal: true
      })
    }
  };

  validationCheckCreate = () => {
    let isValid = true;
    this.state.formDetails.forEach((exercise: any, index: number) => {
      console.log('exercise',exercise)
      if (!exercise.exercise_name || !exercise.add_tag_list || !exercise.category ||
        exercise.exercise_name.trim() === '' || exercise.category.trim() === '' || exercise.add_tag_list?.length === 0) {
        isValid = false;
      }
    });
    return isValid;
  };

  postCreateVideo = async () => {
    const videosFormData = new FormData();
    const { formDetails } = this.state;

formDetails.forEach((exercise: any, index: number) => {
  const {
    exercise_name,
    add_tag_list,
    video_image,
    videos,
    category
  } = exercise;

  const videoData = {
    'exercise_name': exercise_name,
    'add_tag_list': add_tag_list,
    'video_image': video_image ?? '',
    'videos': videos,
    'category': category.toLowerCase().replaceAll(' ', '_'),
  };

  Object.entries(videoData).forEach(([key, value]) => {
    videosFormData.append(`videos[][${key}]`, value);
  });
});

    const requestMessage = generateRequestMessageData(
      configJSON.createVideosWithDetail,
      configJSON.createBulkUploadMethod
    );
    this.postCreateVideoId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      videosFormData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getNameOfFile = (fileName: string) => {
    const arrayName = fileName.split('.')
    arrayName.pop()
    return arrayName.join('.')
  }
  handleFileChange = (files: any) => {
    const fileArray: any = Array.from(files);
    this.setState({
      activeFile: 0,
      selectedFiles: fileArray,
      uploadProgress: Array(fileArray.length).fill(10),
    });
    this.handleInputChange(0, "videos", files[0]);
    fileArray.forEach((fileItem: any, index:number) => {
      setTimeout(() => {
        this.handleInputChange(index, 'exercise_name', this.getNameOfFile(fileItem.name));
      }, 400 * (index+1));
      
    })
    this.uploadFiles(fileArray);

    if (fileArray.length > 30) {
      this.setState({
        activeFile: -1,
        createsuccessmodal: true,
        formDetails: [],
        selectedFiles: [],
        uploadProgress: {},
        createfailmsg: "Upload 30 Videos at a Time",
        loading: false
      });
      this.clearFileInput()
    }
  };

  handleThumbnail = (event: any) => {
    const checkfile = event.target?.files[0]
    this.handleInputChange(this.state.activeFile, "video_image", checkfile);
    // this.setState({thumbnailUrl:[...this.state.thumbnailUrl , checkfile]})
    const newThumbnailUrl = [...this.state.thumbnailUrl];
    newThumbnailUrl.splice(this.state.activeFile, 1);
    newThumbnailUrl.splice(this.state.activeFile, 0, checkfile);
    this.setState({ thumbnailUrl: newThumbnailUrl });
  }
  handlePositiveButton = () => {
    this.setState({
      createsuccessmodal: false,
      loading: false
    })
    if(this.state.createfailmsg === "Video Uploaded Successfully"){
      history.back()
    }
   
  }
  
  uploadFiles = (files: any) => {
    files.forEach((file: any, index: number) => {
      const formDetails = new FormData();
      formDetails.append("video", file);

      this.simulateUploadProgress(file, index);
    });
  };

  getAllTagsDataApi = () => {
    const requestMessage = generateRequestMessage(
      `${configJSON.getAllTags}`,
      configJSON.getBulkUploadMethod
    );
    this.getAllTagsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getListCategoryDataApi = () => {
    const requestMessage = generateRequestMessage(
      `${configJSON.getListCategory}`,
      configJSON.getBulkUploadMethod
    );
    this.getListCategoryApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFileClick = (index: number, file: any) => {
    this.handleInputChange(index, "videos", file);
    this.setState({ isDisableInput: false, activeFile: index, base64url: URL.createObjectURL(file) });
  };

  simulateUploadProgress = (file: any, index: number) => {
    const interval = setInterval(() => {
      const { uploadProgress } = this.state;
      uploadProgress[index] = uploadProgress[index] + 10;

      if (uploadProgress[index] >= 100) {
        clearInterval(interval);
      }

      this.setState({ uploadProgress });
    }, 300);
  };

  removeFile = (index: number, file: any) => {
    this.state.thumbnailUrl.splice(index, 1);
    this.setState({
      selectedFiles: Array.from(this.state.selectedFiles).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    }, () => { this.clearFileInput() });
    if (index > 0) {
      this.setState({ activeFile: 0 }, () => { console.log("activeactive", this.state.activeFile) });
    } else {
      this.setState({ activeFile: -1 }, () => { console.log("activeactive0", this.state.activeFile) });
    }
  };



  selectFiles = async () => {
      const pickerResult = await DocumentPicker.pickMultiple({
        presentationStyle: "fullScreen",
        copyTo: "cachesDirectory",
      });
      const tempFile: DocumentPickerResponse[] = pickerResult.filter(
        (result) => {
          if (result.size && result.size > this.maxFileSize) {
            this.showAlert("Alert", result.name + configJSON.fileSizeErrorMsg);
            return false;
          } else {
            return true;
          }
        }
      );

      this.setState({
        files: [...this.state.files, ...tempFile],
      });
  };
  clearAllFile = () => {
    this.setState({ files: [], filesWeb: [], filesStatus: [] });
  };
  deleteFile = (fileGroupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBulkUploadCallId = deleteBulkUploadMsg.messageId;
    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );
    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteBulkUploadEndpoint}/${fileGroupId}`
    );
    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
  };
  getUploadedFiles = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const getBulkUploadFileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBulkUploadCallId = getBulkUploadFileMsg.messageId;

    getBulkUploadFileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBulkUploadEndpoint
    );

    getBulkUploadFileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadFileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(getBulkUploadFileMsg.id, getBulkUploadFileMsg);
  };

  // Customizable Area End
}
