import React from "react";
import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    useMediaQuery,
    Grid,
    Paper,
    Hidden
    // Customizable Area End
} from "@material-ui/core";

import Loader from "./Loader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

interface myProps {
    style?: any;
    insideCircleText:any;
    outsideCircleText:any;  
    hiddenTextStyle?:any;
    progress?: number,
    color?: string,
  }

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "9.45px",
            letterSpacing: "-1%",
            textAlign: "center",
            color: "#000000"
        },
        subtitle1: {
            fontWeight: 500,
            fontFamily: "Poppins",
            fontSize: "24px",
            lineHeight: "32px",
            letterSpacing: "-0.5%",
            color: "#747474"
        },
        body1: {
            fontWeight: 700,
            fontFamily: "Poppins",
            fontSize: "16px",
            lineHeight: "9.45px",
            letterSpacing: "-1%",
            color: "#000000"
        },
    },
});
const BoilerPlate = (props:myProps) => {

    const {progress = 0, color = "white"} = props;
   
return  <Box sx={webStyle.topBarBox} style={props.style}>
            <Box>
                <Box position="relative" display="inline-flex">
                    <Loader loading className={{
                        height: "100% !important",
                        width: "100% !important",
                        transform: "rotate(30deg) !important",
                        "& .MuiCircularProgress-circle": { stroke: color, strokeWidth: "1.5px" },
                        "& .MuiCircularProgress-svg": { overflow: "visible", }, 
                        "@media (max-width:768px)":{
                            "& .MuiCircularProgress-circle": { stroke: "green", strokeWidth: "1.5px" },
                        }  
                    }} style={props.style} variant="determinate" value={100} />
                    <Loader loading className={{
                        height: "100% !important",
                        width: "100% !important",
                        transform: "scaleY(-1) rotate(90deg) !important",
                        "& .MuiCircularProgress-circle": { stroke: color, strokeWidth: ".35em" },
                        "& .MuiCircularProgress-svg": { overflow: "visible", },
                    }} style={props.style} variant="determinate" value={progress}  />
                    <Typography component="div" style={{
                        color: color,
                        fontSize: 16,
                        marginBottom: 3,
                        width: '30px',
                        textAlign: "center"
                    }}>{props.insideCircleText}</Typography>
                </Box>
            </Box>
            {/* <Box style={props.hiddenTextStyle}>
                <Typography component="div" variant="subtitle1">
                   {props.outsideCircleText}
                </Typography>
            </Box> */}
        </Box>

    
}

export default BoilerPlate;

const webStyle = {
    topBarBox: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative",
        // "& .MuiBox-root-178":{
        //     marginLeft:"25px",
        // }
    },
    customProgress: {
        height: "100% !important",
        width: "100% !important",
        transform: "rotate(-0deg) !important",
        "& .MuiCircularProgress-circle": { stroke: "white", strokeWidth: ".35em" },
        "& .MuiCircularProgress-svg": { overflow: "visible", },
    },
    customProgress2: {
            height: "100% !important",
            width: "100% !important",
        
        transform: "rotate(-0deg) !important",
        "& .MuiCircularProgress-circle": { stroke: "white", strokeWidth: "1.5px" },
        "& .MuiCircularProgress-svg": { overflow: "visible", }, 
        "@media (max-width:768px)":{
            "& .MuiCircularProgress-circle": { stroke: "green", strokeWidth: "1.5px" },
          }           
    },
    
    
};