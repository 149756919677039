import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Template } from "./types";
import { Workout } from "../../../components/src/CommonModalWorkout";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  serviceProviderId: string;
  serviceProviderSchedule: any;
  anchorEl: HTMLButtonElement | null;
  anchorElTag: HTMLButtonElement | null;
  currentMonth: any;
  dateSelected: any;
  dateCalendarSelected: any;
  listTemplate: Template[];
  listTag: any[];
  listScheduleSlot: any[];
  selectedClassType: any;
  selectedTag: string;
  openModalWorkout: boolean;
  isOpenModalConfirmDelete: boolean;
  deleteWorkout: any
  editWorkoutSelected: any;
  battleOfDaySelected: any[][];
  addTimeSlotSelected: any;
  loading: boolean;
  listClassType: any[];
  listTemplateByClassType: any[];
  modalLoading: boolean;
  errorOutside: any;
  addedTimeSlot: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  idFilter = "simple-popover";
  idTagFilter = "tag-popover";
  getSchedulesApiCallId: string = ""
  getTemplatesApiCallId: string = ""
  getTagsApiCallId: string = ""
  postSchedulesApiCallId: string = ""
  deleteSchedulesApiCallId: string = ""
  putSchedulesApiCallId: string = ""
  getWorkoutId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      serviceProviderId: "",
      serviceProviderSchedule: "",
      currentMonth: moment(),
      dateSelected: null,
      listTemplate: [],
      listTag: [],
      anchorEl: null,
      dateCalendarSelected: new Date(),
      anchorElTag: null,
      selectedClassType: null,
      selectedTag: "",
      openModalWorkout: false,
      editWorkoutSelected: null,
      battleOfDaySelected: [],
      addTimeSlotSelected: null,
      listScheduleSlot: [],
      loading: true,
      listClassType: [],
      listTemplateByClassType: [],
      modalLoading: false,
      errorOutside: null,
      isOpenModalConfirmDelete: false,
      deleteWorkout: null,
      addedTimeSlot: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.postSchedulesApiCallId === apiRequestCallId) {
        this.handleMessagePostSchedule(responseJson)
        return
      }
      if (this.putSchedulesApiCallId === apiRequestCallId) {
        if(this.state.deleteWorkout !== null){
          const workoutSelected = this.state.deleteWorkout
          this.handleDeleteSchedule(workoutSelected.id)
        }else{
          this.handleMessagePutSchedule(responseJson)
        }
        return
      }
      if (responseJson && responseJson.data) {
        this.handleMessageHaveData(apiRequestCallId, responseJson)
      }else{
        this.handleMessageNotHaveData(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSchedules();
    this.getTemplate();
  }
   handleAddWorkoutData = (listWorkoutWorked: any, zoneId: any, stationItem: any) => {
    const setData = stationItem.sets;
    setData.forEach((setItem: any, setItemIndex: number) => {
      if (
        listWorkoutWorked[zoneId - 1][setItemIndex] && listWorkoutWorked[zoneId - 1][setItemIndex].length > 0
      ) {
        listWorkoutWorked[zoneId - 1][setItemIndex].push(setData[setItemIndex]);
      } else {
        listWorkoutWorked[zoneId - 1][setItemIndex] = [];
        listWorkoutWorked[zoneId - 1][setItemIndex].push(setData[setItemIndex]);
      }
    });
  }
  handleWorkoutData = (listStationData: any) => {
    const listWorkoutWorked: any[][] = [[],[],[]];
    if (listStationData && listStationData.length > 0) {
      listStationData.forEach((stationItem: any) => {
        const zoneId = stationItem.zone_id;
        if (typeof zoneId === "number") {
          this.handleAddWorkoutData(listWorkoutWorked, zoneId, stationItem);
        }
      });
    }
    this.setState({
      modalLoading: false,
      battleOfDaySelected: listWorkoutWorked,
    });
  }
  handleMessagePostSchedule = (responseJson: any) => {
    if(responseJson.error){
      this.setState({ errorOutside:responseJson.error,  loading: false, modalLoading: false})
    }else{
      this.setState({listTemplate: [],
        listScheduleSlot: [], addTimeSlotSelected: null, loading: true, modalLoading: false, addedTimeSlot: null})
      this.getSchedules();
      this.getTemplate()
    }
  }
  handleMessagePutSchedule = (responseJson: any) => {
    if(responseJson.error){
      this.setState({ errorOutside:responseJson.error,  loading: false, modalLoading: false})
    }else{
      this.setState({ editWorkoutSelected: null, loading: true, modalLoading: false})
      this.getSchedules();
      this.getTemplate()
    }
  }
  handleMessageHaveData = (apiRequestCallId : any, responseJson : any) => {
    if (this.getSchedulesApiCallId === apiRequestCallId) {
      this.setState({
        listScheduleSlot: responseJson.data,
        loading: false,
      }, () => {
        this.renderDataSchedule()
      });
    }
    if(this.getWorkoutId === apiRequestCallId) {
      const listStationData = responseJson.data?.attributes?.formatted_station_data
      this.handleWorkoutData(listStationData)
    }
  }
  filterWrongTemplates = (listTemplate: Template[]) : Template[] => {
    return listTemplate.filter(template => {
      return template.title !== null
    })
  }
  handleMessageNotHaveData = (apiRequestCallId : any, responseJson : any) => {
    if (this.deleteSchedulesApiCallId === apiRequestCallId) {
      if(this.state.addedTimeSlot !== null){
        this.postCreateTimeSlot(this.state.addedTimeSlot)
      }else{
        this.getSchedules();
        this.getTemplate()
      }
    }
    if (this.getTemplatesApiCallId === apiRequestCallId) {
      this.setState({
        listTemplate: this.filterWrongTemplates(responseJson),
      }, () => {
        this.renderDataSchedule()
      });
    }
    if(responseJson.errors){
      this.setState({modalLoading: false})
    }
  }
  getSchedules = () => {
    const currentMonth = this.state.currentMonth;

    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const startDate = startOfMonth.startOf("week");
    const endDate = endOfMonth.endOf("week");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.getSchedulesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleListAPIEndPoint + `?start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTemplate = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.getTemplatesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.templateListAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getWorkout = (workoutId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.getWorkoutId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWorkoutAPIEndPoint + `/${workoutId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSortDataTemplate = (newListTemplate: any[]) => {
    newListTemplate.sort((a: Template, b: Template) => {
      const dateA: any = a.time ? new Date(a.time) : null;
      const dateB: any = b.time ? new Date(b.time) : null; 
      if(dateA === null){
        return 1;
      }
      if(dateB === null){
        return -1;
      }
      return dateA - dateB; 
    })
  }
  handleCreateListClassType = (newListTemplate: any[]) => {
    let newListClassType: any[] = []
      newListTemplate.forEach(template => {
        if (newListClassType.length === 0) {
          newListClassType.push({
            template_id: template.template_id,
            title: template.class_type,
            items: [template]
          });
        } else {
          const indexFounded = newListClassType.findIndex(
            (classType) => classType.template_id === template.template_id
          );
          if(indexFounded === -1){
            newListClassType.push({
              template_id: template.template_id,
              title: template.class_type,
              items: [template]
            });
          }else{
            newListClassType[indexFounded].items = [
              ...newListClassType[indexFounded].items,
              template,
            ];
          }
        }
      })
      return newListClassType
  }
  handleFoundATemplate = (templateFound: any,newListTemplate: any,scheduleSlot: any, indexOfFound: any ) => {
    if (templateFound) {
      if(indexOfFound !== null && templateFound.time === undefined){
        newListTemplate.splice(indexOfFound, 1)
      }
      newListTemplate.push({
        ...templateFound,
        time: scheduleSlot.attributes.schedule_date,
      });
    }
  }
  renderListTag = (listTemplate: any) => {
    const listTag: string[] = []
    listTemplate.forEach((template: any) => {
      if(template.focus_tag !== null){
        if(listTag.length === 0){
          listTag.push(template.focus_tag)
        }else{
          if(!listTag.includes(template.focus_tag)){
            listTag.push(template.focus_tag)
          }
        }
      }
    })
    return listTag
  }
  /* istanbul ignore next */
  renderDataSchedule = () => {
    if(this.state.listScheduleSlot.length > 0 && this.state.listTemplate.length > 0) {
      let newListScheduleSlot = [...this.state.listScheduleSlot];
      const newListTemplate = [...this.state.listTemplate];
      newListScheduleSlot = newListScheduleSlot.map(scheduleSlot => {
        if(scheduleSlot.attributes.video_template_id !== null){
          let indexOfFound = null
          const templateFound = newListTemplate.find((templateItem, index) => {
            if(templateItem.id == scheduleSlot.attributes.video_template_id){
              indexOfFound = index
              return true;

            }
            return false
          });
          this.handleFoundATemplate(templateFound,newListTemplate,scheduleSlot, indexOfFound )
          return {
            ...scheduleSlot,
            template: templateFound
          }
        }else{
          return scheduleSlot;
        }
      })
      this.handleSortDataTemplate(newListTemplate)
      const newListClassType: any[] = this.handleCreateListClassType(newListTemplate)
      const listTag = this.renderListTag(newListClassType[0].items)

      this.setState({
        listScheduleSlot: newListScheduleSlot,
        listTemplate: newListTemplate,
        listClassType : newListClassType,
        selectedClassType: newListClassType[0],
        listTemplateByClassType: newListClassType[0].items,
        listTag,
        modalLoading: false
      })
    }
  }
  handlePrevMonth = () => {
    this.setState(
      {
        currentMonth: this.state.currentMonth.clone().subtract(1, "month"),
        loading: true,
        listTemplate: [],
        listScheduleSlot: [],
      },
      () => {
        this.getSchedules();
        this.getTemplate()
      }
    );
  };

  handleNextMonth = () => {
    this.setState(
      {
        currentMonth: this.state.currentMonth.clone().add(1, "month"),
        loading: true,
        listTemplate: [],
        listScheduleSlot: [],
      },
      () => {
        this.getSchedules();
        this.getTemplate()
      }
    );
  };
  /* istanbul ignore next */
  handleChangeSelectedDate = (date: any) => {
    const workoutSelected = this.state.listScheduleSlot.find(scheduleSlot => scheduleSlot.attributes.schedule_date === date.format("YYYY-MM-DD"))
    const isSchedulingWorkout = workoutSelected.attributes.video_template_id !== null
    this.setState({
      dateSelected: date,
      editWorkoutSelected: workoutSelected,
      modalLoading: isSchedulingWorkout,
      errorOutside: null,
      battleOfDaySelected: []
    });
    if(isSchedulingWorkout){
      this.getWorkout(workoutSelected.attributes.video_template_id)
    }
  };
  getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    border: isDragging ? "1px solid #E73700" : "none",
    ...draggableStyle
  });
  /* istanbul ignore next */
  handleDeleteScheduleOption = (value: string) => {
    const workoutSelected = this.state.deleteWorkout
    if(value === "1"){
      this.handleDeleteSchedule(workoutSelected.id)
    }else{
      let newListTimeSlot = [...workoutSelected.attributes.time_slots]
      newListTimeSlot = newListTimeSlot.map((itemTime) => {
      return {
        _destroy: itemTime.id, delete_statue: 0
        }
    });
    this.handleUpdateASchedule(workoutSelected, workoutSelected.attributes.schedule_date, newListTimeSlot)
    }
  }
  handleCloseClassTypePopover = () => {
    this.setState({ anchorEl: null });
  };
  handleClickClassTypePopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleCloseTagPopover = () => {
    this.setState({ anchorElTag: null });
  };
  handleClickTagFilterPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    this.setState({ anchorElTag: event.currentTarget });
  };
  handleChangeSelectedClassType = (classType: any) => {
    const listTag = this.renderListTag(classType.items)
    this.setState({ selectedClassType: classType, listTemplateByClassType: classType.items, listTag, selectedTag: "" });
    this.handleCloseClassTypePopover()
  };
  handleChangeSelectedTag = (tag: string) => {
    let listTemplate = [...this.state.selectedClassType.items]
    listTemplate = listTemplate.filter((template: any) => template.focus_tag === tag)
    this.setState({ selectedTag: tag, listTemplateByClassType: listTemplate });
    this.handleCloseTagPopover()
  };
  handleCloseModalWorkout = () => {
    this.setState({
      openModalWorkout: false,
    });
  };
  handleCloseModalEditWorkout = () => {
    this.setState({
      editWorkoutSelected: null,
    });
  };
  handleCloseModalAddTimeSlot = () => {
    this.setState({
      addTimeSlotSelected: null,
    });
  };
  handleCloseModalConfirmDelete = () => {
    this.setState({
      isOpenModalConfirmDelete: false,
      deleteWorkout: null
    });
  };
  handleAddTimeSlot = (date: any) => {
    this.setState({
      addTimeSlotSelected: date,
      errorOutside: null
    });
  }
  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => {}
  };
  handleClickClassDay = (workoutId: string) => {
    this.getWorkout(workoutId)
    this.setState({
      modalLoading: true,
      openModalWorkout: true,
    });
  };
  postCreateTimeSlot = (data: any) => {
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = { 
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.postSchedulesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleListAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data))
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDeleteSchedule = (scheduleId?: string) => {
    this.setState({editWorkoutSelected: null, loading: true,  listTemplate: [], listScheduleSlot: [], isOpenModalConfirmDelete: false, deleteWorkout: null });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
    };

    this.deleteSchedulesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleListAPIEndPoint + `/${scheduleId ?? this.state.editWorkoutSelected?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateSchedule = (dataPut: any, scheduleId: any) => {
      this.setState({ loading: true,   listTemplate: [], listScheduleSlot: []});
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": configJSON.applicationJsonApiContentType,
      };

      this.putSchedulesApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.scheduleListAPIEndPoint + `/${scheduleId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(dataPut)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }
  handleSaveTimeSlot = (timeSlotSelected: any, listTimeSlot: any[]) => {
    const data = {
      schedule: {
        schedule_date: moment(timeSlotSelected).format("YYYY-MM-DD"),
        time_slots_attributes: listTimeSlot.map((timeSlot: any) => {
          return {
            start_time: timeSlot.startTime,
            status: parseInt(timeSlot.type.value, 10),
          };
        }),
      },
    };
    const currentDateSchedule  = this.state.listScheduleSlot.find(scheduleSlot => scheduleSlot.attributes.schedule_date === timeSlotSelected.format("YYYY-MM-DD"))
    if(currentDateSchedule){
      this.setState({   modalLoading: true, addedTimeSlot: data})
      this.handleDeleteSchedule(currentDateSchedule.id)
    }else{
      this.setState({  loading: true, modalLoading: true})
      this.postCreateTimeSlot(data)
    }
    
   
  }
  handleUpdateASchedule = (schedule: any, newDate: any, newListTimeSlot: any) => {
    this.setState({  loading: true, modalLoading: true, isOpenModalConfirmDelete: false})
    const dataPut = {
      schedule: {
        video_template_id: schedule.video_template_id,
        schedule_date: moment(newDate).format("YYYY-MM-DD"),
        time_slots_attributes: newListTimeSlot
      },
    };
    this.updateSchedule(dataPut, schedule.id);
  }
  onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination &&
      result.destination.droppableId === "templateDropable"
    ) {
      return;
    }
    const date = result.destination.droppableId;
    const timeTemplateDrag = result.draggableId;
    const templateId = timeTemplateDrag.split("--")[0];
    const dateSelectedData = this.state.listScheduleSlot.find(
      (scheduleSlot: any) => scheduleSlot.attributes.schedule_date === date
    );
    if (dateSelectedData) {
      const dataPut = {
        schedule: {
          video_template_id: templateId,
          schedule_date: date,
          time_slots_attributes: dateSelectedData.attributes.time_slots.map(
            (timeSlot: any) => {
              return { id: timeSlot.id, start_time: timeSlot.start_time, status: timeSlot.status};
            }
          ),
        },
      };
      this.updateSchedule(dataPut, dateSelectedData.id);
    }  
  }
  handleChangeDateCalendarSelected = (date: any) => {
    this.setState({
      dateCalendarSelected: date,
      currentMonth: moment(date),
      loading: true,  listTemplate: [], listScheduleSlot: []
    }, () => {
      this.getSchedules()
      this.getTemplate()
    });
  }
  // Customizable Area End
}